import React from "react";
import { SettingsCard, Formblock, Notifier } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { DeepPartial } from "utility-types";
import { IntegrationConnection } from "dashboard/miter";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { useForm } from "react-hook-form";
import {
  AcumaticaConnectionMetadata,
  AcumaticaLabelFieldValue,
  ProjectTaskExpansionParams,
} from "backend/services/acumatica/acumatica-types";
import { DefaultBranchSelection } from "./DefaultBranchSelection";
import { useDebouncedCallback } from "use-debounce";
import { expandedEarningTypeOptions } from "dashboard/pages/timesheets/TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { IntegrationEarningTypeConfig } from "../IntegrationEarningTypeConfig";
import { TimesheetEarningType } from "backend/models/timesheet";
import { CostCodeFieldSelection } from "./CostCodeFieldSelection";
import { IntegrationPayTypeConfig } from "../IntegrationPayTypeConfig";
import { TMPayType } from "backend/models/teamMember/team-member";
import { DefaultExpenseItemSelection } from "./DefaultExpenseItemSelection";
import { Option } from "ui/form/Input";

export const ACUMATICA_INPUT_LENGTH = 250;
export const DEFAULT_ACUMATICA_LABEL_STYLE = { minWidth: 230 };

export const basePayTypeOptions = [
  { label: "Hourly", value: "hourly" },
  { label: "Salary", value: "salary" },
  { label: "Default", value: "default" },
];

export const projectTaskRestrictionFieldOptions: Option<ProjectTaskExpansionParams>[] = [
  { label: "Visibility Settings", value: "visibility_settings" },
  { label: "Attributes", value: "attributes" },
];

export const standardClassificationLabelOptions: Option<AcumaticaLabelFieldValue>[] = [
  { label: "Inventory ID", value: "inventory_id" },
  { label: "Description", value: "description" },
];

export const AcumaticaConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();

  const { configObject } = integration.connection?.metadata?.acumatica || {};
  const { earningTypeMapping, markTimesheetsAsCompleted } = configObject?.timesheets || {};
  const { syncProjectTasksAsSubJobs } = configObject?.jobs || {};
  const { costCodeField, useApproversOnProjectTasks, restrictionFieldForProjectTasks } =
    configObject?.activities || {};
  const { employeeClassMapping } = configObject?.team_members || {};

  const endpoint = configObject?.company?.endpoint;
  const { maxRequestsPerMinute, maxConcurrentAPIRequests } = configObject?.company || {};

  const updateCompanyConfig = async (
    value: string | number,
    key: "apiVersion" | "maxRequestsPerMinute" | "maxConcurrentAPIRequests" | "endpoint"
  ) => {
    const update = {
      configObject: { company: { [key]: value } },
    };

    await updateAcumaticaMetadata(update);
  };

  const debouncedUpdate = useDebouncedCallback(updateCompanyConfig, 1000);

  const updateAcumaticaMetadata = async (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => {
    const raw: DeepPartial<IntegrationConnection> = { metadata: { acumatica: update } };
    const collapseCount = opts?.collapseCount;
    const flattened = buildAtomicMongoUpdateFromNested(raw, {
      collapseCount: collapseCount != null ? collapseCount + 2 : undefined,
    });

    await updateIntegrationConnection(flattened);
  };

  const saveEarningTypeCallback = async (newEarningTypeMap: Record<string, TimesheetEarningType>) => {
    await updateAcumaticaMetadata(
      {
        configObject: { timesheets: { earningTypeMapping: newEarningTypeMap } },
      },
      { collapseCount: 2 }
    );
  };

  const savePayTypeCallback = async (newPayTypeMap: Record<string, TMPayType>) => {
    await updateAcumaticaMetadata(
      {
        configObject: { team_members: { employeeClassMapping: newPayTypeMap } },
      },
      { collapseCount: 2 }
    );
  };

  const updateSubJobsConfig = async (checked: boolean) => {
    const update: DeepPartial<AcumaticaConnectionMetadata> = {
      configObject: { jobs: { syncProjectTasksAsSubJobs: checked } },
    };

    await updateAcumaticaMetadata(update, { collapseCount: 2 });
  };

  const updateApiLimits = async (text: string, name: "maxConcurrentAPIRequests" | "maxRequestsPerMinute") => {
    const num = Number(text || undefined);
    if (Number.isNaN(num) || Math.round(num) !== num || num <= 0) {
      Notifier.error("Please input a positive integer");
      return;
    }
    await updateCompanyConfig(num, name);
  };

  const debouncedUpdateApiLimits = useDebouncedCallback(updateApiLimits, 1000);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Company Settings">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="apiVersion"
          type="text"
          labelInfo="The release version of your Acumatica Instance."
          label="Release Version"
          defaultValue={configObject?.company?.apiVersion}
          onChange={(e) => debouncedUpdate(e.target.value, "apiVersion")}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="apiVersion"
          type="text"
          labelInfo="The endpoint to use when talking to the Acumatica API. Uses 'Default' if not provided."
          label="Endpoint"
          defaultValue={endpoint}
          onChange={(e) => debouncedUpdate(e.target.value, "endpoint")}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
        <DefaultBranchSelection
          integration={integration}
          updateAcumaticaMetadata={updateAcumaticaMetadata}
          configOptions={configObject}
          form={form}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="maxRequestsPerMinute"
          type="number"
          min={1}
          step={1}
          labelInfo="The maximum number of API requests per minute your Acumatica instance supports."
          label="Max Requests Per Minute"
          defaultValue={maxRequestsPerMinute}
          onChange={(e) => debouncedUpdateApiLimits(e.target.value, "maxRequestsPerMinute")}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="maxConcurrentAPIRequests"
          type="number"
          min={1}
          step={1}
          labelInfo="The maximum number of concurrent API requests your Acumatica instance supports."
          label="Max Concurrent Requests"
          defaultValue={maxConcurrentAPIRequests}
          onChange={(e) => debouncedUpdateApiLimits(e.target.value, "maxConcurrentAPIRequests")}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Earning type mapping">
        <IntegrationEarningTypeConfig
          saveEarningTypeCallback={saveEarningTypeCallback}
          earningTypeMapping={earningTypeMapping}
          earningTypeOptions={expandedEarningTypeOptions}
        />
      </SettingsCard>
      <SettingsCard title="Pay Type Mapping">
        <IntegrationPayTypeConfig
          savePayTypeCallback={savePayTypeCallback}
          payTypeMapping={employeeClassMapping}
          payTypeOptions={basePayTypeOptions}
        />
      </SettingsCard>
      <SettingsCard title="Expenses">
        <DefaultExpenseItemSelection
          integration={integration}
          updateAcumaticaMetadata={updateAcumaticaMetadata}
          configOptions={configObject}
          form={form}
        />
      </SettingsCard>
      <SettingsCard title="Jobs">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="syncProjectTasksAsSubJobs"
          type="checkbox"
          labelInfo="Sync Project Tasks in Acumatica as Sub Jobs in Miter."
          label="Sync Sub Jobs"
          defaultValue={!!syncProjectTasksAsSubJobs}
          onChange={(e) => updateSubJobsConfig(e.target.checked)}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Activities">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="useApproversOnProjectTasks"
          type="checkbox"
          labelInfo="Use Project Task approver as Supervisor on Jobs for the Project."
          label="Use Project Task Approver"
          defaultValue={!!useApproversOnProjectTasks}
          onChange={(e) =>
            updateAcumaticaMetadata(
              {
                configObject: { activities: { useApproversOnProjectTasks: e.target.checked } },
              },
              { collapseCount: 2 }
            )
          }
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
        <Formblock
          form={form}
          options={projectTaskRestrictionFieldOptions}
          name="restrictionFieldForProjectTasks"
          type="select"
          defaultValue={restrictionFieldForProjectTasks}
          onChange={(option: Option<ProjectTaskExpansionParams> | null) => {
            updateAcumaticaMetadata(
              {
                configObject: { activities: { restrictionFieldForProjectTasks: option?.value } },
              },
              { collapseCount: 2 }
            );
          }}
          editing={true}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          label="Project Task Restriction"
          labelInfo="The field on the Project Task Miter will use to restrict non labor related Activities when pulling."
          underlineTooltip
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
        />
        <CostCodeFieldSelection
          form={form}
          updateAcumaticaMetadata={updateAcumaticaMetadata}
          costCodeField={costCodeField}
        />
      </SettingsCard>
      <SettingsCard title="Timesheets">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="markTimesheetsAsCompleted"
          type="checkbox"
          labelInfo="When checked, goes through all timesheets that were pushed in the last 24 hours and marks them as completed on Acumatica."
          label="Mark Timesheets as Completed"
          defaultValue={!!markTimesheetsAsCompleted}
          onChange={(e) =>
            updateAcumaticaMetadata(
              {
                configObject: { timesheets: { markTimesheetsAsCompleted: e.target.checked } },
              },
              { collapseCount: 2 }
            )
          }
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Standard Classification">
        <Formblock
          form={form}
          options={standardClassificationLabelOptions}
          name="standardClassificationLabel"
          type="select"
          defaultValue={restrictionFieldForProjectTasks}
          onChange={(option: Option<AcumaticaLabelFieldValue> | null) => {
            updateAcumaticaMetadata(
              {
                configObject: { standard_classifications: { labelField: option?.value } },
              },
              { collapseCount: 2 }
            );
          }}
          editing={true}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          label="Label Field"
          labelInfo="The field on the Labor Item to use for the Label in Miter."
          underlineTooltip
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
        />
      </SettingsCard>
    </div>
  );
};
