import InfoButton from "dashboard/components/information/information";
import React, { FC, ReactElement, ReactNode } from "react";
import { Badge } from "../badge";
type SettingsCardProps = {
  children: React.ReactNode;
  title?: string | ReactElement | ReactNode;
  info?: string;
  subtitle?: string;
  contentStyle?: React.CSSProperties;
  beta?: boolean;
};

export const SettingsCard: FC<SettingsCardProps> = ({
  children,
  title,
  info,
  subtitle,
  contentStyle,
  beta,
}) => {
  return (
    <div className="billing-card-wrapper">
      <div style={{ display: "flex" }}>
        {title && <div className="billing-card-header">{title}</div>}
        {info && (
          <div style={{ marginTop: 5 }}>
            <InfoButton text={info} />
          </div>
        )}
        {beta && <Badge text="Beta" color="blue" />}
      </div>
      {subtitle && <div style={{ marginTop: 5, color: "#555", ...contentStyle }}>{subtitle}</div>}
      {(title || info || beta) && <div className="vertical-spacer-medium" />}
      <div style={contentStyle}>{children}</div>
    </div>
  );
};
