import React from "react";
import { useForm } from "react-hook-form";
import { Formblock } from "ui";
import * as vals from "dashboard/utils/validators";
import { Option } from "ui/form/Input";

import { ActivityModalTabProps } from "./TabbedActivityModal";
import {
  useActivityOptions,
  useDepartmentOptions,
  useHasEnabledWcGroups,
  useLocationOptions,
  useWcCodeOptions,
  useWcGroupOptions,
} from "dashboard/hooks/atom-hooks";
import { useHasAccessToAccessFilters } from "dashboard/gating";

export const ActivityBasics: React.FC<ActivityModalTabProps> = ({
  selectedActivity,
  handleFieldsChange,
  readonly,
}) => {
  const hasEnabledWcGroups = useHasEnabledWcGroups();
  const activityOptions = useActivityOptions();
  const wcCodeOptions = useWcCodeOptions({ defaultValue: selectedActivity?.wc_code || undefined });
  const departmentOptions = useDepartmentOptions({
    defaultValue: selectedActivity?.department_id || undefined,
  });
  const locationOptions = useLocationOptions({ defaultValue: selectedActivity?.location_id || undefined });
  const hasAccessToAccessFilters = useHasAccessToAccessFilters();

  // Only allow workers' comp groups that are not job and cost code types because those are not allowed for activities
  const wcGroupOptions = useWcGroupOptions({
    defaultValue: selectedActivity?.wc_group_id || undefined,
    predicate: (wcGroup) => wcGroup.type !== "job_and_cost_code",
  });

  const { register, control, errors } = useForm();

  const handleWcCodeChange = (e: Option<string> | null) => {
    handleFieldsChange({ wc_code: e?.value || null });
  };

  const handleWcGroupChange = (e: Option<string> | null) => {
    handleFieldsChange({ wc_group_id: e?.value || null });
  };

  return (
    <div>
      <div className="vertical-spacer-small"></div>
      {wcCodeOptions && (
        <>
          {selectedActivity.base_activity_id && (
            <Formblock
              label="Base activity"
              labelInfo="The company level activity that this activity is derived from"
              type="select"
              options={activityOptions}
              name="base_activity_id"
              errors={errors}
              className="tabbed-modal"
              defaultValue={selectedActivity?.base_activity_id}
              editing={true}
              disabled={true}
              onChange={() => {}}
            />
          )}
          <Formblock
            label="Activity name*"
            labelInfo="A human-friendly name used to identify the activity (i.e., Carpentry)"
            type="text"
            register={register(vals.required)}
            onChange={(e) => handleFieldsChange({ label: e.target.value })}
            name="label"
            errors={errors}
            className="tabbed-modal"
            defaultValue={selectedActivity?.label}
            editing={true}
            disabled={readonly}
          />
          <Formblock
            label="Cost code"
            labelInfo="Cost code used to aggregate costs in job costing and reporting"
            type="text"
            register={register}
            className="tabbed-modal"
            onChange={(e) => handleFieldsChange({ cost_code: e.target.value })}
            name="cost_code"
            errors={errors}
            editing={true}
            defaultValue={selectedActivity?.cost_code}
            disabled={readonly}
          />
          <Formblock
            label="Workers' comp code"
            labelInfo="3-4 digit workers' compensation code assigned by the NCCI or a state rating bureau"
            type="select"
            control={control}
            name="wc_code"
            className="tabbed-modal"
            options={wcCodeOptions}
            defaultValue={selectedActivity?.wc_code}
            onChange={handleWcCodeChange}
            errors={errors}
            editing={true}
            isClearable
            disabled={readonly}
          />
          {hasEnabledWcGroups && (
            <Formblock
              label="Workers' comp group"
              labelInfo="Workers' compensation group that this activity belongs to (optional)"
              type="select"
              control={control}
              name="wc_group_id"
              className="tabbed-modal"
              options={wcGroupOptions}
              defaultValue={selectedActivity?.wc_group_id}
              onChange={handleWcGroupChange}
              errors={errors}
              editing={true}
              isClearable
              disabled={readonly}
            />
          )}
          {hasAccessToAccessFilters && (
            <>
              <Formblock
                label="Department"
                labelInfo="Department with which the activity is associated"
                type="select"
                control={control}
                name="department"
                className="tabbed-modal"
                defaultValue={selectedActivity?.department_id}
                options={departmentOptions}
                errors={errors}
                editing={true}
                onChange={(option) => handleFieldsChange({ department_id: option.value })}
              />
              <Formblock
                label="Location"
                labelInfo="Location with which the activity is associated"
                type="select"
                control={control}
                name="location"
                className="tabbed-modal"
                defaultValue={selectedActivity?.location_id}
                options={locationOptions}
                errors={errors}
                editing={true}
                onChange={(option) => handleFieldsChange({ location_id: option.value })}
              />
            </>
          )}
          <div className="vertical-spacer-small"></div>
        </>
      )}
    </div>
  );
};
