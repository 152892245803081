import React, { FC, useState } from "react";
import {
  useActiveCompanyId,
  useActivityOptionsMap,
  useCostTypeOptions,
  useExpenseReimbursementCategories,
  useJobOptions,
  useLedgerAccountOptions,
  usePermissionGroupOptions,
} from "dashboard/hooks/atom-hooks";
import { ExpenseReimbursementCategory, MiterAPI } from "dashboard/miter";
import { ActionModal, Formblock, Notifier } from "ui";
import { useForm } from "react-hook-form";
import { isReimbursementScoped } from "dashboard/pages/activities/activityUtils";
import { Option } from "ui/form/Input";
import InfoButton from "dashboard/components/information/information";
import * as formValidators from "dashboard/utils/validators";
import { ExpenseReimbursementPayoutMethod } from "backend/models/expense-reimbursement";
import { JobInput } from "dashboard/components/shared/JobInput";
import { useReimbursementPayoutMethodOptions } from "../expenseUtils";
import { ExpenseReimbursementCategoryCreationParams } from "backend/services/expenses/expense-reimbursement-categories-service";
import { withValue } from "dashboard/utils";
import { isCostTypeExpenseManagementScoped } from "dashboard/components/cost-types/costTypeUtils";
import { isJobSpendManagementScoped } from "dashboard/pages/jobs/jobUtils";

type CategoryFormParams = {
  name?: string;
  amount?: number;
  amount_locked: boolean;
  merchant_name?: string;
  merchant_name_locked: boolean;
  job?: Option<string>;
  job_locked: boolean;
  activity?: Option<string>;
  activity_locked: boolean;
  cost_type?: Option<string>;
  cost_type_locked: boolean;
  gl_account?: Option<string>;
  gl_account_locked: boolean;
  is_taxable: boolean;
  payout_method?: Option<ExpenseReimbursementPayoutMethod>;
  mileage_rate?: number;
  is_default: boolean;
  permission_group_ids: Option<string>[];
};

type Props = {
  selectedCategory?: ExpenseReimbursementCategory;
  onSave: () => void;
  onHide: () => void;
};

const ExpenseReimbursementCategoryModal: FC<Props> = ({ selectedCategory, onSave, onHide }) => {
  // hooks
  const activeCompanyId = useActiveCompanyId();
  const existingCategories = useExpenseReimbursementCategories();

  const jobOptions = useJobOptions({
    predicate: isJobSpendManagementScoped,
  });
  const activityOptionsMap = useActivityOptionsMap({
    predicate: isReimbursementScoped,
  });
  const [activityOptions, setActivityOptions] = useState<Option<string>[]>(
    activityOptionsMap.get(selectedCategory?.job?.id)
  );
  const costTypeOptions = useCostTypeOptions({
    predicate: isCostTypeExpenseManagementScoped,
  });
  const ledgerAccountOptions = useLedgerAccountOptions();
  const payoutMethodOptions = useReimbursementPayoutMethodOptions(true);
  const selectablePermissionGroupOptions = usePermissionGroupOptions();

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [isTaxableHidden, setIsTaxableHidden] = useState<boolean>(
    selectedCategory?.payout_method === "ach" || !selectedCategory?.payout_method
  );

  // form
  const form = useForm<CategoryFormParams>({
    shouldUnregister: false,
    defaultValues: {
      name: selectedCategory?.name,
      amount: selectedCategory?.amount?.value,
      amount_locked: !!selectedCategory?.amount?.is_locked,
      merchant_name: selectedCategory?.merchant_name?.id,
      merchant_name_locked: !!selectedCategory?.merchant_name?.is_locked,

      // WBS
      job: jobOptions.find(withValue(selectedCategory?.job?.id)),
      job_locked: !!selectedCategory?.job?.is_locked,
      activity: activityOptions.find(withValue(selectedCategory?.activity?.id)),
      activity_locked: !!selectedCategory?.activity?.is_locked,
      cost_type: costTypeOptions.find(withValue(selectedCategory?.cost_type?.id)),
      cost_type_locked: !!selectedCategory?.cost_type?.is_locked,
      gl_account: ledgerAccountOptions.find(withValue(selectedCategory?.expense_account?.id)),
      gl_account_locked: !!selectedCategory?.expense_account?.is_locked,

      mileage_rate: selectedCategory?.mileage_rate ?? undefined,
      payout_method: payoutMethodOptions.find(withValue(selectedCategory?.payout_method)),
      is_taxable: !!selectedCategory?.is_taxable,
      is_default: !!selectedCategory?.is_default,
      permission_group_ids: selectablePermissionGroupOptions.filter((pg) =>
        selectedCategory?.permission_group_ids?.includes(pg.value)
      ),
    },
  });

  // Remove properties that equal what exists in the user object
  const cleanParams = (data: CategoryFormParams): ExpenseReimbursementCategoryCreationParams | undefined => {
    const {
      name,
      amount,
      amount_locked,
      merchant_name,
      merchant_name_locked,
      job,
      job_locked,
      activity,
      activity_locked,
      cost_type,
      cost_type_locked,
      gl_account,
      gl_account_locked,
      is_taxable,
      payout_method,
      mileage_rate,
      is_default,
      permission_group_ids,
    } = data;

    // cannot submit until name is entered
    if (!name) return;
    // if creating a new category and the name matches an existing category, return
    if (!selectedCategory && existingCategories.some((existingCategory) => existingCategory.name === name)) {
      Notifier.error(`Category with name "${name}" already exists`);
      return;
    }

    return {
      _id: selectedCategory?._id ?? undefined,
      name,
      company_id: activeCompanyId!,
      amount: amount ? { value: amount, is_locked: amount_locked } : null,
      merchant_name: merchant_name ? { id: merchant_name, is_locked: merchant_name_locked } : null,
      job: job
        ? {
            id: job.value,
            is_locked: job_locked,
          }
        : null,
      activity: activity
        ? {
            id: activity.value,
            is_locked: activity_locked,
          }
        : null,
      cost_type: cost_type
        ? {
            id: cost_type.value,
            is_locked: cost_type_locked,
          }
        : null,
      expense_account: gl_account
        ? {
            id: gl_account.value,
            is_locked: gl_account_locked,
          }
        : null,
      is_taxable: payout_method?.value === "ach" ? false : is_taxable,
      payout_method: payout_method?.value ?? null,
      mileage_rate: mileage_rate ? Number(mileage_rate) : null,
      is_default,
      permission_group_ids: permission_group_ids?.map((pg) => pg.value) || [],
    };
  };
  const upsertCategory = async (data: CategoryFormParams) => {
    try {
      const params = cleanParams(data);
      if (!params) return;
      setLoading(true);

      const res = await MiterAPI.expense_reimbursements.categories.create_or_update(params);
      if (res.error) throw new Error(res.error);

      Notifier.success(`Category ${selectedCategory ? "updated" : "created"}`);
      onSave();
      onHide();
    } catch (e: $TSFixMe) {
      console.error(`Error ${selectedCategory ? "updated" : "created"} category:`, e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    if (!selectedCategory) return;

    setLoading(true);
    try {
      const res = await MiterAPI.expense_reimbursements.categories.archive(selectedCategory._id);
      if (res.error) throw new Error(res.error);

      Notifier.success("Category deleted successfully");
      onSave();
      onHide();
    } catch (e: $TSFixMe) {
      console.error("Error deleting category:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const { control, register, errors, setValue, handleSubmit } = form;

  const formData = form.getValues();

  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="name"
          label="Name*"
          control={control}
          register={register(formValidators.required)}
          editing={true}
          errors={errors}
          style={{ width: "75%" }}
        />
        <div className="flex">
          <h3 className="settings-subheader">Team Member Selectable Fields</h3>
          <InfoButton
            text={`Selecting "Lock" prevents the team member from editing the field after selecting a category.`}
          />
        </div>
        {/* amount */}
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <Formblock
            type="unit"
            name="amount"
            label="Amount"
            unit="$"
            register={register(
              formValidators.numberValidator({
                required: false,
                excludeNegatives: true,
                excludeZero: true,
                maxDecimals: 2,
              })
            )}
            control={control}
            errors={errors}
            editing={true}
            onChange={(e) => {
              // if unsetting, also unset locked
              if (!e.target.value) {
                setValue("amount_locked", false);
              }
            }}
          />
          <Formblock
            type="checkbox"
            name="amount_locked"
            text="Lock"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            disabled={!formData?.amount}
          />
        </div>
        {/* Merchant name */}
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <Formblock
            type="text"
            name="merchant_name"
            label="Merchant name"
            placeholder="ex. Per Diem"
            editing={true}
            register={register}
            onChange={(e) => {
              if (!e.target.value) {
                setValue("merchant_name_locked", false);
              }
            }}
          />
          <Formblock
            type="checkbox"
            name="merchant_name_locked"
            text="Lock"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            disabled={!formData?.merchant_name}
          />
        </div>
        {/* job */}
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <div>
            <JobInput
              type="select"
              name="job"
              label="Job"
              form={form}
              control={control}
              options={jobOptions}
              errors={errors}
              editing={true}
              onChange={(e) => {
                const newJobId = e?.value;

                if (!newJobId) {
                  setValue("job_locked", false);
                }

                if (newJobId === formData.job?.value) return;

                const newActivityOptions = activityOptionsMap.get(newJobId);
                setActivityOptions(newActivityOptions);

                // reset activity if current activity is not in new job's cost code list
                if (newActivityOptions.every((o) => o.value !== formData.activity?.value)) {
                  setValue("activity", null);
                  setValue("activity_locked", false);
                }
              }}
              isClearable
            />
          </div>
          <Formblock
            type="checkbox"
            name="job_locked"
            text="Lock"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            disabled={!formData?.job?.value}
          />
        </div>
        {/* activity */}
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <Formblock
            type="select"
            name="activity"
            label="Activity"
            control={control}
            options={activityOptions}
            errors={errors}
            editing={true}
            isClearable
            onChange={(option) => {
              if (!option?.value) {
                setValue("activity_locked", false);
              }
            }}
          />
          <Formblock
            type="checkbox"
            name="activity_locked"
            text="Lock"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            disabled={!formData?.activity?.value}
          />
        </div>
        {/* cost type */}
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <Formblock
            type="select"
            name="cost_type"
            label="Cost type"
            control={control}
            options={costTypeOptions}
            errors={errors}
            editing={true}
            isClearable
            onChange={(option) => {
              if (!option?.value) {
                setValue("cost_type_locked", false);
              }
            }}
          />
          <Formblock
            type="checkbox"
            name="cost_type_locked"
            text="Lock"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            disabled={!formData?.cost_type?.value}
          />
        </div>
        {/* gl account */}
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <Formblock
            type="select"
            name="gl_account"
            label="GL account"
            control={control}
            options={ledgerAccountOptions}
            errors={errors}
            editing={true}
            isClearable
            onChange={(option) => {
              if (!option?.value) {
                setValue("gl_account_locked", false);
              }
            }}
          />
          <Formblock
            type="checkbox"
            name="gl_account_locked"
            text="Lock"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            disabled={!formData?.gl_account?.value}
          />
        </div>
        <div className="flex">
          <h3 className="settings-subheader">Admin Only Fields</h3>
          <InfoButton
            text={`None of these fields are adjustable by the team member. Setting anything here will default reimbursements of this category to what is chosen.`}
          />
        </div>
        <Formblock
          type="select"
          name="payout_method"
          label="Payout method"
          control={control}
          options={payoutMethodOptions}
          register={register}
          editing={true}
          errors={errors}
          isClearable
          style={{ width: "75%" }}
          onChange={(option) => {
            setIsTaxableHidden(option?.value === "ach" || !option);
          }}
        />
        <Formblock
          type="checkbox"
          name="is_taxable"
          label="Taxable"
          control={control}
          register={register}
          editing={true}
          errors={errors}
          style={{ width: "75%" }}
          hidden={isTaxableHidden}
        />
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <Formblock
            type="unit"
            unit="$"
            register={register(
              formValidators.numberValidator({
                required: false,
                excludeNegatives: true,
                excludeZero: true,
                maxDecimals: 3,
              })
            )}
            name="mileage_rate"
            label="Mileage rate"
            labelInfo="Enter in dollars per mile (ex. 0.655). If entered, reimbursements of this category will automatically be mileage reimbursements."
            control={control}
            editing={true}
            errors={errors}
          />
          <div>per mile.</div>
        </div>
        <Formblock
          type="checkbox"
          name="is_default"
          label="Default"
          labelInfo="If selected, all new reimbursements will default to this category. Team members can still select another one."
          control={control}
          register={register}
          editing={true}
          errors={errors}
          style={{ width: "75%" }}
        />
        <Formblock
          type="multiselect"
          name="permission_group_ids"
          label="Permission groups"
          labelInfo="If selected, only team members in these permission groups can select this category. Useful for locking down a per diem category just for payroll/accounting, etc."
          form={form}
          editing={true}
          placeholder={"Select permission groups"}
          options={selectablePermissionGroupOptions}
          height={"unset"}
          style={{ width: "75%" }}
        />
      </div>
    );
  };
  return (
    <ActionModal
      headerText={selectedCategory ? "Edit category" : "Create category"}
      showSubmit={true}
      showCancel={true}
      showDelete={true}
      deleteDisabled={selectedCategory == null}
      onDelete={handleDelete}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={handleSubmit(upsertCategory)}
      loading={loading}
      wrapperStyle={{ minWidth: 600 }}
    >
      {renderForm()}
    </ActionModal>
  );
};

export default ExpenseReimbursementCategoryModal;
