import {
  PlusCircle,
  LinkSimpleHorizontal,
  Users,
  Link,
  ShieldCheck,
  ArrowsClockwise,
  Receipt,
  Table,
  Download,
  ArrowsOutLineHorizontal,
} from "phosphor-react";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Notifier } from "ui";
import styles from "../../components/banking/Lander.module.css";
import { connectExternalCards, initializeStripeAccount } from "dashboard/utils/expenses";
import PlaidLink from "../../components/banking/PlaidLink";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { StripeAccountResponse } from "dashboard/miter";
import { useNavigate } from "react-router-dom";
import { isMiterCardsAccountFullyActive } from "./expenseUtils";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import LinkNewCardModal from "./modals/LinkNewCardModal";

const CardsLander: React.FC<{ stripeAccount: StripeAccountResponse | null; refreshData?: () => void }> = ({
  stripeAccount,
  refreshData,
}) => {
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [isLinkCardModalOpen, setIsLinkCardModalOpen] = useState(false);

  const [plaidLinkToken, setPlaidLinkToken] = useState<null | string>(null);

  const isStripeAccountPartiallyOnboarded = stripeAccount && !isMiterCardsAccountFullyActive(stripeAccount);

  const company = activeCompanyId;

  const handleStripeLinkClick = async () => {
    if (!company) return Notifier.error("No active company");
    initializeStripeAccount({
      company,
      source: "Card Transactions Lander",
      account: stripeAccount,
      setLoading,
    });
  };

  const renderHeader = () => {
    return (
      <div className={styles["header"]}>
        <p className={styles["header-subtitle"]}>
          Issue Miter spend cards to your team or connect your existing cards.
        </p>
      </div>
    );
  };

  const renderFeatures = () => {
    const featureElements = capitalFeatures.map((feature) => {
      return (
        <div className={styles["feature"]} key={feature.label}>
          <div className={styles["feature-icon"]}>{feature.icon}</div>
          <div className={styles["feature-label"]}>
            <p>{feature.label}</p>
          </div>
        </div>
      );
    });

    return <div className={styles["features"] + " " + styles["capital-features"]}>{featureElements}</div>;
  };

  const renderCTA = () => {
    return (
      <div className={styles["cta"]}>
        <div className="flex">
          <Button
            className={styles["cta-button"] + " button-2 no-margin"}
            onClick={handleStripeLinkClick}
            loading={loading}
            style={{ marginRight: "10px" }}
          >
            <PlusCircle style={{ marginBottom: -2, marginRight: 10 }} />
            {isStripeAccountPartiallyOnboarded ? "Continue Miter cards setup" : "Issue your first Miter card"}
          </Button>
          <Button
            className={styles["cta-button"] + " button-1 no-margin"}
            onClick={() => setIsLinkCardModalOpen(true)}
          >
            <LinkSimpleHorizontal style={{ marginBottom: -2, marginRight: 10 }} />
            Connect an external card
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Cards | Miter</title>
      </Helmet>
      {renderHeader()}
      {renderFeatures()}
      {renderCTA()}
      {isLinkCardModalOpen && (
        <LinkNewCardModal
          onHide={(shouldRefresh?: boolean) => {
            // if a new Astrada card was added, refresh all data to close the lander
            if (shouldRefresh) {
              refreshData?.();
            }

            setIsLinkCardModalOpen(false);
          }}
          setPlaidLinkToken={setPlaidLinkToken}
        />
      )}
      {plaidLinkToken && company && (
        <PlaidLink
          token={plaidLinkToken}
          onSuccess={(public_token: string, metadata: PlaidLinkOnSuccessMetadata) =>
            connectExternalCards({
              company,
              public_token,
              metadata,
              updateMode: false,
              callback: () => {
                navigate(`/spend/cards/programs`);
                refreshData?.();
              },
            })
          }
          onExit={() => setPlaidLinkToken(null)}
        />
      )}
    </div>
  );
};

const capitalFeatures = [
  {
    icon: <Users color="#4D54B6" weight="duotone" />,
    label: "Issue cards to your team.",
  },
  {
    icon: <Link color="#4D54B6" weight="duotone" />,
    label: "Real time transaction feeds for Visa and Mastercard cards.",
  },
  {
    icon: <Link color="#4D54B6" weight="duotone" />,
    label: "Direct transaction feed for American Express cards.",
  },
  {
    icon: <ShieldCheck color="#4D54B6" weight="duotone" />,
    label: "Set detailed spending controls.",
  },
  {
    icon: <ArrowsClockwise color="#4D54B6" weight="duotone" />,
    label: "Sync transactions to your ERP.",
  },
  {
    icon: <Receipt color="#4D54B6" weight="duotone" />,
    label: "Automate receipt reminders.",
  },
  {
    icon: <Table color="#4D54B6" weight="duotone" />,
    label: "Job cost each purchase.",
  },
  {
    icon: <Download color="#4D54B6" weight="duotone" />,
    label: "Generate expense reports (with receipts!) for a time period and/or job.",
  },
  {
    icon: <ArrowsOutLineHorizontal color="#4D54B6" weight="duotone" />,
    label: "Split an expense by amount or percentage across different job cost attributes.",
  },
];

export default CardsLander;
