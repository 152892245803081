import { SmartField } from "dashboard/types/offers-types";
import { InputType } from "packages/ui/form/Input";
import { smartFieldLabelMap } from "./offer-templates";
import * as vals from "dashboard/utils/validators";
import { OfferLetter } from "dashboard/types/offers-types";
import { DateTime } from "luxon";
import { CandidateInfo } from "dashboard/components/offers/offer-letter-wizard/OfferLetterWizard";
import { BlobInfo } from "packages/ui/editor/Editor";
import { MiterAPI } from "dashboard/miter";

export const ADMIN_ENTERED_SMART_FIELDS: SmartField[] = [
  "job_title",
  "annual_salary",
  "hourly_pay_rate",
  "start_date",
  "sender_first_name",
  "sender_last_name",
  "sender_title",
  "department",
];

type SmartFieldFormblockParam = {
  type: InputType;
  unit: string;
  name: SmartField;
  label: string;
  val: typeof vals.required;
  editing: boolean;
  dateOnly?: boolean;
};
export const generateSmartFieldFormblockParam = (smartField: SmartField): SmartFieldFormblockParam => {
  const type = mapSmartFieldToInputType[smartField];
  if (!type) {
    throw new Error(`Invalid smart field: ${smartField}`);
  }
  return {
    type,
    unit: "$",
    name: smartField,
    label: `${smartFieldLabelMap[smartField]}*`,
    val: vals.required,
    editing: true,
    dateOnly: true,
  };
};

const mapSmartFieldToInputType: Partial<Record<SmartField, InputType>> = {
  job_title: "text",
  annual_salary: "unit",
  hourly_pay_rate: "unit",
  start_date: "datetime",
  sender_first_name: "text",
  sender_last_name: "text",
  sender_title: "text",
  department: "text",
  location: "text",
};

type ReplaceSmartFieldsParams = {
  rawHTML: string;
  smartFieldInformation: OfferLetter["smart_field_information"];
  smartFields: SmartField[];
  candidateInfo: CandidateInfo;
};

export const replaceSmartFieldsWithValues = ({
  rawHTML,
  smartFieldInformation,
  smartFields,
  candidateInfo,
}: ReplaceSmartFieldsParams): string => {
  // Merge candidate information with smartFieldInformation
  const mergedSmartFieldInformation = {
    ...smartFieldInformation,
    ...candidateInfo,
  };

  return smartFields.reduce((acc, key) => {
    if (key === "candidate_signature") return acc;
    let value = mergedSmartFieldInformation[key];
    if (key === "start_date" && typeof value === "string") {
      const date = DateTime.fromISO(value);
      value = date.toFormat("MMMM dd, yyyy");
    } else if ((key === "annual_salary" || key === "hourly_pay_rate") && typeof value === "number") {
      value = `$${value.toLocaleString()}`;
    }
    return acc.replace(new RegExp(`{{${key}}}`, "g"), value as string);
  }, rawHTML);
};

type GenerateEmailBody = {
  candidateFirstName: string;
  companyName: string;
};

export const generateDefaultEmailBody = (input: GenerateEmailBody): string => {
  const { candidateFirstName, companyName } = input;

  return `
    <p>Dear ${candidateFirstName},</p><br/>
    <p>We are excited to offer you a position at our company. Attached to this email, you will find your official offer letter with all the details of our offer. To accept the offer, please sign the attached document electronically. If you need any assistance with the signing process, please let us know.</p><br/>
    <p>We look forward to welcoming you to our team!</p><br/>
    <p>Regards,</p>
    <p>${companyName}</p>
  `;
};

type UploadTimyMCEImageParams = {
  companyId: string;
  tinyMCEBlob: BlobInfo;
};

type UploadTimyMCEImageResponse = {
  url: string;
  fileId: string;
};

const OFFER_FILE_ID_PREFIX = "file-id-token-";

export const uploadTimyMCEImage = async (
  input: UploadTimyMCEImageParams
): Promise<UploadTimyMCEImageResponse> => {
  const { companyId, tinyMCEBlob } = input;
  const blobData = tinyMCEBlob.blob();
  const data = {
    originalname: tinyMCEBlob.filename(),
    type: blobData.type,
    fileBlob: blobData, // keep this for AWS direct upload from the client
    label: tinyMCEBlob.filename(),
    size: blobData.size,
    company_id: companyId,
    tag_ids: [],
    archived: false,
    parent_type: "offer_template" as const,
  };
  const res = await MiterAPI.files.upload({ files: [data] });
  if (!res[0]) throw new Error("Unable to upload image.");

  const { urls } = await MiterAPI.files.get_urls({
    filter: [
      {
        field: "_id",
        value: res[0].file._id,
        type: "_id",
      },
    ],
  });

  if (!urls[0]) throw new Error("Unable to get image URL.");
  const url = urls[0].value.url;
  if (!url) throw new Error("Image URL is empty.");
  return { url, fileId: res[0].file._id };
};

type HyrdateHTMLProps = {
  defaultHTML: string;
};

type HydrateHTMLResponse = {
  hydratedHTML: string;
  mapS3URLToId: Record<string, string>;
};

export const hydrateHTML = async (input: HyrdateHTMLProps): Promise<HydrateHTMLResponse> => {
  const { defaultHTML } = input;

  /** Grep for the file-id-token-<mongo id> pattern in the HTML */
  const regex = /file-id-token-([a-f\d]{24})/g;
  let match;
  const newMapS3URLToId: Record<string, string> = {};
  const fileIds: string[] = [];

  while ((match = regex.exec(defaultHTML)) !== null) {
    const fileId = match[1] as string;
    fileIds.push(fileId);
  }

  const filesWithURls = await MiterAPI.files.get_urls({
    filter: [{ field: "_id", value: fileIds }],
  });
  if (filesWithURls.error) {
    throw new Error(filesWithURls.error);
  }

  for (const file of filesWithURls.urls) {
    const url = file?.value?.url;
    const id = file?.file?._id.toString();
    if (!url || !id) {
      continue;
    }
    newMapS3URLToId[url] = id;
  }

  let updatedHTML = defaultHTML;
  for (const [url, id] of Object.entries(newMapS3URLToId)) {
    const fileIdToken = `${OFFER_FILE_ID_PREFIX}${id}`;
    const regex = new RegExp(fileIdToken, "g");
    updatedHTML = updatedHTML.replace(regex, `${url}`);
  }

  return { hydratedHTML: updatedHTML, mapS3URLToId: newMapS3URLToId };
};

type DehydrateHTMLProps = {
  hyrdratedHTML: string;
  s3URLToFileIdMap: Record<string, string>;
};

export const dehydrateHTML = (input: DehydrateHTMLProps): string => {
  const { hyrdratedHTML, s3URLToFileIdMap } = input;
  let updatedHTML = hyrdratedHTML;

  for (const [url, id] of Object.entries(s3URLToFileIdMap)) {
    // TinyMCE stores the URL with an additional &amp; so when we regex URLS, we need to include that amp.
    const encodedUrl = url.replace(/&/g, "&amp;");
    const fileIdToken = `${OFFER_FILE_ID_PREFIX}${id}`;
    updatedHTML = updatedHTML.replace(encodedUrl, fileIdToken);
  }

  return updatedHTML;
};

export const CANDIDATE_SIGNATURE_TOKEN = "{{candidate_signature}}";

export const SMART_FIELD_TOOLBAR =
  "link | blocks | " +
  "bold italic forecolor | alignleft aligncenter " +
  "alignright alignjustify | bullist numlist outdent indent | " +
  "table tabledelete | tableprops tablerowprops tablecellprops | " +
  "preview";
