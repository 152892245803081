import { unionRateFringeLookup } from "dashboard/pages/settings/payRateGroups/PayRateGroupPage/PayRateGroupModalUtils";
import React, { useMemo } from "react";
import { Badge, TableV2 } from "ui";
import TimesheetContext from "./timesheetContext";
import { ColumnConfig } from "ui/table-v2/Table";
import { HourlyEmployerBenefitContribution } from "backend/utils/payroll/types";
import Banner from "dashboard/components/shared/Banner";

export const contributionSourceLookup = {
  union_fringe: { label: "Fringe", color: "green" },
  benefit: { label: "Benefit", color: "orange" },
  albc: { label: "Activity-level contribution", color: "blue" },
  time_off_policy: { label: "Time off accrual", color: "gray" },
  holiday_schedule: { label: "Holidays", color: "yellow" },
};

type HebcWithId = HourlyEmployerBenefitContribution & {
  _id: string;
  regAmount: number;
  otAmount: number;
  dotAmount: number;
};

export const TimesheetBenefitContributions: React.FC = () => {
  const { timesheet } = React.useContext(TimesheetContext);

  const tableData: HebcWithId[] = useMemo(() => {
    if (!timesheet?.pay_rate.hourly_employer_benefit_contributions) return [];
    return timesheet?.pay_rate?.hourly_employer_benefit_contributions?.map((c, index) => {
      return {
        ...c,
        _id: index.toString(),
        regAmount: c.amount.reg,
        otAmount: c.amount.ot,
        dotAmount: c.amount.dot,
      };
    });
  }, [timesheet]);

  const tableColumns: ColumnConfig<HebcWithId>[] = [
    {
      field: "label",
      headerName: "Label",
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 100,
      valueGetter: (params) => unionRateFringeLookup[params.data?.category || ""],
    },
    {
      field: "sourceLabel",
      headerName: "Source",
      minWidth: 100,
      cellRenderer: (params) => {
        if (params.data._id === "totals_row") return "-";
        const obj = contributionSourceLookup[params.data?.source || ""];
        return <Badge text={obj?.label} color={obj?.color} style={{ margin: 0 }} />;
      },
    },
    {
      field: "regAmount",
      headerName: "REG $",
      minWidth: 95,
      dataType: "number",
      unit: "dollar",
      sumRow: true,
    },
    {
      field: "otAmount",
      headerName: "OT $",
      minWidth: 95,
      sumRow: true,
      dataType: "number",
      unit: "dollar",
    },
    {
      field: "dotAmount",
      headerName: "DOT $",
      minWidth: 95,
      sumRow: true,
      dataType: "number",
      unit: "dollar",
    },
    {
      field: "bonafide",
      headerName: "Bonafide",
      dataType: "boolean",
      minWidth: 120,
      headerTooltip: "Whether the contribution can be credited against the prevailing wage fringe rate.",
    },
  ];

  return (
    <div>
      <div className="payment-active-view-header">
        <div className="">Hourly benefit contributions</div>
      </div>

      {timesheet?.pay_rate?.weekly_hours_worked_for_offsets ? (
        <Banner
          type="warning"
          content={
            <div>
              This timesheet&apos;s contributions were calculated using{" "}
              {timesheet?.pay_rate?.weekly_hours_worked_for_offsets} weekly hours worked.
            </div>
          }
        />
      ) : null}
      <TableV2
        id="timesheet-benefit-contributions"
        resource="contributions"
        columns={tableColumns}
        data={tableData}
        rowHeight={50}
        hideFooter={true}
        paginationPageSize={100}
        showTotals={true}
      />
    </div>
  );
};
