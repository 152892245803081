import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { OvertimeSettings } from "dashboard/pages/settings/payrollSettings/OvertimeCalculation";
import { Notifier } from "dashboard/utils";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { CompanySettings } from "backend/models/company";
import { AutomaticBreakTime } from "./AutomaticBreakTime";
import { AdvancedBreakTime } from "./AdvancedBreakTime";
import { useForm } from "react-hook-form";
import { RateDifferentials } from "./RateDifferentials";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { DefaultTimesheetsView } from "./DefaultTimesheetsView";
import KioskSettings from "./KioskSettings";
import GeolocationSettings from "./GeolocationSettings";
import MobileTimeInputSettings from "./MobileTimeInputSettings";
import { NonSupervisorEditingSettings } from "./NonSupervisorEditingSettings";
import MobileTimesheetCreationSettings from "./MobileTimesheetCreationSettings";
import MobileSupervisorTimesheetViewSettings from "./MobileSupervisorTimesheetViewSettings";
import TimesheetsTableSettings from "./TimesheetsTableSettings";
import TimesheetsPayPeriodEditorSettings from "./TimesheetsPayPeriodEditorSettings";
import TimesheetsBulkCreationSettings from "./TimesheetsBulkCreationSettings";
import DefaultClockInTimeSettings from "./DefaultClockInTimeSettings";
import TimesheetClockOutSettings from "./TimesheetClockOutSettings";
import TimesheetRoundingSettings from "./TimesheetRoundingSettings";
import TimesheetNotificationSettings from "./TimesheetNotificationSettings";
import TimesheetEarningTypeSettings from "./TimesheetEarningTypeSettings";
import TimesheetWorkersCompCodeSettings from "./TimesheetWorkersCompCodeSettings";
import TimesheetBulkImportSettings from "./TimesheetBulkImportSettings";
import TimesheetFieldRequirementSettings from "./TimesheetFieldRequirementSettings";
import TimesheetPolicySettings from "./TimesheetPolicySettings";
import TimesheetCustomFieldsSettings from "./TimesheetCustomFieldsSettings";
import LiveTimesheetSettings from "./LiveTimesheetSettings";
import WeeklyTimesheetReportSettings from "./WeeklyTimesheetReportSettings";
import { useEnhancedSearchParams } from "miter-utils";
import { Toggler } from "ui";
import TimesheetWorkplaceSettings from "./TimesheetWorkplaceSettings";

export type TimesheetSettings = CompanySettings["timesheets"];

export const TimesheetSettings: React.FC = () => {
  const form = useForm();
  const { fetchUserData } = useContext(AppContext);
  const navigate = useNavigate();
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;
  const { settings } = activeCompany!;

  const [timesheetSettings, setTimesheetSettings] = useState(settings.timesheets);

  const togglerConfig = [
    { label: "Clocking in/out", path: "clock_in_out" },
    { label: "Mobile", path: "mobile" },
    { label: "Policies", path: "policies" },
    { label: "Available fields", path: "available_fields" },
    { label: "Breaks", path: "breaks" },
    { label: "Pay rates", path: "pay_rates" },
    { label: "Tables", path: "tables" },
    { label: "Notifications", path: "notifications" },
  ];

  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const togglerState = parsedSearchParams.sub || "clock_in_out";

  const handleToggle = (path: string) => {
    setSearchParams({ sub: path });
  };

  const goBackToTimesheets = () => {
    navigate("/timesheets");
  };

  const updateSettings = async (newSettings) => {
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        $set: { "settings.timesheets": newSettings },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Timesheet settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error("There was an error updating timesheet settings. Our engineers are looking into it!");
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(updateSettings, 500);

  const handleChange = async (newFields: Partial<TimesheetSettings>, wait?: boolean) => {
    const newSettings = { ...timesheetSettings, ...newFields };
    setTimesheetSettings(newSettings);

    if (wait) {
      await updateSettings(newSettings);
    } else {
      updateSettingsDebounced(newSettings);
    }
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Timesheet settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={goBackToTimesheets}>
          Back to timesheets
        </div>
        <div className="flex">
          <h1>Timesheet settings</h1>
        </div>
        <div className="toggler-vertical-container">
          <Toggler
            config={togglerConfig}
            toggle={handleToggle}
            active={togglerState}
            type="vertical"
            wrapperStyle={{ marginTop: 20 }}
          />
          <div className="toggler-right-content">
            {togglerState === "clock_in_out" && (
              <>
                <GeolocationSettings timesheetSettings={timesheetSettings} handleChange={handleChange} />
                <TimesheetRoundingSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                  form={form}
                />
                <DefaultClockInTimeSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
                <TimesheetClockOutSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                  form={form}
                />
              </>
            )}
            {togglerState === "mobile" && (
              <>
                <KioskSettings timesheetSettings={timesheetSettings} handleChange={handleChange} />
                <MobileTimesheetCreationSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                  form={form}
                />
                <NonSupervisorEditingSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
                <MobileTimeInputSettings timesheetSettings={timesheetSettings} handleChange={handleChange} />
                <MobileSupervisorTimesheetViewSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
              </>
            )}
            {togglerState === "policies" && (
              <>
                <TimesheetPolicySettings timesheetSettings={timesheetSettings} handleChange={handleChange} />
              </>
            )}
            {togglerState === "available_fields" && (
              <>
                <TimesheetFieldRequirementSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
                <TimesheetCustomFieldsSettings />
                <TimesheetEarningTypeSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
                <TimesheetWorkplaceSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
                <TimesheetWorkersCompCodeSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
              </>
            )}
            {togglerState === "breaks" && (
              <>
                <AdvancedBreakTime
                  timesheetSettings={timesheetSettings}
                  handleChange={(newSettings) => handleChange(newSettings, true)}
                />
                <AutomaticBreakTime
                  updateTimesheetSettings={handleChange}
                  automaticBreakTimeSettings={timesheetSettings?.automatic_break_time || null}
                  breakTypes={timesheetSettings.break_types}
                  advancedBreakTime={timesheetSettings?.advanced_break_time}
                />
              </>
            )}
            {togglerState === "pay_rates" && (
              <>
                <OvertimeSettings />
                <RateDifferentials />
              </>
            )}
            {togglerState === "tables" && (
              <>
                <TimesheetsTableSettings timesheetSettings={timesheetSettings} handleChange={handleChange} />
                <TimesheetsPayPeriodEditorSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                  form={form}
                />
                <DefaultTimesheetsView
                  updateTimesheetSettings={handleChange}
                  timesheetSettings={timesheetSettings}
                />
                <LiveTimesheetSettings timesheetSettings={timesheetSettings} handleChange={handleChange} />
                <TimesheetsBulkCreationSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
                <TimesheetBulkImportSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
              </>
            )}
            {togglerState === "notifications" && (
              <>
                <TimesheetNotificationSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
                <WeeklyTimesheetReportSettings
                  timesheetSettings={timesheetSettings}
                  handleChange={handleChange}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
