import {
  useActiveCompanyId,
  useDepartmentOptions,
  useJobOptions,
  useLocationOptions,
  useRefetchJobs,
  useSetActivities,
  useWcCodeOptions,
} from "dashboard/hooks/atom-hooks";
import { AggregatedJob, MiterAPI, Activity, Job } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import * as vals from "dashboard/utils/validators";
import { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import { useSetSearchParams } from "miter-utils";
import { JobInput } from "../shared/JobInput";
import { useHasAccessToAccessFilters } from "dashboard/gating";

type Props = {
  job?: AggregatedJob;
  hide: () => void;
};

export const CreateActivityModal: FC<Props> = ({ job, hide }) => {
  // Hooks
  const form = useForm();
  const { register, control, errors, handleSubmit } = form;

  const setSearchParams = useSetSearchParams({ replaceInHistory: true });
  const activeCompanyId = useActiveCompanyId();
  const setActivities = useSetActivities();
  const refetchJobs = useRefetchJobs();
  const jobOptions = useJobOptions({
    predicate: useCallback((j: AggregatedJob | Job) => (job?._id ? j._id === job._id : true), [job?._id]),
  });

  // State
  const [saving, setSaving] = useState(false);
  const wcCodeOptions = useWcCodeOptions();
  const departmentOptions = useDepartmentOptions();
  const locationOptions = useLocationOptions();
  const hasAccessToAccessFilters = useHasAccessToAccessFilters();

  const save = async (data) => {
    if (!activeCompanyId) return;
    setSaving(true);
    try {
      const cleanData: Partial<Activity> = {
        ...data,
        company: activeCompanyId,
        job: job ? job._id : undefined,
        wc_code: data.wc_code ? data.wc_code.value : undefined,
        department_id: data.department ? data.department.value : undefined,
        location_id: data.location ? data.location.value : undefined,
      };

      const response = await MiterAPI.activities.create(cleanData);
      if (response.error) throw new Error(response.error);

      if (job) refetchJobs(job._id);
      setActivities((prev) => prev.concat(response));
      Notifier.success(`Activity successfully created.`);
      setSearchParams({ aid: response._id });
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.log(e);
    }
    hide();
    setSaving(false);
  };

  return (
    <>
      <ActionModal
        headerText={"Create activity"}
        onHide={hide}
        showCancel={true}
        onCancel={hide}
        showSubmit={true}
        onSubmit={handleSubmit(save)}
        submitText={"Create"}
        loading={saving}
        bodyStyle={{ overflowX: "visible" }}
      >
        <div className="vertical-spacer"></div>
        {job && (
          <JobInput
            label="Job*"
            type="select"
            options={jobOptions}
            defaultValue={job._id}
            control={control}
            name="job"
            className="modal"
            errors={errors}
            disabled={true}
            editing={true}
            form={form}
          />
        )}
        <Formblock
          label="Activity name*"
          labelInfo="A human-friendly name used to identify the activity (i.e., Carpentry)"
          type="text"
          register={register(vals.required)}
          name="label"
          className={"modal"}
          errors={errors}
          editing={true}
        />
        <Formblock
          label="Cost code"
          labelInfo="Cost code used to aggregate costs in job costing and reporting"
          type="text"
          register={register}
          name="cost_code"
          className={"modal"}
          errors={errors}
          editing={true}
        />
        <Formblock
          label="Workers' compensation code"
          labelInfo="3-4 digit workers' compensation code assigned by the NCCI or a state rating bureau"
          type="select"
          control={control}
          name="wc_code"
          className={"modal"}
          options={wcCodeOptions}
          errors={errors}
          editing={true}
        />
        {hasAccessToAccessFilters && (
          <>
            <Formblock
              label="Department"
              labelInfo="Department associated with this activity"
              type="select"
              control={control}
              name="department"
              className={"modal"}
              options={departmentOptions}
              errors={errors}
              editing={true}
            />
            <Formblock
              label="Location"
              labelInfo="Location associated with this activity"
              type="select"
              control={control}
              name="location"
              className={"modal"}
              options={locationOptions}
              errors={errors}
              editing={true}
            />
          </>
        )}
        <div className={"vertical-spacer"} />
      </ActionModal>
    </>
  );
};
