import {
  CreateTeamMemberOnboardingChecklistParams as CreateTeamMemberOnboardingChecklistParams_,
  UpdateTeamMemberOnboardingChecklistParams as UpdateTeamMemberOnboardingChecklistParams_,
} from "./../../backend/services/team-member-onboarding-checklist-service";
import { CreateCustomTaskParams, UpdateCustomTaskParams } from "./../../backend/services/custom-task-service";
import {
  CreateWorkersCompGroupParams as CreateWorkersCompGroupParams_,
  UpdateWorkersCompGroupParams as UpdateWorkersCompGroupParams_,
} from "backend/services/wc-group-service";

import { UpdateHolidayScheduleParams as UpdateHolidayScheduleParams_ } from "backend/services/holiday-schedule-service";

import {
  DashboardAuthenticatedUserData as DashboardAuthenticatedUserData_,
  Account as Account_,
} from "backend/services/sessions/session-types";

import { CreateRoleParams, UpdateRoleParams } from "backend/services/role-service";
import {
  CreatePermissionGroupParams,
  TeamMemberOverrideAttributes,
  UpdatePermissionGroupParams,
} from "backend/services/permission-group-service";
import { UpdateAllowanceParams } from "backend/services/allowance-service";
import { ActionableItems } from "backend/services/actionable-item-service";
import { ENDeductionAuditRow, ENDemographicAuditRow } from "backend/services/employee-navigator-service";
import { CreateImportResultParams, UpdateImportResultParams } from "backend/services/import-result-service";
import { CreateCrewParams, UpdateCrewParams } from "backend/services/crew-service";
import {
  CreateFormSubmissionParams as CreateFormSubmissionParams_,
  UpdateFormSubmissionParams as UpdateFormSubmissionParams_,
} from "backend/services/form-submission-service";
import { CreatePerformanceReviewParams } from "backend/controllers/performance/performance-review-controller";
import { UpdatePerformanceReviewCycleParams } from "backend/controllers/performance/performance-review-cycle-controller";
import { CreatePerformanceReviewCycleParams } from "backend/services/performance/performance-review-cycle-service";
import {
  CreateFormParams,
  SendSubmissionRequestsParams,
  UpdateFormParams,
} from "backend/services/form-service";
import type { ForageResponse, ForageRequest } from "backend/utils/forage/forage-types";
import { CreateNoteParams, UpdateNoteParams } from "backend/services/note-service";
import { CreateTagParams, UpdateTagParams } from "backend/services/tag-service";
import {
  PartialCustomFieldValue,
  SaveCustomFieldValueParams,
} from "backend/services/custom-field-value-service";
import { CreateReportViewParams, UpdateReportViewParams } from "backend/services/report-view-service";
import { DashboardCompanyData as DashboardCompanyData_ } from "backend/controllers/initialize-apps-controller";
import { CreateCustomerParams, UpdateCustomerParams } from "backend/services/customer-service";
import {
  TimeOffPolicyParams as TimeOffPolicyParams_,
  TimeOffEnrollmentResult,
  TimeOffEmployee as TimeOffEmployee_,
} from "backend/services/time-off/time-off-policy-service";
import { CreateDepartmentParams, UpdateDepartmentParams } from "backend/services/department-service";
import {
  CreateEquipmentLogParams,
  CreateEquipmentParams,
  UpdateEquipmentLogParams,
  UpdateEquipmentParams,
} from "backend/services/equipment-service";
import {
  CreateEquipmentTimesheetParams,
  UpdateEquipmentTimesheetParams,
} from "backend/services/equipment-timesheet-service";
import { Stripe, StripeInboundTransfer } from "backend/utils/stripe";
import { CreateInboundTransferBody } from "backend/controllers/stripe-transfer-controller";
import { TeamMemberGroupType as TeamMemberGroupType_ } from "backend/types";
import {
  IntegrationOtherLoginInfo,
  ManualSyncOp,
  SetupIntegrationResponse,
} from "backend/services/integrations/integration-connection-service";
import {
  CreateDailyReportParams as CreateDailyReportParams_,
  UpdateDailyReportParams as UpdateDailyReportParams_,
} from "backend/services/daily-report-service";
import { ScheduleEvent } from "backend/services/schedule-service";
import { CreateJobParams as CreateJobParams_, UpdateJobParams } from "backend/services/job-service";
import { CompleteI9Params, CreateI9Params, UpdateI9Params } from "backend/services/i-9-service";
import type { CreateBillParams, UpdateBillParams } from "backend/services/expenses/bill-service";
import qs from "qs";
import {
  DismissTmBodyParams,
  GetPayRateParams,
  RehireTmBodyParams,
} from "backend/controllers/team-members-controller";
import {
  CreateFileRes as CreateFileRes_,
  DeleteFilesRes,
  FileWithUrl as FileWithUrl_,
  S3UrlCreationParam,
  S3UrlCreationResponse as S3UrlCreationResponse_,
} from "backend/services/file-service";
import { UpdateRoleResponse } from "backend/controllers/roles-controller";
import type { SendMessageBody, SendMessageResponse } from "backend/controllers/messages-controller";
import type { CreateAssignmentParams, UpdateAssignmentParams } from "backend/services/assignments-service";
import type { CreateCustomFieldParams, UpdateCustomFieldParams } from "backend/services/custom-field-service";
import type {
  UpdateExpenseParams,
  UpdateThirdPartyCardParams,
  CreateImportedCardTransactionParams,
  CreateSplitCardTransactionParam,
} from "backend/services/expenses/expense-service";
import type { AstradaAccessTokenResponse } from "backend/services/expenses/astrada-service";
import type {
  MiterIntegration,
  MiterIntegrationKey,
  IntegrationEntity,
  IntegrationSyncOpDirection,
  IntegrationSyncOpTrigger,
} from "backend/models/integrations/integrations";
import type {
  ACHTransfer as ACHTransfer_,
  Activity as Activity_,
  Allowance as Allowance_,
  ApprenticeshipProgram as ApprenticeshipProgram_,
  Assignment as Assignment_,
  BankAccount as BankAccount_,
  BenefitsEligibilityGroup as BenefitsEligibilityGroup_,
  Bill as Bill_,
  Breadcrumb as Breadcrumb_,
  BurdenRate as BurdenRate_,
  CardProgram as CardProgram_,
  CardTransactionCategory as CardTransactionCategory_,
  ChangeRequest as ChangeRequest_,
  ChangeRequestPolicy as ChangeRequestPolicy_,
  CheckOriginatedBankAccount as CheckOriginatedBankAccount_,
  Company as Company_,
  CompanyBenefit as CompanyBenefit_,
  Conversation as Conversation_,
  CostType as CostType_,
  Crew as Crew_,
  CustomField as CustomField_,
  CustomFieldValue as CustomFieldValue_,
  CustomHoliday as CustomHoliday_,
  CustomTask as CustomTask_,
  Customer as Customer_,
  DailyReport as DailyReport_,
  Department as Department_,
  ESignatureItem as ESignatureItem_,
  EmployeeBenefit as EmployeeBenefit_,
  Equipment as Equipment_,
  EquipmentLog as EquipmentLog_,
  Expense as Expense_,
  ExpenseCard as ExpenseCard_,
  ExpensePolicy as ExpensePolicy_,
  ExpenseReimbursement as ExpenseReimbursement_,
  ExpenseReimbursementCategory as ExpenseReimbursementCategory_,
  ExpenseReimbursementPolicy as ExpenseReimbursementPolicy_,
  File as File_,
  FileEvent as FileEvent_,
  Form as Form_,
  FormSubmission as FormSubmission_,
  HolidaySchedule as HolidaySchedule_,
  I9 as I9_,
  ImportResult as ImportResult_,
  ImportedCardTransaction as ImportedCardTransaction_,
  IntegrationConnection as IntegrationConnection_,
  IntegrationSync as IntegrationSync_,
  IntegrationSyncResult as IntegrationSyncResult_,
  Job as Job_,
  LeaveType as LeaveType_,
  LedgerAccount as LedgerAccount_,
  LedgerEntry as LedgerEntry_,
  LedgerLineItem as LedgerLineItem_,
  LedgerMapping as LedgerMapping_,
  LiveTimesheet as LiveTimesheet_,
  Location as Location_,
  Message as Message_,
  MiterBillingItem as MiterBillingItem_,
  MiterCardTransaction as MiterCardTransaction_,
  Note as Note_,
  OnboardingChecklistItem as OnboardingChecklistItem_,
  OvertimeRule as OvertimeRule_,
  PayRateGroup as PayRateGroup_,
  PaySchedule as PaySchedule_,
  Payroll as Payroll_,
  PayrollPayment as PayrollPayment_,
  PerDiemRate as PerDiemRate_,
  PerformanceReview as PerformanceReview_,
  PerformanceReviewCycle as PerformanceReviewCycle_,
  PermissionGroup as PermissionGroup_,
  PlaidBankAccount as PlaidBankAccount_,
  PlaidItem as PlaidItem_,
  Policy as Policy_,
  PostTaxDeduction as PostTaxDeduction_,
  RateDifferential as RateDifferential_,
  RawBankAccount as RawBankAccount_,
  ReportView as ReportView_,
  Role as Role_,
  StandardClassification as StandardClassification_,
  StripeConnectedAccount as StripeConnectedAccount_,
  Tag as Tag_,
  TeamMember as TeamMember_,
  TeamMemberChangeRequest as TeamMemberChangeRequest_,
  TeamMemberOnboardingChecklist as TeamMemberOnboardingChecklist_,
  ThirdPartyCard as ThirdPartyCard_,
  ThirdPartyCardTransaction as ThirdPartyCardTransaction_,
  TimeOffPolicy as TimeOffPolicy_,
  TimeOffRequest as TimeOffRequest_,
  TimeOffRequestPolicy as TimeOffRequestPolicy_,
  TimeOffUpdate as TimeOffUpdate_,
  Timesheet as Timesheet_,
  TimesheetBreadcrumbs as TimesheetBreadcrumbs_,
  TimesheetPolicy as TimesheetPolicy_,
  UnionRate as UnionRate_,
  UnionReciprocityRule as UnionReciprocityRule_,
  User as User_,
  UserSurvey as UserSurvey_,
  Vendor as Vendor_,
  WorkersCompCode as WorkersCompCode_,
  WorkersCompGroup as WorkersCompGroup_,
  Workplace as Workplace_,
} from "backend/models";
import type { ObjectId } from "backend/utils/mongoose/utils";
import {
  BreakType as BreakType_,
  BreakTypes as BreakTypes_,
  FieldRequirement as FieldRequirement_,
  AutomaticBreakTimeSettings as AutomaticBreakTimeSettings_,
} from "backend/models/company/company-settings";
import {
  PermissionsConfig as PermissionsConfig_,
  PermissionPaths as PermissionPaths_,
} from "backend/models/permission-group";
import type {
  AggregatedPayroll as AggregatedPayroll_,
  TablePayment,
  TablePayroll,
} from "backend/utils/aggregations/payrollAggregations";
import type {
  MiterFilterField,
  MiterFilterArray,
  MiterFilterObject,
  MiterQueryObject,
  BulkOpFailure,
  SuccessesAndFailuresResponse,
  BulkUpdateParams,
  BulkUpdateResult as BulkUpdateResult_,
  CsvDownloadObj,
  PickAndRestPartial,
} from "backend/utils/utils";
import type { Address as Address_, ArchiveResponse, BulkCreateResponse } from "backend/types";
import {
  CheckBenefit as CheckBenefit_,
  CheckItemBenefit as CheckItemBenefit_,
  CheckBenefitOverride as CheckBenefitOverride_,
  CheckPostTaxDeductionOverride as CheckPostTaxDeductionOverride_,
  CheckCompanyPayday,
  CheckPayday,
  CheckCompanyTaxDocument,
  CheckPostTaxDeduction,
  CheckWorkplace,
  CheckDocument as CheckDocument_,
  CheckBankAccount,
  CheckPayment,
  CheckTaxParameter,
  CheckNetPaySplit,
  CheckJurisdiction,
  CheckTmTaxDocument,
  CheckAddress,
} from "backend/utils/check/check-types";
import type {
  CheckEmployeeOnboardingForm,
  CheckEmployeeOnboardingFormWithParams,
  CheckNetPaySplitCreationParams,
  RetryCheckPaymentBodyParams,
  UpdateCheckTMParams,
} from "backend/utils/check/check-types";
import type { ArchiveUnionRateResponse } from "backend/controllers/union-rates-controller";
import type {
  FringeAmount as FringeAmount_,
  FringeRecord as FringeRecord_,
  UnionRateImportRecord,
} from "backend/services/union-rate-service";
import type {
  CreateExpenseCardBody,
  UpdateExpenseCardBody,
} from "backend/controllers/expenses/expense-cards-controller";
import type {
  StripeAccountResponse as StripeAccountResponse_,
  RetrieveStripeAccountQuery,
  CreateStripeAccountBody,
} from "backend/controllers/stripe-accounts-controller";
import type {
  RetrievePlaidTokenResponse,
  SaveAccessTokenBody,
  PlaidProducts,
  PlaidVerificationEnum,
  PlaidBackendAccountBase,
} from "backend/controllers/plaid-controller";
import { PayRateItem as PayRateItem_, PaymentWarning } from "backend/utils/payroll/types";
import { FilePickerFile } from "ui/form/FilePicker";
import { CustomAggregatePaginateResult } from "backend/utils/aggregations/pagination";
import { IntegrationStatusObj } from "backend/services/integrations/integration-types";

import { Assign } from "utility-types";
import {
  CreateESignatureParams,
  CreateESignatureRequestParams,
} from "backend/services/esignature-item-service";
import { CreateOrUpdateExpenseReimbursementParam as CreateOrUpdateExpenseReimbursementParam_ } from "backend/services/expenses/expense-reimbursement-service";
import { ExpenseReimbursementCategoryCreationParams } from "backend/services/expenses/expense-reimbursement-categories-service";
import { CardTransactionCategoryCreationParams } from "backend/services/expenses/card-transaction-categories-service";
import { MileageLocation as MileageLocation_ } from "backend/models/expense-reimbursement";
import {
  AbstractExpenseReimbursementResponse,
  AbstractExpenseReimbursementRequest,
} from "backend/controllers/expenses/expense-reimbursement-controller";
import {
  UpdateLedgerEntryParams,
  EnrichedLedgerLineItem as EnrichedLedgerLineItem_,
  EnrichedLedgerEntry as EnrichedLedgerEntry_,
  TableLedgerEntry as TableLedgerEntry_,
} from "backend/services/ledger-entry-service";
import { UpdatePayScheduleParams } from "backend/services/pay-schedule-service";
import { UnionRateFringe as UnionRateFringe_ } from "backend/models/union-rate";
import { CompanyUser as CompanyUser_ } from "backend/models/user";
import { FileToCreateWithoutBlob, FileToUpload, serialize, uploadToS3WithSignedUrl } from "miter-utils";

import { CreateHolidayScheduleParams } from "backend/services/holiday-schedule-service";
import { MiterError as MiterError_ } from "backend/services/error-service";
import { GetPayrollQuery } from "backend/controllers/payrolls-controller";
import { GetIntacctObjectsResponse } from "backend/controllers/integrations/integrations-controller";
import { GetTimeOffRequestsForPayPeriodOrPayrollInputs } from "backend/controllers/time-off-requests-controller";
import { Option } from "ui/form/Input";
import { SyncableObjectResult } from "backend/models/integrations/integrations";
import { JonasCompany } from "backend/services/jonas/jonas-types";
import { createObjectMap, handleForageResponse } from "./utils";
import {
  authTokenAtom,
  defaultAtomStore,
  hitUnauthorizedErrorAtom,
  newReleaseAvailableAtom,
  showNewReleaseModalAtom,
  userAtom,
} from "./atoms";
import { sleep } from "./utils";
import { CreateLedgerAccountParams } from "backend/services/ledger-accounts-service";
import { CreateTeamMemberParams, TeamMemberPaymentParams } from "backend/utils/team-members/createTeamMember";
import { TeamMemberEmploymentHistoryParams } from "backend/utils/team-members/import-employment-history";
import {
  LinkedTeamMember,
  TmCompanyDefinedAttributes,
  TmOnLeaveBodyParams,
  UpdateCompanyDefinedAttributesEntry,
} from "backend/services/team-member-service";
import {
  CleanedBenefitFormData,
  DescendentBenefitFailure,
  UpdateEmployeeBenefitParams,
} from "backend/services/benefits-service";
import { LeaveTypeRequest as LeaveTypeRequest_ } from "backend/controllers/leave-type-controller";
import { FoundationGlEntryCsvRow } from "backend/services/foundation/foundation-service";
import { ImportInputsRaw } from "backend/models/import-result";
import { ExternalFinancialAccountType } from "backend/utils/plaid";
import { CreateActivityParams, UpdateActivityParams } from "backend/services/activity-service";
import { CreateNewPlaidAccountResult } from "backend/services/plaid-service";
import {
  BuildDraftPayRatesResponse,
  DuplicateTimesheetsResponse,
  DuplicateTimesheetsParams,
  SplitTimesheetParams,
  SplitTimesheetsResults,
} from "backend/services/timesheets/timesheets-service";
import { RecodeTimesheetParams } from "backend/services/timesheets/recode-timesheets";
import {
  AggregatedCandidate,
  AggregatedJobApplication,
  AggregatedJobPosting,
  Candidate,
  CreateJobPostingParams,
  ImportJobApplicationsParams,
  UpdateJobApplicationParams,
  UpdateJobPostingParams,
} from "./types/ats";
import type {
  PolicyRule as PolicyRule_,
  Condition as Condition_,
  TimesheetPolicyRule as TimesheetPolicyRule_,
  TimesheetPolicyConfig as TimesheetPolicyConfig_,
} from "backend/models/policy";
import type {
  ApprovalFlow as ApprovalFlow_,
  ApproverGroup as AppoverGroup_,
  ApprovalHistoryItem as ApprovalHistoryItem_,
  ApprovalHistory as ApprovalHistory_,
  ApprovalStage as ApprovalStage_,
  TakeActionOnItemsResponse,
  KickBackItemsParams,
  ApproverGroupType,
} from "backend/services/approvals/types";
import type { PolicyCreationParams } from "backend/services/policy-service";
import { PermissionsValidationResponse } from "backend/services/procore/auth/procore-auth-service";
import { ConnectProcoreToMiterCompanyParams } from "backend/services/procore/procore-utils";
import { CheckrPackage, CreateScreeningParams, HierarchicalNode, Screening } from "./types/screening-types";
import {
  AddPaperCheckNumBodyParams,
  AggregatedMiterEarning as AggregatedMiterEarning_,
  GetMockedEarningsParams,
  PreAggregatedMiterEarning as PreAggregatedMiterEarning_,
  UpdatePaperCheckNumberParams,
} from "backend/services/payroll-service";
import {
  QBCustomersOptionsResponse,
  ActivityMappingOptionsResponse,
} from "backend/services/quickbooks/quickbooks-types";
import { TimeOffPolicyLevelConfig as TimeOffPolicyLevelConfig_ } from "backend/models/time-off-policy";
import {
  TMTimeOffPolicy as TMTimeOffPolicy_,
  TeamMemberIntegrations as TeamMemberIntegrations_,
  TeamMemberOnLeave as TeamMemberOnLeave_,
} from "backend/models/teamMember/team-member";
import {
  AggregatedOnboardingChecklistItem,
  CreateOnboardingChecklistParams,
  OnboardingChecklist,
  OnboardingChecklistCompletionData,
  SignESignatureRequestParams,
  UpdateOnboardingChecklistItemParams,
  UpdateOnboardingChecklistParams,
} from "./types/onboarding-types";
import {
  CertificationType,
  Certification,
  CreateCertificationTypeParams,
  CreateCertificationParams,
  UpdateCertificationParams,
  UpdateCertificationTypeParams,
  AggregatedCertificationType,
  AggregatedCertification,
} from "./types/certification-types";
import { ProcoreCompanyCompact, ProcoreCustomFieldDefinition } from "backend/services/procore/procore-types";
import {
  DraftTimesheet,
  PreppedBulkEntryTimesheet,
} from "./pages/timesheets/BulkCreateTimesheets/BulkCreateTimesheets";
import { EarningRecord } from "./pages/payrolls/viewPayroll/EarningsImporter";
import { IntacctConfigObjectType } from "backend/services/intacct/intacct-types";
import { AuditLog as AuditLog_ } from "backend/models/audit-log";
import { ExpenseReimbursementModalFormData } from "./pages/expenses/modals/ExpenseReimbursementModalForm";
import { DraftReimbursement } from "./components/expense-reimbursements/BulkCreateExpenseReimbursementPageModal";
import { TimesheetSection as TimesheetSection_, TimesheetSignOff } from "backend/models/timesheet";
import { TimeOffRequestFormSchedule } from "./pages/time-off/TimeOffRequestModal";
import { EnrolleeRecord } from "./components/time-off/TimeOffPolicyEnrolleeImporter";
import { CreateRawBankAccountParams } from "backend/services/bank-accounts/bank-accounts-service";
import { DeepPartial } from "utility-types";
import { Sage100PayrollExportInputs } from "backend/services/sage100/sage100-service";
import { NetSuiteSubsidiary } from "backend/services/netsuite/types/netsuite-types";
import {
  AggregatedFillableDocument,
  AggregatedFillableTemplate,
  CreateFillableTemplateParams,
  FetchAnvilFieldsResponse,
  GenerateEmbedURLResponse,
  UpdateFillableTemplateParams,
} from "./types/fillable-template-types";
import { MiterDefinedNetSuiteCostCode } from "backend/services/netsuite/types/netsuite-cost-code-types";
import {
  CreateLiveTimesheetParams,
  UpdateLiveTimesheetParams,
} from "backend/services/live-timesheet-service";
import { PayrollOverride } from "./pages/payrolls/viewPayroll/PayrollOverridesImporter";
import { ShippingAddressType } from "backend/models/expense-card";
import { UpdateTeamMembersParams } from "../../backend/utils/team-members/updateTeamMember";
import { BillPaymentStatusEnum } from "backend/models/bill";
import { AcumaticaNonStockItemSourceData } from "backend/services/acumatica/types/acumatica-non-stock-item-types";
import { AcumaticaCorporateCardSourceData } from "backend/services/acumatica/types/acumatica-expense-types";
import { CreateLocationParams, UpdateLocationParams } from "backend/services/location-service";
import { AggregatedTimesheet as AggregatedTimesheet_ } from "backend/utils/aggregations";
import { AggregatedDailyReport as AggregatedDailyReport_ } from "backend/utils/aggregations/dailyReportAggregations";
import { AggregatedTimeOffRequest as AggregatedTimeOffRequest_ } from "backend/utils/aggregations/timeOffAggregations";
import { AggregatedExpenseReimbursement as AggregatedExpenseReimbursement_ } from "backend/utils/aggregations/expenseReimbursementAggregations";
import { AggregatedForm as AggregatedForm_ } from "backend/utils/aggregations/formAggregations";
import { FormWithSubmissionCount as FormWithSubmissionCount_ } from "backend/utils/aggregations/formAggregations";
import { AggregatedEquipmentLog as AggregatedEquipmentLog_ } from "backend/utils/aggregations/equipmentLogAggregations";
import { AggregatedEquipmentTimesheet as AggregatedEquipmentTimesheet_ } from "backend/utils/aggregations/equipmentTimesheetAggregations";
import { AggregatedCompany as AggregatedCompany_ } from "backend/utils/aggregations/companyAggregations";
import { AggregatedRole as AggregatedRole_ } from "backend/utils/aggregations/roleAggregations";
import { AggregatedTeamMember as AggregatedTeamMember_ } from "backend/utils/aggregations/teamMemberAggregations";
import { AggregatedVendor as AggregatedVendor_ } from "backend/utils/aggregations/vendorAggregations";
import {
  AggregatedJob as AggregatedJob_,
  SlimJobTeamMember as SlimJobTeamMember_,
} from "backend/utils/aggregations/jobAggregations";
import { AggregatedEmployeeBenefit as AggregatedEmployeeBenefit_ } from "backend/utils/aggregations/employeeBenefitAggregations";
import { AggregatedPayRateGroup as AggregatedPayRateGroup_ } from "backend/utils/aggregations/payRateGroupAggregations";
import { AggregatedAssignment as AggregatedAssignment_ } from "backend/utils/aggregations/assignmentAggregations";
import { AggregatedI9 as AggregatedI9_ } from "backend/utils/aggregations/i9Aggregations";
import { AggregatedFile as AggregatedFile_ } from "backend/utils/aggregations/fileAggregations";
import { AggregatedPerformanceReviewCycle as AggregatedPerformanceReviewCycle_ } from "backend/utils/aggregations/performanceReviewCycleAggregations";
import { AggregatedPerformanceReview as AggregatedPerformanceReview_ } from "backend/utils/aggregations/performanceReviewAggregations";
import { AggregatedLiveTimesheet as AggregatedLiveTimesheet_ } from "backend/utils/aggregations/liveTimesheetAggregations";
import { AggregatedCardProgram as AggregatedCardProgram_ } from "backend/utils/aggregations/expense-management/cardProgramAggregations";
import { AggregatedThirdPartyCard as AggregatedThirdPartyCard_ } from "backend/utils/aggregations/thirdPartyCardAggregations";
import { AggregatedChangeRequest as AggregatedChangeRequest_ } from "backend/utils/aggregations/changeRequestAggregations";
import { CreateAllowanceParams as CreateAllowanceParams_ } from "backend/services/allowance-service";
import { ArchiveTimesheetsParams as ArchiveTimesheetsParams_ } from "backend/services/timesheets/timesheets-service";
import { AcumaticaBranch } from "backend/services/acumatica/types/acumatica-location-types";
import { AggregatedExpense as AggregatedExpense_ } from "backend/utils/aggregations/expenseAggregations";
import { AggregatedBill as AggregatedBill_ } from "backend/utils/aggregations/billAggregations";
import { AggregatedTeamMemberOnboardingTask as AggregatedTeamMemberOnboardingTask_ } from "backend/utils/aggregations/team-member-onboarding/teamMemberOnboardingChecklistAggregation";
import { AggregatedTeamMemberOnboardingChecklist as AggregatedTeamMemberOnboardingChecklist_ } from "backend/utils/aggregations/team-member-onboarding/teamMemberOnboardingChecklistAggregation";
import { Sage300JcdWarning } from "backend/services/sage300/sage300-types";
import {
  TeamMemberOnboardingTask as TeamMemberOnboardingTask_,
  PersonalInfoOnboardingTask as PersonalInfoOnboardingTask_,
  EEOOnboardingTask as EEOOnboardingTask_,
  WithholdingsOnboardingTask as WithholdingsOnboardingTask_,
  PaymentMethodOnboardingTask as PaymentMethodOnboardingTask_,
  BankAccountsOnboardingTask as BankAccountsOnboardingTask_,
  I9EmployeeOnboardingTask as I9EmployeeOnboardingTask_,
  I9EmployerOnboardingTask as I9EmployerOnboardingTask_,
  FillDocumentOnboardingTask as FillDocumentOnboardingTask_,
  SignDocumentOnboardingTask as SignDocumentOnboardingTask_,
  FormOnboardingTask as FormOnboardingTask_,
  CertificationOnboardingTask as CertificationOnboardingTask_,
  CustomFieldOnboardingTask as CustomFieldOnboardingTask_,
  CustomOnboardingTask as CustomOnboardingTask_,
  ScreeningOnboardingTask as ScreeningOnboardingTask_,
} from "backend/services/team-member-onboarding/team-member-onboarding-types";
import { EquipmentTimesheet as EquipmentTimesheet_ } from "backend/models/equipment-timesheet";
import { RecruitingConversation } from "./types/chat";
import { JobPhotoDetails } from "backend/controllers/jobs-controller";
import { CreateOrUpdateVendorParams } from "backend/services/vendor-service";
import {
  CreateOfferLetterParams,
  CreateOfferTemplateParams,
  OfferLetter,
  OfferLetterWithAnvilLink,
  OfferTemplate,
  UpdateOfferLetterParams,
  UpdateOfferTemplateParams,
} from "./types/offers-types";
import { JobScope as JobScope_ } from "backend/models/job";

export type FrontendModel<T> = T extends ObjectId
  ? string
  : T extends Array<infer Item>
  ? FrontendModel<Item>[]
  : T extends Record<string, unknown>
  ? { [K in keyof T]: FrontendModel<T[K]> }
  : T;

export type { MiterFilterField, MiterFilterArray, MiterFilterObject };

export type SearchAssignmentsResponse = $TSFixMe;

export type TeamMemberGroupType = FrontendModel<TeamMemberGroupType_>;
export type CreateJobParams = FrontendModel<CreateJobParams_>;

export type MiterIntegrationForCompany<T = IntegrationConnection> = MiterIntegration & {
  connection?: T & { user?: Partial<User> };
};

export type MiterIntegrationForCompanyWithConnection<T = IntegrationConnection> = Required<
  Pick<MiterIntegrationForCompany<T>, "connection">
>;

export type PermissionGroupMemberList = {
  roles: Role[];
  teamMembers: TeamMember[];
};

export type IntegrationConnectionForQB = {
  [K in keyof IntegrationConnection]: K extends "integration_key" ? QBTypes : IntegrationConnection[K];
};

export type MiterError = MiterError_ & { error_status: number };

export type QBTypes = "qbd" | "qbo";

// Spend Management re-exports
export type {
  // Stripe accounts
  Stripe,
  RetrieveStripeAccountQuery,
  // Expense cards
  CreateExpenseCardBody,
  UpdateExpenseCardBody,
  ShippingAddressType,
  // Plaid
  PlaidVerificationEnum,
  PlaidBackendAccountBase,
  // general Expenses,
  CreateImportedCardTransactionParams,
};

export type ACHTransfer = FrontendModel<ACHTransfer_>;
export type Account = FrontendModel<Account_>;
export type Activity = FrontendModel<Activity_>;
export type Address = Address_;
export type Allowance = FrontendModel<Allowance_>;
export type AppoverGroup = FrontendModel<AppoverGroup_>;
export type ApprenticeshipProgram = FrontendModel<ApprenticeshipProgram_>;
export type ApprovalFlow = FrontendModel<ApprovalFlow_>;
export type ApprovalHistory = FrontendModel<ApprovalHistory_>;
export type ApprovalHistoryItem = FrontendModel<ApprovalHistoryItem_>;
export type ApprovalItem = FrontendModel<
  | Expense
  | AggregatedExpense
  | ExpenseReimbursement
  | AggregatedTimesheet
  | AggregatedTimeOffRequest
  | TimeOffRequest
  | TeamMemberChangeRequest
>;
export type ApprovalStage = FrontendModel<ApprovalStage_>;
export type Assignment = FrontendModel<Assignment_>;
export type AuditLog = FrontendModel<AuditLog_>;
export type AutomaticBreakTimeSettings = FrontendModel<AutomaticBreakTimeSettings_>;
export type BenefitsEligibilityGroup = FrontendModel<BenefitsEligibilityGroup_>;
export type Bill = FrontendModel<Bill_>;
export type Breadcrumb = FrontendModel<Breadcrumb_>;
export type BulkUpdateResult<T = $TSFixMe> = BulkUpdateResult_<T>;
export type BurdenRate = FrontendModel<BurdenRate_>;
export type CardProgram = FrontendModel<CardProgram_>;
export type CardTransactionCategory = FrontendModel<CardTransactionCategory_>;
export type ChangeRequest = FrontendModel<ChangeRequest_>;
export type ChangeRequestPolicy = FrontendModel<ChangeRequestPolicy_>;
export type Company = FrontendModel<Company_>;
export type CompanyBenefit = FrontendModel<CompanyBenefit_>;
export type CompanyUser = FrontendModel<CompanyUser_>;
export type Condition = FrontendModel<Condition_>;
export type Conversation = FrontendModel<Conversation_>;
export type CostType = FrontendModel<CostType_>;
export type CreateOrUpdateExpenseReimbursementParam = FrontendModel<CreateOrUpdateExpenseReimbursementParam_>;
export type Crew = FrontendModel<Crew_>;
export type CustomField = FrontendModel<CustomField_>;
export type CustomFieldValue = FrontendModel<CustomFieldValue_>;
export type CustomHoliday = FrontendModel<CustomHoliday_>;
export type Customer = FrontendModel<Customer_>;
export type DailyReport = FrontendModel<DailyReport_>;
export type DashboardAuthenticatedUserData = FrontendModel<DashboardAuthenticatedUserData_>;
export type DashboardCompanyData = FrontendModel<DashboardCompanyData_>;
export type Department = FrontendModel<Department_>;
export type ESignatureItem = FrontendModel<ESignatureItem_>;
export type EmployeeBenefit = FrontendModel<EmployeeBenefit_>;
export type Equipment = FrontendModel<Equipment_>;
export type EquipmentLog = FrontendModel<EquipmentLog_>;
export type EquipmentTimesheet = FrontendModel<EquipmentTimesheet_>;
export type Expense = FrontendModel<Expense_>;
export type ExpenseCard = FrontendModel<ExpenseCard_>;
export type ExpensePolicy = FrontendModel<ExpensePolicy_>;
export type ExpenseReimbursement = FrontendModel<ExpenseReimbursement_>;
export type ExpenseReimbursementCategory = FrontendModel<ExpenseReimbursementCategory_>;
export type ExpenseReimbursementPolicy = FrontendModel<ExpenseReimbursementPolicy_>;
export type FieldRequirement = FrontendModel<FieldRequirement_>;
export type File = FrontendModel<File_>;
export type FileEvent = FrontendModel<FileEvent_>;
export type FileWithUrl = FrontendModel<FileWithUrl_>;
export type Form = FrontendModel<Form_>;
export type FormSubmission = FrontendModel<FormSubmission_>;
export type FringeAmount = FrontendModel<FringeAmount_>;
export type FringeRecord = FrontendModel<FringeRecord_>;
export type HolidaySchedule = FrontendModel<HolidaySchedule_>;
export type I9 = FrontendModel<I9_>;
export type ImportResult = FrontendModel<ImportResult_>;
export type ImportedCardTransaction = FrontendModel<ImportedCardTransaction_>;
export type IntegrationConnection = FrontendModel<IntegrationConnection_>;
export type IntegrationSync = FrontendModel<IntegrationSync_>;
export type IntegrationSyncResult = FrontendModel<IntegrationSyncResult_>;
export type Job = FrontendModel<Job_>;
export type LedgerAccount = FrontendModel<LedgerAccount_>;
export type LedgerEntry = FrontendModel<LedgerEntry_>;
export type LedgerLineItem = FrontendModel<LedgerLineItem_>;
export type LedgerMapping = FrontendModel<LedgerMapping_>;
export type LiveTimesheet = FrontendModel<LiveTimesheet_>;
export type Location = FrontendModel<Location_>;
export type Message = FrontendModel<Message_>;
export type MiterBillingItem = FrontendModel<MiterBillingItem_>;
export type MiterCardTransaction = FrontendModel<MiterCardTransaction_>;
export type Note = FrontendModel<Note_>;
export type OnboardingChecklistItem = FrontendModel<OnboardingChecklistItem_>;
export type OvertimeRule = FrontendModel<OvertimeRule_>;
export type PayRateGroup = FrontendModel<PayRateGroup_>;
export type PaySchedule = FrontendModel<PaySchedule_>;
export type Payroll = FrontendModel<Payroll_>;
export type PayrollPayment = FrontendModel<PayrollPayment_>;
export type PayRateItem = FrontendModel<PayRateItem_>;
export type PerDiemRate = FrontendModel<PerDiemRate_>;
export type PerDiemRateCreationParams = Omit<PerDiemRate, "_id" | "created_at" | "updated_at" | "archived">;
export type PerformanceReview = FrontendModel<PerformanceReview_>;
export type PerformanceReviewCycle = FrontendModel<PerformanceReviewCycle_>;
export type PermissionGroup = FrontendModel<PermissionGroup_>;
export type PermissionPaths = FrontendModel<PermissionPaths_>;
export type PermissionsConfig = FrontendModel<PermissionsConfig_>;
export type PlaidItem = FrontendModel<PlaidItem_>;
export type Policy = FrontendModel<Policy_>;
export type PolicyRule = FrontendModel<PolicyRule_>;
export type PostTaxDeduction = FrontendModel<PostTaxDeduction_>;
export type RateDifferential = FrontendModel<RateDifferential_>;
export type ReportView = FrontendModel<ReportView_>;
export type Role = FrontendModel<Role_>;
export type StandardClassification = FrontendModel<StandardClassification_>;
export type StripeAccountResponse = FrontendModel<StripeAccountResponse_>;
export type StripeConnectedAccount = FrontendModel<StripeConnectedAccount_>;
export type TMTimeOffPolicy = FrontendModel<TMTimeOffPolicy_>;
export type Tag = FrontendModel<Tag_>;
export type TeamMember = FrontendModel<TeamMember_>;
export type TeamMemberChangeRequest = FrontendModel<TeamMemberChangeRequest_>;
export type TeamMemberIntegrations = FrontendModel<TeamMemberIntegrations_>;
export type TeamMemberOnLeave = FrontendModel<TeamMemberOnLeave_>;
export type LeaveType = FrontendModel<LeaveType_>;
export type LeaveTypeRequest = FrontendModel<LeaveTypeRequest_>;
export type TentativeApprovalItem =
  | DraftTimesheet
  | ExpenseReimbursementModalFormData
  | DraftReimbursement
  | Partial<TimeOffRequest>;
export type ThirdPartyCard = FrontendModel<ThirdPartyCard_>;
export type ThirdPartyCardTransaction = FrontendModel<ThirdPartyCardTransaction_>;
export type TimeOffEmployee = FrontendModel<TimeOffEmployee_>;
export type TimeOffPolicy = FrontendModel<TimeOffPolicy_>;
export type TimeOffPolicyLevelConfig = FrontendModel<TimeOffPolicyLevelConfig_>;
export type TimeOffPolicyParams = FrontendModel<TimeOffPolicyParams_>;
export type TimeOffRequest = FrontendModel<TimeOffRequest_>;
export type TimeOffRequestPolicy = FrontendModel<TimeOffRequestPolicy_>;
export type TimeOffUpdate = FrontendModel<TimeOffUpdate_>;
export type Timesheet = FrontendModel<Timesheet_>;
export type TimesheetBreadcrumbs = FrontendModel<TimesheetBreadcrumbs_>;
export type TimesheetPolicy = FrontendModel<TimesheetPolicy_>;
export type TimesheetPolicyConfig = FrontendModel<TimesheetPolicyConfig_>;
export type TimesheetPolicyRule = FrontendModel<TimesheetPolicyRule_>;
export type TimesheetSection = FrontendModel<TimesheetSection_>;
export type UnionRate = FrontendModel<UnionRate_>;
export type UnionRateFringe = FrontendModel<UnionRateFringe_>;
export type UnionReciprocityRule = FrontendModel<UnionReciprocityRule_>;
export type User = FrontendModel<User_>;
export type UserSurvey = FrontendModel<UserSurvey_>;
export type Vendor = FrontendModel<Vendor_>;
export type WorkersCompCode = FrontendModel<WorkersCompCode_>;
export type WorkersCompGroup = FrontendModel<WorkersCompGroup_>;
export type WorkersCompGroupMapping = FrontendModel<WorkersCompGroup["mappings"][number]>;

export type CustomTask = FrontendModel<CustomTask_>;
export type TeamMemberOnboardingChecklist = FrontendModel<TeamMemberOnboardingChecklist_>;
export type TeamMemberOnboardingTask = FrontendModel<TeamMemberOnboardingTask_>;
export type PersonalInfoOnboardingTask = FrontendModel<PersonalInfoOnboardingTask_>;
export type EEOOnboardingTask = FrontendModel<EEOOnboardingTask_>;
export type WithholdingsOnboardingTask = FrontendModel<WithholdingsOnboardingTask_>;
export type PaymentMethodOnboardingTask = FrontendModel<PaymentMethodOnboardingTask_>;
export type BankAccountsOnboardingTask = FrontendModel<BankAccountsOnboardingTask_>;
export type I9EmployeeOnboardingTask = FrontendModel<I9EmployeeOnboardingTask_>;
export type I9EmployerOnboardingTask = FrontendModel<I9EmployerOnboardingTask_>;
export type FillDocumentOnboardingTask = FrontendModel<FillDocumentOnboardingTask_>;
export type SignDocumentOnboardingTask = FrontendModel<SignDocumentOnboardingTask_>;
export type FormOnboardingTask = FrontendModel<FormOnboardingTask_>;
export type CertificationOnboardingTask = FrontendModel<CertificationOnboardingTask_>;
export type CustomFieldOnboardingTask = FrontendModel<CustomFieldOnboardingTask_>;
export type CustomOnboardingTask = FrontendModel<CustomOnboardingTask_>;
export type ScreeningOnboardingTask = FrontendModel<ScreeningOnboardingTask_>;

export type CreateTeamMemberOnboardingChecklistParams =
  FrontendModel<CreateTeamMemberOnboardingChecklistParams_>;
export type UpdateTeamMemberOnboardingChecklistParams =
  FrontendModel<UpdateTeamMemberOnboardingChecklistParams_>;
export type CreateFileRes = FrontendModel<CreateFileRes_>;
export type S3UrlCreationResponse = FrontendModel<S3UrlCreationResponse_>;
export type Workplace = FrontendModel<Workplace_>;

export type TimeOffEnrolleesTableEntry = Assign<
  AggregatedTeamMember,
  {
    balance?: number | null;
    enrollment_status: "enroll" | "unenroll";
    updated: boolean;
    level_id?: string | null;
  }
>;

export type AggregatedTimesheet = FrontendModel<AggregatedTimesheet_>;
export type AggregatedTimeOffRequest = FrontendModel<AggregatedTimeOffRequest_>;
export type AggregatedExpenseReimbursement = FrontendModel<AggregatedExpenseReimbursement_>;
export type AggregatedForm = FrontendModel<AggregatedForm_>;
export type FormWithSubmissionCount = FrontendModel<FormWithSubmissionCount_>;
export type AggregatedDailyReport = FrontendModel<AggregatedDailyReport_>;
export type AggregatedEquipmentLog = FrontendModel<AggregatedEquipmentLog_>;
export type AggregatedEquipmentTimesheet = FrontendModel<AggregatedEquipmentTimesheet_>;
export type AggregatedChangeRequest = FrontendModel<AggregatedChangeRequest_>;
export type AggregatedCompany = FrontendModel<AggregatedCompany_>;
export type AggregatedRole = FrontendModel<AggregatedRole_>;
export type AggregatedTeamMember = FrontendModel<AggregatedTeamMember_>;
export type PreAggregatedMiterEarning = FrontendModel<PreAggregatedMiterEarning_>;
export type AggregatedMiterEarning = FrontendModel<AggregatedMiterEarning_>;
export type AggregatedTeamMemberWithI9 = Assign<AggregatedTeamMember, { I9?: AggregatedI9 }>;
export type AggregatedPayroll = FrontendModel<AggregatedPayroll_>;
export type AggregatedVendor = FrontendModel<AggregatedVendor_>;
export type AggregatedJob = FrontendModel<AggregatedJob_>;
export type AggregatedEmployeeBenefit = FrontendModel<AggregatedEmployeeBenefit_>;
export type AggregatedPayRateGroup = FrontendModel<AggregatedPayRateGroup_>;
export type AggregatedAssignment = FrontendModel<AggregatedAssignment_>;
export type AggregatedI9 = FrontendModel<AggregatedI9_>;
export type AggregatedFile = FrontendModel<AggregatedFile_>;
export type AggregatedPerformanceReviewCycle = FrontendModel<AggregatedPerformanceReviewCycle_>;
export type AggregatedPerformanceReview = FrontendModel<AggregatedPerformanceReview_>;
export type AggregatedLiveTimesheet = FrontendModel<AggregatedLiveTimesheet_>;
export type AggregatedCardProgram = FrontendModel<AggregatedCardProgram_>;
export type MileageLocation = FrontendModel<MileageLocation_>;
export type AggregatedThirdPartyCard = FrontendModel<AggregatedThirdPartyCard_>;
export type AggregatedBill = FrontendModel<AggregatedBill_>;
export type AggregatedExpense = FrontendModel<AggregatedExpense_>;
export type AggregatedTeamMemberOnboardingTask = FrontendModel<AggregatedTeamMemberOnboardingTask_>;
export type AggregatedTeamMemberOnboardingChecklist = FrontendModel<AggregatedTeamMemberOnboardingChecklist_>;
export type CreateDailyReportParams = FrontendModel<CreateDailyReportParams_>;
export type UpdateDailyReportParams = FrontendModel<UpdateDailyReportParams_>;
export type SlimJobTeamMember = FrontendModel<SlimJobTeamMember_>;

export type EnrichedLedgerLineItem = FrontendModel<EnrichedLedgerLineItem_>;
export type EnrichedLedgerEntry = FrontendModel<EnrichedLedgerEntry_>;
export type TableLedgerEntry = FrontendModel<TableLedgerEntry_>;

export type CreateTimesheetParams = {
  company: string;
  clock_in: number;
  clock_out: number;
  team_member: string;
  job?: string | null;
  images?: string[];
  activity?: string | null;
  unpaid_break_time?: number | null;
  creation_method: string;
  live_timesheet_id?: string;
  breaks?: Timesheet["breaks"];
  notes?: string | null;
  department_id?: string | null;
  timezone?: string;
  status?: Timesheet["status"];
  time_off_policy_id?: string | undefined | null;
  classification_override?: string | null;
  rate_differential_id?: string | null;
  injury?: boolean | null | string;
  sign_off?: TimesheetSignOff | null | undefined;
  wc_code?: string | null;
  custom_field_values?: SaveCustomFieldValueParams[] | PartialCustomFieldValue[];
  equipment_ids?: string[] | null;
};

export type BreakType = FrontendModel<BreakType_>;
export type BreakTypes = FrontendModel<BreakTypes_>;

export type JobScope = JobScope_;

export type CreateAllowanceParams = CreateAllowanceParams_;

export type CreateMultipleTimesheetsParams = {
  timesheets: CreateTimesheetParams[];
};

export type ImportParams<T, S> = {
  clean_inputs: T[];
  raw_inputs?: S[];
};

export type ImportTimesheetsParams = {
  clean_inputs: CreateTimesheetParams[];
  raw_inputs: ImportInputsRaw[];
};

export type ImportExpenseReimbursementParams = {
  clean_inputs: CreateOrUpdateExpenseReimbursementParam[];
  raw_inputs: ImportInputsRaw[];
};

export type ImportExpensesParams = {
  clean_inputs: CreateImportedCardTransactionParams[];
  raw_inputs: ImportInputsRaw[];
};

export type ImportActivitiesParams = {
  clean_inputs: CreateActivityParams[];
  raw_inputs: ImportInputsRaw;
};

export type ImportEmployeeBenefitsParams = {
  clean_inputs: CleanedBenefitFormData[];
  raw_inputs: ImportInputsRaw;
};

export type ImportTeamMembersParams = {
  clean_inputs: CreateTeamMemberParams[];
  raw_inputs: ImportInputsRaw;
  upsert: boolean;
};

export type ImportEmploymentHistoryParams = {
  clean_inputs: TeamMemberEmploymentHistoryParams[];
  raw_inputs: ImportInputsRaw;
  overwrite?: boolean;
  company_id: string;
};

export type ImportEquipmentTimesheetsParams = {
  clean_inputs: CreateEquipmentTimesheetParams[];
  raw_inputs: ImportInputsRaw[];
};

export type CreateMultipleTimesheetsResponse = {
  createdTimesheets: Timesheet[];
  errors: string[];
  error?: string;
};

export type ApproveTimesheetsParams =
  | { ids: string[]; filter?: undefined; team_member_id?: string; role_id?: string }
  | { ids?: undefined; filter: MiterFilterArray; team_member_id?: string; role_id?: string };

export type LegacyApproveTimesheetsResponse = {
  already_approved: Timesheet[];
  newly_approved: Timesheet[];
};

export type UnapproveTimesheetsParams = ApproveTimesheetsParams;

export type LegacyUnapproveTimesheetsResponse = {
  newly_unapproved: Timesheet[];
  unable_to_unapprove: Timesheet[];
};

export type PolicyApproveTimesheetsRes = {
  success: string[];
  failures: { _id: string; message: string }[];
};

export type PolicyUnapproveTimesheetsRes = PolicyApproveTimesheetsRes;

export type ApproveTimesheetsResponse = LegacyApproveTimesheetsResponse | PolicyApproveTimesheetsRes;
export type UnapproveTimesheetsResponse = LegacyUnapproveTimesheetsResponse | PolicyUnapproveTimesheetsRes;

export type UpdateTimesheetParams = {
  company?: string;
  clock_in?: number;
  clock_out?: number;
  job?: string | null | undefined;
  images?: string[];
  activity?: string | null | undefined;
  unpaid_break_time?: number;
  archived?: boolean;
  status?: Timesheet["status"];
  sign_off?: TimesheetSignOff | null | undefined;
  rate_differential_id?: string | null;
};

export type UpdateManyTimesheetsParams = {
  update: UpdateTimesheetParams;
  custom_field_values?: PartialCustomFieldValue[];
} & ({ ids: string[] } | { filter: MiterFilterArray });

export type MarkTimesheetsAsPaidParams = { ids: string[] } | { filter: MiterFilterArray };

export type ArchiveTimesheetsParams = ArchiveTimesheetsParams_;

export type ArchiveTimesheetsResponse =
  | {
      archived: Timesheet[];
      not_archived: Timesheet[];
      failed_to_archive: Timesheet[];
    } & MiterError;

export type CreateFileResponse = {
  file: File;
  url: string;
};

export type CreateFilesParams = {
  files: FileToUpload[];
};

export type CreateFilesWithoutBlobParams = {
  files: FileToCreateWithoutBlob[];
};

export type UpdateFileParams = Pick<File, "_id"> &
  Partial<
    Pick<
      File,
      | "label"
      | "onboarding_document"
      | "team_permissions"
      | "existing_tm_document"
      | "answers"
      | "fill_status"
    >
  >;

export type UpdateManyFilesParams = {
  files: UpdateFileParams[];
};

export type UpdateFilesResponse = File[] & MiterError;

export type CreateFilesResponse = CreateFileResponse[];

export type RetrieveFileResponse = (File | AggregatedFile) & { url: string };

export type DownloadFilesResponse = {
  originalname: string;
  label?: string;
  data: Buffer;
  type: string;
} & MiterError;

export type TimeOffPolicyEnrollee = TeamMember & {
  new_status?: string;
  error?: string;
  _id: string;
};

export type CreateTimeOffPolicyResponse = TimeOffPolicy & MiterError;

export type UpdateTimeOffPolicyResponse = CreateTimeOffPolicyResponse;

export type RetrieveTimeOffPoliciesResponse = TimeOffPolicy[] & MiterError;

export type CreateTimeOffRequestParams = {
  data: Partial<TimeOffRequest>;
};

export type BuildBalanceProjectionParams = {
  requestStartDate: string; // ISO string
  teamMemberId: string;
  timeOffPolicyId: string;
  timeOffRequestId?: string;
};

export type BalanceEstimate = { accrualProjection: number; usageProjection: number };

export type CreateTimeOffRequestResponse = TimeOffRequest & MiterError;

export type UpdateTimeoffRequestParams = Partial<TimeOffRequest>;

export type UpdateTimeOffRequestResponse = CreateTimeOffRequestResponse;

export type DeleteTimeOffRequestResponse = TimeOffRequest & MiterError;

export type RetrieveTimeOffRequestResponse = AggregatedTimeOffRequest & MiterError;

export type RetrieveExpenseReimbursementsResponse = {
  expense_reimbursements: AggregatedExpenseReimbursement[];
};

export type RetrieveTimeOffRequestsResponse = AggregatedTimeOffRequest[] & MiterError;

export type RetrieveTimeOffUpdatesResponse = TimeOffUpdate[] & MiterError;

export type CreateWorkersCompCodeParams = Partial<WorkersCompCode>;
export type CreateWorkersCompCodeResponse = WorkersCompCode & MiterError;

export type UpdateWorkersCompCodeParams = CreateWorkersCompCodeParams;
export type UpdateWorkersCompCodeResponse = CreateWorkersCompCodeResponse;

export type RetrieveWorkersCompCodesResponse = WorkersCompCode[] & MiterError;

export type UpdateRolesParams = {
  ids: string[];
  update: Partial<Role>;
};

export type RetrievePostTaxDeductionResponse = PostTaxDeduction & MiterError;

export type CreatePostTaxDeductionParams = Partial<PostTaxDeduction> & Partial<CheckPostTaxDeduction>;
export type CreatePostTaxDeductionResponse = PostTaxDeduction & MiterError;

export type ImportPostTaxDeductionsParams = {
  clean_inputs: CreatePostTaxDeductionParams[];
  raw_inputs: ImportInputsRaw;
};

export type ImportAllowancesParams = {
  clean_inputs: CreateAllowanceParams[];
  raw_inputs: ImportInputsRaw;
};

export type ImportLedgerAccountsParams = {
  clean_inputs: CreateLedgerAccountParams[];
  raw_inputs: ImportInputsRaw;
};

export type UpdatePostTaxDeductionParams = CreatePostTaxDeductionParams;
export type UpdatePostTaxDeductionResponse = CreatePostTaxDeductionResponse;

export type DeletePostTaxDeductionResponse = CreatePostTaxDeductionResponse;

export type RetrieveTeamMemberDocumentsResponse = CheckTmTaxDocument[] & MiterError;

export type DownloadTeamMemberDocumentResponse = Response & MiterError;

export type ImportTeamMembersResponse = {
  success: TeamMember[];
  failure: CreateTeamMemberParams[];
};

export type SendTeamMemberOnboardingSmsResponse = {
  success: string;
};

export type RetrieveTeamMemberResponse = AggregatedTeamMember & MiterError;
export type RetrieveTeamMembersResponse = AggregatedTeamMember[] & MiterError;

export type ChatProfileData = TeamMember & {
  jobs: Job[];
  reports_to?: TeamMember | null;
  department?: Department | null;
};

export type RetrieveTeamMemberCheckOnboardLinkResponse = TeamMember & {
  url: string;
} & MiterError;

export type RetrieveTeamMemberPostTaxDeductionsResponse = {
  post_tax_deductions: PostTaxDeduction[];
} & MiterError;

export type RetrieveTeamMemberPaystubResponse = Response & MiterError;

export type UpdateTeamMemberParams = Assign<
  Partial<TeamMember> &
    UpdateCheckTMParams &
    TeamMemberPaymentParams & {
      workplace_address?: CheckAddress | null;
      workplace_type?: "company" | "custom";
    },
  {
    phone?: string | undefined | null;
    email?: string | undefined | null;
    enroll_in_payroll?: boolean;
    permission_group_ids?: string[];
    intacct_location_id?: string;
  }
>;

export type UpdateTeamMemberResponse = TeamMember & MiterError;

export type DeleteTeamMemberResponse = {} & MiterError;
export type DeleteTeamMembersParams = { ids: string[] };
export type DeleteTeamMembersResponse = string[] & MiterError;

export type RetrieveCompanyTaxDocumentsResponse = CheckCompanyTaxDocument[] & MiterError;
export type DownloadCompanyDocumentResponse = Response & MiterError;

export type GetCompanyTimeOffPoliciesResponse = TimeOffPolicy[] & MiterError;

export type GetCompanyBillingInfoResponse = {
  payment_method?: Stripe.PaymentMethod | null;
  invoices: Stripe.Invoice[];
  source: Stripe.Source;
  miterBillingItems: MiterBillingItem[];
} & MiterError;

export type UpdateCompanyPaymentMethodParams = {
  source: Stripe.Source;
};

export type CreateWorkplaceParams = Partial<Workplace> & {
  company: string;
  address: CheckAddress;
};

export type UpdateWorkplaceParams = Partial<Workplace> & Partial<CheckWorkplace>;

export type ImportEmployeeBenefitsResponse = {
  successful_imports: {
    phone: string;
    benefit: string;
    description: string;
  }[];
  failed_imports: {
    phone: string;
    benefit: string;
    description: string;
  }[];
} & MiterError;

export type JobWithWorkplace = Job & {
  workplaces: Workplace[];
};

export type SearchResponse = {
  jobs: JobWithWorkplace[];
  timesheets: CustomAggregatePaginateResult<AggregatedTimesheet>;
  teamMembers: TeamMember[];
  chats: Conversation[];
};

export type GetCompanyDashboardDataResponse = {
  unapproved_timesheets: number;
  draft_payrolls: number;
  unapproved_time_off_requests: number;
  blocking_or_needs_attention_tms: number;
} & MiterError;

export type CreateApplicationFormResponse = {
  url: string;
  status: string;
} & MiterError;

export type ImportJobsResponse = {
  success: string[];
  failures: (CreateJobParams & { error: string; fields: { name: string; error: string }[] })[];
} & MiterError;

export type ImportEquipmentResponse = {
  success: CreateEquipmentParams[];
  failures: (CreateEquipmentParams & { error: string; fields: { name: string; error: string }[] })[];
} & MiterError;

export type ImportEquipmentTimesheetResponse = {
  success: CreateEquipmentTimesheetParams[];
  failures: (CreateEquipmentTimesheetParams & { error: string; fields: { name: string; error: string }[] })[];
} & MiterError;

export type ImportBillsResponse = {
  success: CreateBillParams[];
  failures: (CreateBillParams & { error: string; fields: { name: string; error: string }[] })[];
} & MiterError;

export type SendAuthCodeResponse = {
  success: boolean;
  method_id: string;
};

export type UpdateUserParams = {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
};

export type CreateChangeRequestParams = Omit<
  ChangeRequest,
  "_id" | "approval_stage" | "approval_history" | "archived" | "created_at" | "updated_at"
>;

export type RetrievePayrollResponse = AggregatedPayroll & MiterError;

export type CheckDocument = CheckDocument_;
export type CheckBenefit = CheckBenefit_;
export type CheckItemBenefit = CheckItemBenefit_;
export type CheckBenefitOverride = CheckBenefitOverride_;
export type CheckPostTaxDeductionOverride = CheckPostTaxDeductionOverride_;

export type BulkOperationResponse = {
  successes: string[];
  failures: { id: string; message: string; team_member_id?: string; stack: $TSFixMe }[];
  warnings: { id: string; message: string }[];
};

export type SessionUpdateParams =
  | { new_account_id: string; new_company_id?: undefined }
  | { new_company_id: string; new_account_id?: undefined };

/**
 * Represents an actual group or role with a specific value.
 * For example, "department" with the value "Finance".
 */
export type ApproverGroup = {
  type: ApproverGroupType;
  value?: string;
};

export type TeamMemberGroup = {
  type: TeamMemberGroupType;
  value?: string | null;
};

export type FormParentType = Form["parent_type"];
export type RawBankAccount = FrontendModel<RawBankAccount_>;
export type PlaidBankAccount = FrontendModel<PlaidBankAccount_>;
export type CheckOriginatedBankAccount = FrontendModel<CheckOriginatedBankAccount_>;
export type BankAccount = FrontendModel<BankAccount_>;

export type CreateWorkersCompGroupParams = FrontendModel<CreateWorkersCompGroupParams_>;
export type UpdateWorkersCompGroupParams = FrontendModel<UpdateWorkersCompGroupParams_>;

export type UpdateHolidayScheduleParams = FrontendModel<UpdateHolidayScheduleParams_>;

export type CreateFormSubmissionParams = FrontendModel<CreateFormSubmissionParams_>;
export type UpdateFormSubmissionParams = FrontendModel<UpdateFormSubmissionParams_>;

/**************************************************************************
 * This is a utility module that wraps the Miter API into a basic module
 * that we can reuse to reduce code duplication and keep consistency when
 * calling the Miter API.
 ***************************************************************************/

export const MiterAPI = {
  timesheets: {
    create: async (payload: CreateTimesheetParams): Promise<Timesheet & MiterError> => {
      return await APIHandler.request("/timesheets", "POST", payload);
    },
    create_many: async (
      payload: CreateMultipleTimesheetsParams
    ): Promise<CreateMultipleTimesheetsResponse> => {
      return await APIHandler.request("/timesheets/multiple", "POST", payload);
    },
    import: async (payload: ImportTimesheetsParams): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/timesheets/import", "POST", payload);
    },
    approve: async (payload: ApproveTimesheetsParams): Promise<ApproveTimesheetsResponse & MiterError> => {
      return await APIHandler.request("/timesheets/approve", "POST", payload);
    },
    recode: async (inputs: RecodeTimesheetParams): Promise<BulkUpdateResult<string> & MiterError> => {
      return await APIHandler.request("/timesheets/recode", "POST", inputs);
    },
    split: async (inputs: SplitTimesheetParams): Promise<SplitTimesheetsResults & MiterError> => {
      return await APIHandler.request("/timesheets/split", "POST", inputs);
    },
    unapprove: async (
      payload: UnapproveTimesheetsParams
    ): Promise<UnapproveTimesheetsResponse & MiterError> => {
      return await APIHandler.request("/timesheets/unapprove", "POST", payload);
    },
    markAsPaid: async (params: MarkTimesheetsAsPaidParams): Promise<{ failures: string[] } & MiterError> => {
      return await APIHandler.request("/timesheets/mark-as-paid", "POST", params);
    },
    duplicate: async (
      params: DuplicateTimesheetsParams
    ): Promise<DuplicateTimesheetsResponse & MiterError> => {
      return await APIHandler.request("/timesheets/duplicate", "POST", params);
    },
    list: async (
      miterQueryObject: MiterQueryObject,
      skipPayRateUpdatesForApproved?: boolean
    ): Promise<CustomAggregatePaginateResult<AggregatedTimesheet> & MiterError> => {
      const path = "/timesheets/list";
      return await APIHandler.request(path, "POST", {
        ...miterQueryObject,
        skipPayRate: skipPayRateUpdatesForApproved,
      });
    },
    update_one: async (
      id: string,
      payload: UpdateTimesheetParams
    ): Promise<AggregatedTimesheet & MiterError> => {
      const queryPath = "/timesheets/" + id;
      return await APIHandler.request(queryPath, "PATCH", payload);
    },
    update_many: async (params: UpdateManyTimesheetsParams): Promise<BulkUpdateResult & MiterError> => {
      const queryPath = "/timesheets/update/multiple";
      return await APIHandler.request(queryPath, "PATCH", params);
    },
    archive: async (payload: ArchiveTimesheetsParams): Promise<ArchiveTimesheetsResponse> => {
      const queryPath = "/timesheets/update/archive";
      return await APIHandler.request(queryPath, "PATCH", payload);
    },
    forage: async (
      params: ForageRequest,
      skipPayRateUpdatesForApproved?: boolean
    ): Promise<ForageResponse<AggregatedTimesheet>> => {
      const pathBase = "/timesheets/forage";
      const q = serialize(params);

      const q_en = encodeURIComponent(q);
      let queryPath = pathBase + "?q=" + q_en;
      if (skipPayRateUpdatesForApproved) queryPath += "&skipPayRate=true";

      // Some things can break if the URL is too long, so we need to use POST instead
      let ret: Promise<ForageResponse & MiterError>;
      if (queryPath.length > 2000) {
        ret = APIHandler.request(pathBase, "POST", { q, skipPayRate: skipPayRateUpdatesForApproved });
      } else {
        ret = APIHandler.request(queryPath, "GET");
      }
      return handleForageResponse(ret);
    },
    breadcrumbs: async (id: string): Promise<TimesheetBreadcrumbs & MiterError> => {
      const queryPath = "/timesheets/" + id + "/breadcrumbs";
      return await APIHandler.request(queryPath, "GET");
    },
    build_draft_pay_rates: async (
      timesheets: PreppedBulkEntryTimesheet[]
    ): Promise<BuildDraftPayRatesResponse & MiterError> => {
      return await APIHandler.request("/timesheets/build-draft-pay-rates", "POST", { timesheets });
    },
  },
  live_timesheets: {
    list: async (body: MiterQueryObject): Promise<AggregatedLiveTimesheet[] & MiterError> => {
      return await APIHandler.request("/live-timesheets/list", "POST", body);
    },
    retrieve_many: async (body: MiterFilterArray): Promise<AggregatedLiveTimesheet[] & MiterError> => {
      return await APIHandler.request("/live-timesheets/retrieve-many", "POST", body);
    },
    clock_out: async (id: string, clockOutTimestamp: number): Promise<AggregatedTimesheet & MiterError> => {
      return await APIHandler.request("/live-timesheets/" + id + "/clock-out", "POST", { clockOutTimestamp });
    },
    create: async (body: CreateLiveTimesheetParams): Promise<LiveTimesheet & MiterError> => {
      return await APIHandler.request("/live-timesheets", "POST", body);
    },
    update: async (id: string, body: UpdateLiveTimesheetParams): Promise<LiveTimesheet & MiterError> => {
      return await APIHandler.request("/live-timesheets/" + id, "PATCH", body);
    },
    archive: async (body: { id: string }): Promise<LiveTimesheet & MiterError> => {
      return await APIHandler.request("/live-timesheets/" + body.id, "DELETE");
    },
  },
  team_member: {
    documents: async (
      id: string
    ): Promise<{ documents: AggregatedFile[]; tax_forms: CheckDocument[] } & MiterError> => {
      const queryPath = "/team/" + id + "/documents";
      return await APIHandler.request(queryPath, "POST");
    },
    create: async (payload: Partial<TeamMember>): Promise<TeamMember & MiterError> => {
      const queryPath = "/team";
      return await APIHandler.request(queryPath, "POST", payload);
    },
    import: async (payload: ImportTeamMembersParams): Promise<ImportResult & MiterError> => {
      const queryPath = "/team/import";
      return await APIHandler.request(queryPath, "POST", payload);
    },
    import_employment_history: async (
      payload: ImportEmploymentHistoryParams
    ): Promise<ImportResult & MiterError> => {
      const queryPath = "/team/import-employment-history";
      return await APIHandler.request(queryPath, "POST", payload);
    },
    update_active: async (id: string): Promise<BaseAuthenticatedUserData & MiterError> => {
      const queryPath = "/team/" + id + "/update-active";
      return await APIHandler.request(queryPath, "POST");
    },
    send_onboard_sms: async (id: string): Promise<SendTeamMemberOnboardingSmsResponse & MiterError> => {
      const queryPath = "/team/onboard-sms/" + id;
      return await APIHandler.request(queryPath, "POST");
    },
    send_onboard_email: async (id: string): Promise<SendTeamMemberOnboardingSmsResponse & MiterError> => {
      const queryPath = "/team/onboard-email/" + id;
      return await APIHandler.request(queryPath, "POST");
    },
    retrieve: async (id: string): Promise<RetrieveTeamMemberResponse> => {
      const queryPath = "/team/" + id;
      return await APIHandler.request(queryPath, "GET");
    },
    retrieve_many: async (filterArray: MiterFilterArray): Promise<RetrieveTeamMembersResponse> => {
      const queryPath = "/team?" + qs.stringify(filterArray);
      return await APIHandler.request(queryPath, "GET");
    },
    retrieve_raw_ssn: async (id: string): Promise<{ ssn: string } & MiterError> => {
      const queryPath = "/team/" + id + "/ssn";
      return await APIHandler.request(queryPath, "GET");
    },
    retrieve_raw_ssns: async (tmIds: string[]): Promise<Record<string, string> & MiterError> => {
      const queryPath = "/team/ssns";
      return await APIHandler.request(queryPath, "POST", { tmIds });
    },
    retrieve_chat_profile: async (id: string): Promise<ChatProfileData & MiterError> => {
      const queryPath = "/team/" + id + "/chat-profile";
      return await APIHandler.request(queryPath, "GET");
    },
    dismiss: async (id: string, params: DismissTmBodyParams): Promise<TeamMember & MiterError> => {
      return await APIHandler.request(`/team/${id}/dismiss`, "POST", params);
    },
    schedule_leave_of_absence: async (
      id: string,
      params: TmOnLeaveBodyParams
    ): Promise<TeamMember & MiterError> => {
      return await APIHandler.request(`/team/${id}/schedule-leave`, "POST", params);
    },
    update_leave_of_absence: async (
      id: string,
      params: TmOnLeaveBodyParams & { _id: string }
    ): Promise<TeamMember & MiterError> => {
      return await APIHandler.request(`/team/${id}/update-leave`, "POST", params);
    },
    delete_leave_of_absence: async (
      tmId: string, //tm id
      leaveId: string //on leave id
    ): Promise<TeamMember & MiterError> => {
      return await APIHandler.request(`/team/${tmId}/delete-leave`, "POST", { _id: leaveId });
    },
    rehire: async (id: string, params: RehireTmBodyParams): Promise<TeamMember & MiterError> => {
      return await APIHandler.request(`/team/${id}/rehire`, "POST", params);
    },
    retrieve_check_onboard_link: async (id: string): Promise<RetrieveTeamMemberCheckOnboardLinkResponse> => {
      const queryPath = "/team/onboard/" + id;
      return await APIHandler.request(queryPath, "GET");
    },
    retrieve_withholdings_setup_link: async (id: string): Promise<{ url: string } & MiterError> => {
      const queryPath = "/team/" + id + "/withholdings-setup-link";
      return await APIHandler.request(queryPath, "GET");
    },
    retrieve_tax_document: async (id: string, doc_id: string): Promise<Response & MiterError> => {
      const queryPath = "/team/" + id + "/tax-document?doc_id=" + doc_id;
      return await APIHandler.request(queryPath, "GET", undefined);
    },
    get_payments: async (tmId: string): Promise<{ payments: TablePayment[] } & MiterError> => {
      const path = "/team/" + tmId + "/payments?includeAll=true";
      return await APIHandler.request(path, "GET");
    },
    get_profile_picture_urls: async (companyId: string): Promise<Record<string, string> & MiterError> => {
      const path = "/team/profile-picture-urls";
      return await APIHandler.request(path, "POST", { companyId });
    },
    post_tax_deductions: {
      all: async (id: string): Promise<RetrieveTeamMemberPostTaxDeductionsResponse> => {
        const queryPath = "/team/" + id + "/post-tax-deductions";
        return await APIHandler.request(queryPath, "GET");
      },
    },
    paystubs: {
      retrieve: async (tm_id: string, payroll_ids: string[]): Promise<RetrieveTeamMemberPaystubResponse> => {
        const queryPath = "/team/" + tm_id + "/paystubs";
        return await APIHandler.request(queryPath, "POST", { payroll_ids });
      },
    },
    miter_paystubs: {
      retrieve: async (tm_id: string, payroll_ids: string[]): Promise<RetrieveTeamMemberPaystubResponse> => {
        const queryPath = "/team/" + tm_id + "/miter-paystubs";
        return await APIHandler.request(queryPath, "POST", { payroll_ids });
      },
    },
    update: async (
      id: string,
      payload: UpdateTeamMemberParams,
      options?: { validateEmploymentHistory?: boolean }
    ): Promise<UpdateTeamMemberResponse> => {
      const path = "/team/" + id;
      return await APIHandler.request(path, "PATCH", {
        ...payload,
        options: { validateEmploymentHistory: options?.validateEmploymentHistory },
      });
    },
    bulk_update: async (
      payload: BulkUpdateParams<UpdateTeamMembersParams>
    ): Promise<BulkUpdateResult & MiterError> => {
      const queryPath = "/team/bulk";
      return await APIHandler.request(queryPath, "PATCH", payload);
    },
    delete: async (id: string): Promise<DeleteTeamMemberResponse> => {
      const queryPath = "/team/" + id;
      return await APIHandler.request(queryPath, "DELETE");
    },

    delete_many: async (payload: DeleteTeamMembersParams): Promise<DeleteTeamMembersResponse> => {
      const queryPath = "/team";
      return await APIHandler.request(queryPath, "DELETE", payload);
    },
    get_pay_rate: async (id: string, params: GetPayRateParams): Promise<PayRateItem & MiterError> => {
      return await APIHandler.request("/team/" + id + "/pay-rate-item", "POST", params);
    },
    sync_with_check: async (check_id: string): Promise<TeamMember> => {
      const path = "/team/" + check_id + "/sync";
      return await APIHandler.request(path, "PATCH");
    },
    retrieve_linked_team_members: async (params: {
      phone?: string;
      email?: string;
      teamMemberID?: string;
    }): Promise<LinkedTeamMember[] & MiterError> => {
      const queryPath = "/team/linked-team-members?" + qs.stringify(params);
      return await APIHandler.request(queryPath, "GET");
    },
    retrieve_permission_groups: (
      team_member_id: string,
      overrideAttributes?: TeamMemberOverrideAttributes
    ): Promise<PermissionGroup[] & MiterError> => {
      const queryPath = "/team/" + team_member_id + "/permission-groups?" + qs.stringify(overrideAttributes);
      return APIHandler.request(queryPath, "GET");
    },
    retrieve_company_defined_attributes: (
      companyId: string,
      tmIds?: string[] | null
    ): Promise<TmCompanyDefinedAttributes[] & MiterError> => {
      return APIHandler.request("/team/get-company-defined-attributes", "POST", { companyId, tmIds });
    },
    update_company_defined_attributes: (
      entries: UpdateCompanyDefinedAttributesEntry[]
    ): Promise<BulkUpdateResult<TmCompanyDefinedAttributes> & MiterError> => {
      return APIHandler.request("/team/company-defined-attributes", "PATCH", entries);
    },
    check_forms: {
      list: async (team_member_id: string): Promise<CheckEmployeeOnboardingForm[] & MiterError> => {
        const queryPath = `/team/${team_member_id}/forms`;
        return await APIHandler.request(queryPath, "GET");
      },
      retrieve: async (
        team_member_id: string,
        check_form_id: string
      ): Promise<CheckEmployeeOnboardingFormWithParams & MiterError> => {
        const queryPath = `/team/${team_member_id}/form/${check_form_id}`;
        return await APIHandler.request(queryPath, "GET");
      },
    },
    retrieve_last_payday: async (
      team_member_id: string
    ): Promise<{ payday: string | undefined } & MiterError> => {
      const path = `/team/${team_member_id}/last-payday?`;
      return await APIHandler.request(path, "GET");
    },
  },
  companies: {
    schedule: {
      events: async (company_id: string, filter: MiterFilterArray): Promise<ScheduleEvent[] & MiterError> => {
        const queryPath = `/companies/${company_id}/schedule`;
        return await APIHandler.request(queryPath, "POST", { filter });
      },
    },
    documents: {
      retrieve_all_tax: async (id: string): Promise<RetrieveCompanyTaxDocumentsResponse> => {
        const queryPath = "/companies/" + id + "/documents";
        return await APIHandler.request(queryPath, "GET");
      },
      retrieve_all_tm_tax: async (id: string): Promise<CheckTmTaxDocument[] & MiterError> => {
        const queryPath = "/companies/" + id + "/all_tm_tax_documents";
        return await APIHandler.request(queryPath, "GET");
      },
      download: async (company_id: string, doc_id: string): Promise<DownloadCompanyDocumentResponse> => {
        const queryPath = "/companies/" + company_id + "/document?" + qs.stringify({ doc_id });
        return await APIHandler.request(queryPath, "GET");
      },
    },
    create: async (payload: CreateCompanyBody): Promise<CreateCompanyResponse> => {
      const queryPath = "/companies";
      return await APIHandler.request(queryPath, "POST", payload);
    },
    retrieve: async (id: string): Promise<AggregatedCompany & MiterError> => {
      const queryPath = "/companies/" + id;
      return await APIHandler.request(queryPath, "GET");
    },
    update: async (id: string, payload: $TSFixMe): Promise<Company & MiterError> => {
      const queryPath = "/companies/" + id;
      return await APIHandler.request(queryPath, "PATCH", payload);
    },
    get_check_onboard_link: async (company_id: string): Promise<{ link: string } & MiterError> => {
      const queryPath = "/companies/" + company_id + "/check_onboard_link";
      return await APIHandler.request(queryPath, "GET");
    },
    get_conversations: async (company_id: string): Promise<Conversation[] & MiterError> => {
      const queryPath = "/companies/" + company_id + "/conversations";
      return await APIHandler.request(queryPath, "GET");
    },
    get_dashboard_data: async (company_id: string): Promise<GetCompanyDashboardDataResponse> => {
      const queryPath = "/companies/" + company_id + "/dashboard";
      return await APIHandler.request(queryPath, "GET");
    },
    get_billing_info: async (company_id: string): Promise<GetCompanyBillingInfoResponse> => {
      const queryPath = "/companies/" + company_id + "/billing";
      return await APIHandler.request(queryPath, "GET");
    },
    get_customer_portal_link: async (company_id: string): Promise<{ url: string } & MiterError> => {
      const queryPath = "/companies/" + company_id + "/customer-billing-link";
      return await APIHandler.request(queryPath, "GET");
    },
    update_payment_method: async (
      company_id: string,
      body: UpdateCompanyPaymentMethodParams
    ): Promise<Company & MiterError> => {
      const queryPath = "/companies/" + company_id + "/payment-method";
      return await APIHandler.request(queryPath, "PATCH", body);
    },
  },
  benefits: {
    employee: {
      retrieve_many: async (
        filterArray: MiterFilterArray
      ): Promise<AggregatedEmployeeBenefit[] & MiterError> => {
        const queryPath = "/benefits/employee?" + qs.stringify(filterArray);
        return await APIHandler.request(queryPath, "GET");
      },
      search: async (filterArray: MiterFilterArray): Promise<AggregatedEmployeeBenefit[] & MiterError> => {
        const queryPath = "/benefits/employee/search";
        return await APIHandler.request(queryPath, "POST", { filter: filterArray });
      },
      create: async (payload: $TSFixMe): Promise<EmployeeBenefit & MiterError> => {
        const queryPath = "/benefits/employee";
        return await APIHandler.request(queryPath, "POST", payload);
      },
      update: async (id: string, payload: $TSFixMe): Promise<EmployeeBenefit & MiterError> => {
        const queryPath = "/benefits/" + id + "/employee";
        return await APIHandler.request(queryPath, "PATCH", payload);
      },
      bulk_update: async (
        params: BulkUpdateParams<UpdateEmployeeBenefitParams>
      ): Promise<BulkUpdateResult & MiterError> => {
        const queryPath = "/benefits/employee/bulk";
        return await APIHandler.request(queryPath, "PATCH", params);
      },
      delete: async (payload: { ids: string[] }): Promise<{ errors: string[] } & MiterError> => {
        return await APIHandler.request("/benefits/employee", "DELETE", payload);
      },
      import: async (payload: ImportEmployeeBenefitsParams): Promise<ImportResult & MiterError> => {
        return await APIHandler.request("/benefits/employee/import", "POST", payload);
      },
    },
    company: {
      retrieve_for_company: async (id: string): Promise<CompanyBenefit[] & MiterError> => {
        const queryPath = "/companies/" + id + "/benefits";
        return await APIHandler.request(queryPath, "GET");
      },
      update_enrollment: async (
        benefitId: string,
        enrolledTms: string[]
      ): Promise<{ failures: DescendentBenefitFailure[] } & MiterError> => {
        const queryPath = "/benefits/" + benefitId + "/company/enrollment";
        return await APIHandler.request(queryPath, "PATCH", { benefitId, enrolledTms });
      },
      create: async (payload: Partial<CompanyBenefit>): Promise<CompanyBenefit & MiterError> => {
        const queryPath = "/benefits/company";
        return await APIHandler.request(queryPath, "POST", payload);
      },
      update: async (
        id: string,
        payload: Partial<CompanyBenefit>
      ): Promise<
        { updatedCompanyBenefit: CompanyBenefit; descendentFailures: DescendentBenefitFailure[] } & MiterError
      > => {
        const queryPath = "/benefits/" + id + "/company";
        return await APIHandler.request(queryPath, "PATCH", payload);
      },
      delete: async (id: string): Promise<{ success: true } & MiterError> => {
        const queryPath = "/benefits/" + id + "/company";
        return await APIHandler.request(queryPath, "DELETE");
      },
    },
  },
  benefits_admin: {
    onboard: async (body: { company_id: string }): Promise<Company & MiterError> => {
      const queryPath = "/benefits-admin/company/onboard";
      return await APIHandler.request(queryPath, "POST", body);
    },
  },
  benefits_eligibility_groups: {
    list: async (company_id: string): Promise<BenefitsEligibilityGroup[] & MiterError> => {
      return await APIHandler.request("/benefits-eligibility-groups/" + company_id + "/list", "GET");
    },
  },
  apprenticeship_programs: {
    retrieve: async (): Promise<ApprenticeshipProgram[]> => {
      return await APIHandler.request("/apprenticeship-programs", "GET");
    },
  },
  union_rates: {
    retrieve: async (filter: MiterFilterArray): Promise<UnionRate[] & MiterError> => {
      const path = "/union-rates?" + qs.stringify(filter);
      return await APIHandler.request(path, "GET");
    },
    create: async (body: Partial<UnionRate>): Promise<UnionRate & MiterError> => {
      return await APIHandler.request("/union-rates", "POST", body);
    },
    update: async (id: string, update: Partial<UnionRate>): Promise<UnionRate & MiterError> => {
      return await APIHandler.request("/union-rates/" + id, "PATCH", update);
    },
    archive: async (ids: string[], company_id: string): Promise<ArchiveUnionRateResponse & MiterError> => {
      return await APIHandler.request("/union-rates", "DELETE", { ids, company_id });
    },
    import_classifications: async (
      payload: ImportParams<UnionRateImportRecord, UnionRateImportRecord> & {
        pay_rate_group_id?: string;
        company: string;
      }
    ): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/union-rates/import", "PATCH", payload);
    },
    import_fringes: async (
      payload: ImportParams<UnionRateFringe & { payRateGroupId: string }, FringeRecord> & {
        company: string;
      }
    ): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/union-rates/import-fringes", "PATCH", payload);
    },
    import_fringe_amounts: async (
      payload: ImportParams<FringeAmount, FringeAmount> & {
        company: string;
      }
    ): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/union-rates/import-fringe-amounts", "PATCH", payload);
    },
  },
  union_reciprocity_rules: {
    retrieve: async (filter: MiterFilterArray): Promise<UnionReciprocityRule[] & MiterError> => {
      const path = "/union-reciprocity-rules?" + qs.stringify(filter);
      return await APIHandler.request(path, "GET");
    },
    create: async (body: Partial<UnionReciprocityRule>): Promise<UnionReciprocityRule & MiterError> => {
      return await APIHandler.request("/union-reciprocity-rules", "POST", body);
    },
    update: async (
      id: string,
      update: Partial<UnionReciprocityRule>
    ): Promise<UnionReciprocityRule & MiterError> => {
      return await APIHandler.request("/union-reciprocity-rules/" + id, "PATCH", update);
    },
    archive: async (id: string): Promise<UnionReciprocityRule & MiterError> => {
      return await APIHandler.request("/union-reciprocity-rules/" + id, "DELETE");
    },
  },
  overtime_rules: {
    create: async (body: Partial<OvertimeRule>): Promise<OvertimeRule & MiterError> => {
      return await APIHandler.request("/overtime-rules", "POST", body);
    },
    update: async (
      ids: string[],
      company_id: string,
      update: Partial<OvertimeRule>
    ): Promise<OvertimeRule[] & MiterError> => {
      return await APIHandler.request("/overtime-rules", "PATCH", { ids, company_id, update });
    },
    retrieve: async (queryObject: MiterQueryObject): Promise<OvertimeRule[] & MiterError> => {
      return await APIHandler.request("/overtime-rules/retrieve", "POST", queryObject);
    },
    archive: async (
      ids: string[],
      company_id: string
    ): Promise<SuccessesAndFailuresResponse & MiterError> => {
      return await APIHandler.request("/overtime-rules/archive", "PATCH", { ids, company_id });
    },
  },
  ledger_mappings: {
    create: async (body: Partial<LedgerMapping>): Promise<LedgerMapping & MiterError> => {
      return await APIHandler.request("/ledger-mappings", "POST", body);
    },
    update: async (id: string, update: Partial<LedgerMapping>): Promise<LedgerMapping & MiterError> => {
      return await APIHandler.request("/ledger-mappings/" + id, "PATCH", update);
    },
    retrieve: async (queryObject: MiterQueryObject): Promise<LedgerMapping[] & MiterError> => {
      return await APIHandler.request("/ledger-mappings/retrieve", "POST", queryObject);
    },
    archive: async (ids: string[], company_id: string): Promise<BulkUpdateResult & MiterError> => {
      return await APIHandler.request("/ledger-mappings", "DELETE", { ids, company_id });
    },
  },
  pay_rate_groups: {
    create: async (body: Partial<PayRateGroup>): Promise<PayRateGroup & MiterError> => {
      return await APIHandler.request("/pay-rate-groups", "POST", body);
    },
    update: async (
      ids: string[],
      update: Partial<PayRateGroup>
    ): Promise<SuccessesAndFailuresResponse & MiterError> => {
      return await APIHandler.request("/pay-rate-groups", "PATCH", { ids, update });
    },
    duplicate: async (params: { id: string; company_id: string }): Promise<PayRateGroup & MiterError> => {
      return await APIHandler.request("/pay-rate-groups/duplicate", "POST", params);
    },
    retrieve: async (queryObject: MiterQueryObject): Promise<AggregatedPayRateGroup[] & MiterError> => {
      return await APIHandler.request("/pay-rate-groups/retrieve", "POST", queryObject);
    },
    archive: async (
      ids: string[],
      company_id: string
    ): Promise<SuccessesAndFailuresResponse & MiterError> => {
      return await APIHandler.request("/pay-rate-groups/archive", "PATCH", { ids, company_id });
    },
  },
  payrolls: {
    retrieve_many: async (filterArray: MiterFilterArray): Promise<Payroll[] & MiterError> => {
      const path = "/payrolls?" + qs.stringify(filterArray);
      return await APIHandler.request(path, "GET");
    },
    get_table: async (query: { company: string }): Promise<TablePayroll[] & MiterError> => {
      const path = "/payrolls/table?" + qs.stringify(query);
      return await APIHandler.request(path, "GET");
    },
    add_paper_check_num: async (
      payrollId: string,
      bodyParams: AddPaperCheckNumBodyParams
    ): Promise<{ success: boolean } & MiterError> => {
      return await APIHandler.request("/payrolls/" + payrollId + "/add-check-num", "PATCH", bodyParams);
    },
    bulk_add_paper_check_num: async (
      payrollId: string,
      bodyParams: BulkUpdateParams<UpdatePaperCheckNumberParams>
    ): Promise<BulkUpdateResult<{ _id: string }> & MiterError> => {
      return await APIHandler.request("/payrolls/" + payrollId + "/bulk-add-check-num", "PATCH", bodyParams);
    },
    retrieve: async (id: string, query?: GetPayrollQuery): Promise<RetrievePayrollResponse> => {
      const path = "/payrolls/" + id + (query ? "?" + qs.stringify(query) : "");
      return await APIHandler.request(path, "GET");
    },
    recalculate: async (id: string, body: $TSFixMe): Promise<AggregatedPayroll & MiterError> => {
      const path = "/payrolls/" + id + "/recalculate";
      return await APIHandler.request(path, "PATCH", body);
    },
    update_payroll: async (id: string, payload: $TSFixMe): Promise<Payroll & MiterError> => {
      const path = "/payrolls/" + id;
      return await APIHandler.request(path, "PATCH", payload);
    },
    create: async (payload: $TSFixMe): Promise<Payroll & MiterError> => {
      const path = "/payrolls";
      return await APIHandler.request(path, "POST", payload);
    },
    import_earnings: async (
      id: string,
      payload: ImportParams<EarningRecord, EarningRecord>
    ): Promise<ImportResult & MiterError> => {
      const path = "/payrolls/" + id + "/import-earnings";
      return await APIHandler.request(path, "PATCH", payload);
    },
    import_overrides: async (
      id: string,
      payload: ImportParams<PayrollOverride, PayrollOverride> & { companyId: string }
    ): Promise<ImportResult & MiterError> => {
      const path = "/payrolls/" + id + "/import-overrides";
      return await APIHandler.request(path, "PATCH", payload);
    },
    archive: async (id: string): Promise<Payroll & MiterError> => {
      const path = "/payrolls/" + id + "/archive";
      return await APIHandler.request(path, "PATCH");
    },
    approve: async (id: string): Promise<Payroll & MiterError> => {
      const path = "/payrolls/" + id + "/approve";
      return await APIHandler.request(path, "POST");
    },
    reopen: async (id: string): Promise<Payroll & MiterError> => {
      const path = "/payrolls/" + id + "/reopen";
      return await APIHandler.request(path, "POST");
    },
    get_earnings: async (id: string): Promise<AggregatedMiterEarning[] & MiterError> => {
      return await APIHandler.request("/payrolls/" + id + "/earnings", "GET");
    },
    pay_period_mock_earnings: async (
      p: GetMockedEarningsParams
    ): Promise<
      {
        earnings: AggregatedMiterEarning[];
        warnings: PaymentWarning[];
        tsSections: TimesheetSection[];
      } & MiterError
    > => {
      return await APIHandler.request("/payrolls/pay-period-mocked-earnings", "POST", p);
    },
    get_paystubs: async (id: string): Promise<Response & MiterError> => {
      return await APIHandler.request("/payrolls/" + id + "/paystubs", "GET");
    },
    get_miter_paystubs: async (id: string): Promise<Response & MiterError> => {
      return await APIHandler.request("/payrolls/" + id + "/miter-paystubs", "GET");
    },
    download_miter_checks: async (
      payrollId: string,
      paymentId?: string,
      sortByLastJobWorked?: boolean
    ): Promise<Response & MiterError> => {
      const path =
        "/payrolls/" + payrollId + "/miter-checks?" + qs.stringify({ paymentId, sortByLastJobWorked });
      return await APIHandler.request(path, "GET");
    },
    download_check: async (id: string | undefined): Promise<Response & MiterError> => {
      const path = "/payrolls/" + id + "/check";
      return await APIHandler.request(path, "GET");
    },
    download_checks: async (id: string): Promise<Response & MiterError> => {
      const path = "/payrolls/" + id + "/checks";
      return await APIHandler.request(path, "GET");
    },
    retrieve_expense_reimbursements: async (
      payroll_id: string
    ): Promise<RetrieveExpenseReimbursementsResponse & MiterError> => {
      const path = "/payrolls/" + payroll_id + "/expense-reimbursements";
      return await APIHandler.request(path, "GET");
    },
    retrieve_paydays: async (company_id: string): Promise<CheckCompanyPayday[] & MiterError> => {
      const path = "/payrolls/paydays/" + company_id;
      return await APIHandler.request(path, "GET");
    },
    void_payments: async (
      payrollId: string,
      paymentIds: string[],
      companyId: string,
      options?: { achReversal?: boolean; achReversalReason?: string }
    ): Promise<{ success: true } & MiterError> => {
      const path = "/payrolls/" + payrollId + "/void-payments";
      return await APIHandler.request(path, "POST", { payment_ids: paymentIds, companyId, options });
    },
  },
  messages: {
    send_email: async (body: SendMessageBody): Promise<SendMessageResponse> => {
      return await APIHandler.request("/messages/email", "POST", body);
    },
    send_mail: async (body: SendMessageBody): Promise<SendMessageResponse> => {
      return await APIHandler.request("/messages/mail", "POST", body);
    },
    send_fax: async (body: SendMessageBody): Promise<SendMessageResponse> => {
      return await APIHandler.request("/messages/fax", "POST", body);
    },
    list: async (filterArray: MiterFilterArray): Promise<Message[]> => {
      const path = "/messages?" + qs.stringify(filterArray);
      return await APIHandler.request(path, "GET");
    },
    get_proof: async (id: string): Promise<$TSFixMe> => {
      const path = "/messages/" + id + "/proof";
      return await APIHandler.request(path, "GET");
    },
  },
  post_tax_deductions: {
    retrieve: async (id: string): Promise<RetrievePostTaxDeductionResponse> => {
      const queryPath = "/post-tax-deductions/" + id;
      return await APIHandler.request(queryPath, "GET");
    },
    retrieve_many: async (queryObject: MiterQueryObject): Promise<PostTaxDeduction[] & MiterError> => {
      return await APIHandler.request("/post-tax-deductions/retrieve", "POST", queryObject);
    },
    create: async (payload: CreatePostTaxDeductionParams): Promise<CreatePostTaxDeductionResponse> => {
      const queryPath = "/post-tax-deductions";
      return await APIHandler.request(queryPath, "POST", payload);
    },
    import: async (payload: ImportPostTaxDeductionsParams): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/post-tax-deductions/import", "POST", payload);
    },
    update: async (
      id: string,
      payload: UpdatePostTaxDeductionParams
    ): Promise<UpdatePostTaxDeductionResponse> => {
      const queryPath = "/post-tax-deductions/" + id;
      return await APIHandler.request(queryPath, "PATCH", payload);
    },
    bulk_update: async (
      params: BulkUpdateParams<UpdatePostTaxDeductionParams>
    ): Promise<BulkUpdateResult<PostTaxDeduction> & MiterError> => {
      const queryPath = "/post-tax-deductions/bulk";
      return await APIHandler.request(queryPath, "PATCH", params);
    },
    delete: async (id: string): Promise<DeletePostTaxDeductionResponse> => {
      const queryPath = "/post-tax-deductions/" + id;
      return await APIHandler.request(queryPath, "DELETE");
    },
    bulk_delete: async (ids: string[]): Promise<BulkUpdateResult<PostTaxDeduction> & MiterError> => {
      const queryPath = "/post-tax-deductions/bulk";
      return await APIHandler.request(queryPath, "DELETE", ids);
    },
  },
  reports: {
    create: async (body: {
      type: string;
      format: string;
      params: $TSFixMe;
    }): Promise<$TSFixMe & MiterError> => {
      return await APIHandler.request("/reports", "POST", body);
    },
  },
  workplaces: {
    update: async (id: string, payload: UpdateWorkplaceParams): Promise<Workplace & MiterError> => {
      const queryPath = "/workplaces/" + id;
      return await APIHandler.request(queryPath, "PATCH", payload);
    },
    create: async (payload: CreateWorkplaceParams): Promise<Workplace & MiterError> => {
      const queryPath = "/workplaces";
      return await APIHandler.request(queryPath, "POST", payload);
    },
    forage: async (params: ForageRequest): Promise<ForageResponse<Workplace>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/workplaces/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
  },
  jobs: {
    create: async (body: Partial<Job>): Promise<Job & MiterError> => {
      return await APIHandler.request("/jobs", "POST", body);
    },
    duplicate: async (id: string): Promise<Job & MiterError> => {
      return await APIHandler.request("/jobs/duplicate", "POST", { id });
    },
    update: async (id: string, body: Partial<Job>): Promise<Job & MiterError> => {
      return await APIHandler.request("/jobs/" + id, "PATCH", body);
    },
    retrieve_many: async (filterArray: MiterFilterArray): Promise<AggregatedJob[] & MiterError> => {
      const queryPath = "/jobs?" + qs.stringify(filterArray);
      return await APIHandler.request(queryPath, "GET");
    },
    retrieve_images: async (jobID: string): Promise<{ images: JobPhotoDetails[] }> => {
      return await APIHandler.request("/jobs/" + jobID + "/images", "GET");
    },
    import: async (body: CreateJobParams[]): Promise<ImportJobsResponse> => {
      return await APIHandler.request("/jobs/import", "POST", body);
    },
    bulk_update: async (
      params: BulkUpdateParams<UpdateJobParams>
    ): Promise<BulkUpdateResult<{ _id: string; item: Job }> & MiterError> => {
      const queryPath = "/jobs/bulk";
      return await APIHandler.request(queryPath, "PATCH", params);
    },
  },
  chat: {
    create: async (company_id: string, team_member_id: string): Promise<Conversation & MiterError> => {
      const queryPath = "/chat/" + company_id + "/create/" + team_member_id;
      return await APIHandler.request(queryPath, "POST");
    },
    setup: async (id: string): Promise<{ success: string } & MiterError> => {
      const queryPath = "/chat/" + id + "/setup";
      return await APIHandler.request(queryPath, "POST");
    },
    get_token: async (id: string): Promise<{ token: string } & MiterError> => {
      const queryPath = "/chat/" + id + "/token";
      return await APIHandler.request(queryPath, "GET");
    },
    send_broadcast_notification: async (company: string): Promise<{ success: boolean } & MiterError> => {
      const queryPath = "/chat/send-broadcast-notification";
      return await APIHandler.request(queryPath, "POST", { company });
    },
    recruiting: {
      create: async (
        company_id: string,
        candidate_id: string
      ): Promise<RecruitingConversation & MiterError> => {
        const queryPath = "/chat/recruiting/" + company_id + "/create/" + candidate_id;
        return await APIHandler.request(queryPath, "POST");
      },
      forage: async (params: ForageRequest): Promise<ForageResponse<RecruitingConversation>> => {
        const q = encodeURIComponent(serialize(params));
        const queryPath = "/chat/recruiting/forage?q=" + q;
        return await handleForageResponse(APIHandler.request(queryPath, "GET"));
      },
      update_timestamp: async (conversation_sid: string): Promise<RecruitingConversation & MiterError> => {
        const queryPath = "/chat/recruiting/timestamp/" + conversation_sid;
        return await APIHandler.request(queryPath, "PATCH");
      },

      get_token: async (id: string): Promise<{ token: string } & MiterError> => {
        const queryPath = "/chat/recruiting/" + id + "/token";
        return await APIHandler.request(queryPath, "GET");
      },
    },
  },
  activities: {
    retrieve_many: async (filters: MiterFilterField[]): Promise<Activity[] & MiterError> => {
      const path = "/activities?" + qs.stringify({ filters });
      return await APIHandler.request(path, "GET");
    },
    create: async (body: Partial<Activity>): Promise<Activity & MiterError> => {
      return await APIHandler.request("/activities", "POST", body);
    },
    update: async (id: string, body: Partial<Activity>): Promise<Activity & MiterError> => {
      return await APIHandler.request("/activities/" + id, "PATCH", body);
    },
    archive: async (id: string, job_id?: string | null): Promise<Activity & MiterError> => {
      const path = "/activities/" + id + "/archive";
      return await APIHandler.request(path, "POST", { job_id });
    },
    import: async (payload: ImportActivitiesParams): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/activities/import", "POST", payload);
    },
    bulk_update: async (
      params: BulkUpdateParams<UpdateActivityParams>
    ): Promise<BulkUpdateResult<{ item: Activity; _id: string }> & MiterError> => {
      const queryPath = "/activities/bulk";
      return await APIHandler.request(queryPath, "PATCH", params);
    },
  },
  files: {
    /** Creates the files in the Miter DB + uploads them to S3. Returns the Miter files */
    upload: async (body: CreateFilesParams): Promise<CreateFilesResponse | MiterError> => {
      const { files } = body;
      if (!files.length) return [] as CreateFilesResponse;

      const newFiles: S3UrlCreationParam[] = files.map((file) => ({
        file_name: file.originalname,
        file_mime_type: file.type,
      }));

      let filesWithNewS3UrlsResponse: { files_with_urls: S3UrlCreationResponse[] };
      try {
        // get the signed URL from Miter
        filesWithNewS3UrlsResponse = await APIHandler.request("/files/s3-signed-urls", "POST", {
          files: newFiles,
        });
      } catch (err: $TSFixMe) {
        // if this errors, log more information so we can debug
        console.error("Client debugging - error getting signed URLs for file upload", body);
        throw new Error(err.message);
      }

      await uploadToS3WithSignedUrl(filesWithNewS3UrlsResponse.files_with_urls, files);

      // create map of file name -> file
      const fileWithS3UrlByFileName = createObjectMap(
        filesWithNewS3UrlsResponse.files_with_urls,
        (f) => f.file_name
      );

      // remove the file blob from the body and also add aws_key and signed url
      const filesWithoutBlob = files.map((file) => {
        const { fileBlob, ...rest } = file;

        const matchingFileWithUrl = fileWithS3UrlByFileName[file.originalname || ""];

        return {
          ...rest,
          aws_key: matchingFileWithUrl?.aws_key,
          signed_url: matchingFileWithUrl?.signed_url,
        };
      });

      const res = await APIHandler.request("/files/create", "POST", { files: filesWithoutBlob });
      if (res.error) throw new Error(res.error);

      return res;
    },
    create_without_blob: async (
      body: CreateFilesWithoutBlobParams
    ): Promise<CreateFilesResponse & MiterError> => {
      return await APIHandler.request("/files/create", "POST", body);
    },
    update_many: async (body: UpdateManyFilesParams): Promise<UpdateFilesResponse & MiterError> => {
      return await APIHandler.request("/files/", "PATCH", body);
    },
    retrieve: async (
      id: string,
      options?: { aggregated?: boolean }
    ): Promise<RetrieveFileResponse & MiterError> => {
      const queryPath = "/files/" + id + "?" + qs.stringify(options);
      return await APIHandler.request(queryPath, "GET");
    },
    get_urls: async (body: {
      filter: MiterFilterArray;
      customExpirySeconds?: number;
    }): Promise<{ urls: FileWithUrl[] } & MiterError> => {
      return await APIHandler.request("/files/urls", "POST", body);
    },
    list: async (
      filter: MiterFilterArray,
      include_urls?: boolean
    ): Promise<(File[] | FilePickerFile[]) & MiterError> => {
      const queryPath = "/files?" + qs.stringify({ filter, include_urls: include_urls ?? undefined });
      return await APIHandler.request(queryPath, "GET");
    },
    // needed for jotai
    retrieve_many: async (filter: MiterFilterArray): Promise<File[] & MiterError> => {
      const queryPath = "/files?" + qs.stringify({ filter });
      return await APIHandler.request(queryPath, "GET");
    },
    delete: async (ids: string[]): Promise<DeleteFilesRes & MiterError> => {
      return await APIHandler.request("/files", "DELETE", { ids });
    },
    download: async (files: string[]): Promise<DownloadFilesResponse> => {
      return await APIHandler.request("/files/download", "POST", { files });
    },
    update_esignature_requests: async (
      id: string,
      params: { create: CreateESignatureRequestParams[]; delete: string[] }
    ): Promise<ESignatureItem[] & MiterError> => {
      return await APIHandler.request("/files/" + id + "/update-esignature-requests", "POST", params);
    },
    team_members_with_access: async (id: string): Promise<TeamMember[] & MiterError> => {
      return await APIHandler.request("/files/" + id + "/team-members-with-access", "GET");
    },
    /** Searches files with the specific filter - used in files table */
    search: async (filter: MiterFilterArray): Promise<AggregatedFile[] & MiterError> => {
      return await APIHandler.request("/files/search", "POST", { filter });
    },

    /** Marks the list of files as viewed by the team member */
    viewed: async (ids: string[]): Promise<FileEvent[] & MiterError> => {
      return await APIHandler.request("/files/viewed", "POST", { ids });
    },

    fillable_documents: {
      retrieve: async (
        id: string,
        body: { team_member_id?: string; role_id?: string }
      ): Promise<AggregatedFillableDocument & MiterError> => {
        const queryPath = "/files/fillable-documents/" + id;
        return await APIHandler.request(queryPath, "POST", body);
      },
      complete_fillable_document: async (id: string): Promise<void & MiterError> => {
        const queryPath = "/files/fillable-documents/" + id + "/complete";
        return await APIHandler.request(queryPath, "POST");
      },
      remind: async (id: string): Promise<void & MiterError> => {
        const queryPath = "/files/fillable-documents/" + id + "/remind";
        return await APIHandler.request(queryPath, "GET");
      },
    },
  },
  search: async (
    company_id: string,
    query: string,
    options?: { page: number; tableName: string }
  ): Promise<SearchResponse & MiterError> => {
    const queryPath = "/search?" + qs.stringify({ company: company_id, searchTerm: query, ...options });
    return await APIHandler.request(queryPath, "GET");
  },
  expense_reimbursements: {
    create: async (
      body: CreateOrUpdateExpenseReimbursementParam
    ): Promise<
      {
        expense_reimbursement: ExpenseReimbursement;
      } & MiterError
    > => {
      return await APIHandler.request("/expense-reimbursements", "POST", body);
    },
    update: async (
      body: AbstractExpenseReimbursementRequest[]
    ): Promise<AbstractExpenseReimbursementResponse & MiterError> => {
      return await APIHandler.request("/expense-reimbursements", "PATCH", body);
    },
    update_status: async (
      body: AbstractExpenseReimbursementRequest[]
    ): Promise<AbstractExpenseReimbursementResponse & MiterError> => {
      return await APIHandler.request("/expense-reimbursements/status", "PATCH", body);
    },
    archive: async (
      body: AbstractExpenseReimbursementRequest[]
    ): Promise<AbstractExpenseReimbursementResponse & MiterError> => {
      return await APIHandler.request("/expense-reimbursements/archive", "PATCH", body);
    },
    duplicate: async (
      body: AbstractExpenseReimbursementRequest[]
    ): Promise<AbstractExpenseReimbursementResponse & MiterError> => {
      return await APIHandler.request("/expense-reimbursements/duplicate", "POST", body);
    },
    forage: async (params: ForageRequest): Promise<ForageResponse<AggregatedExpenseReimbursement>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/expense-reimbursements/forage?q=" + q;

      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
    import: async (payload: ImportExpenseReimbursementParams): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/expense-reimbursements/import", "POST", payload);
    },
    retry_ach_transfer: async (id: string): Promise<ACHTransfer & MiterError> => {
      return await APIHandler.request("/expense-reimbursements/" + id + "/retry-ach-transfer", "POST");
    },
    categories: {
      list: async (body: MiterQueryObject): Promise<ExpenseReimbursementCategory[] & MiterError> => {
        return await APIHandler.request("/expense-reimbursement-categories/list", "POST", body);
      },
      create_or_update: async (
        body: ExpenseReimbursementCategoryCreationParams
      ): Promise<ExpenseReimbursementCategory & MiterError> => {
        return await APIHandler.request("/expense-reimbursement-categories", "PUT", body);
      },
      archive: async (id: string): Promise<ExpenseReimbursementCategory & MiterError> => {
        return await APIHandler.request("/expense-reimbursement-categories/" + id, "DELETE");
      },
    },
    per_diem_rates: {
      list: async (body: MiterQueryObject): Promise<PerDiemRate[] & MiterError> => {
        return await APIHandler.request("/per-diem-rates/list", "POST", body);
      },
      create: async (body: PerDiemRateCreationParams): Promise<PerDiemRate & MiterError> => {
        return await APIHandler.request(`/per-diem-rates/`, "POST", body);
      },
      update: async (
        id: string,
        body: Partial<PerDiemRateCreationParams>
      ): Promise<PerDiemRate & MiterError> => {
        return await APIHandler.request(`/per-diem-rates/${id}`, "PATCH", body);
      },
      archive: async (id: string): Promise<PerDiemRate & MiterError> => {
        return await APIHandler.request(`/per-diem-rates/${id}`, "DELETE");
      },
    },
  },
  banking: {
    plaid: {
      retrieve_token: async (params: {
        company: string;
        external_financial_account_type?: ExternalFinancialAccountType;
        products: PlaidProducts[];
        update_mode?: boolean;
        item_id?: string;
        link_customization_name?: string;
      }): Promise<RetrievePlaidTokenResponse & MiterError> => {
        const queryPath = "/plaid/link-token?" + qs.stringify(params);
        return await APIHandler.request(queryPath, "GET");
      },
      connect_accounts: async (
        body: SaveAccessTokenBody
      ): Promise<CreateNewPlaidAccountResult[] & MiterError> => {
        return await APIHandler.request("/plaid/accounts/connect", "POST", body);
      },
      reconnect_accounts: async (body: SaveAccessTokenBody): Promise<{ success: boolean } & MiterError> => {
        return await APIHandler.request("/plaid/accounts/reconnect", "POST", body);
      },
      connect_cards: async (
        body: SaveAccessTokenBody
      ): Promise<
        { financialInstitutionName: string | undefined; newlyConnectedCards: ThirdPartyCard[] } & MiterError
      > => {
        return await APIHandler.request("/plaid/cards/connect", "POST", body);
      },
    },
    ach_transfers: {
      get: async (id: string): Promise<ACHTransfer & MiterError> => {
        const queryPath = `/ach-transfers/${id}`;
        return await APIHandler.request(queryPath, "GET");
      },
    },
  },
  vendors: {
    create: async (body: CreateOrUpdateVendorParams): Promise<AggregatedVendor & MiterError> => {
      return await APIHandler.request("/vendors", "POST", body);
    },
    update: async (id: string, body: CreateOrUpdateVendorParams): Promise<AggregatedVendor & MiterError> => {
      return await APIHandler.request("/vendors/" + id, "PATCH", body);
    },
    retrieve: async (id: string): Promise<AggregatedVendor & MiterError> => {
      return await APIHandler.request("/vendors/" + id, "GET");
    },
    delete: async (id: string): Promise<Vendor & MiterError> => {
      return await APIHandler.request("/vendors/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<AggregatedVendor[] & MiterError> => {
      return await APIHandler.request("/vendors/search", "POST", { filter });
    },
    import: async (
      body: ImportParams<CreateOrUpdateVendorParams, ImportInputsRaw>
    ): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/vendors/import", "POST", body);
    },
  },
  bills: {
    create: async (body: CreateBillParams): Promise<{ bill: AggregatedBill } & MiterError> => {
      return await APIHandler.request("/bills", "POST", body);
    },
    update: async (id: string, body: UpdateBillParams): Promise<{ bill: AggregatedBill } & MiterError> => {
      return await APIHandler.request("/bills/" + id, "PATCH", body);
    },
    update_payment_status: async (
      ids: string[],
      new_payment_status: BillPaymentStatusEnum,
      companyBankAccountIdToUse?: string
    ): Promise<BulkUpdateResult<Bill> & MiterError> => {
      const body = { ids, new_payment_status, company_bank_account_id: companyBankAccountIdToUse };
      return await APIHandler.request("/bills/update-payment-status", "PATCH", body);
    },
    retry_payment: async (
      ids: string[],
      companyBankAccountIdToUse?: string
    ): Promise<BulkUpdateResult<Bill> & MiterError> => {
      const body = { ids, company_bank_account_id: companyBankAccountIdToUse };
      return await APIHandler.request("/bills/retry-payment", "PATCH", body);
    },

    retrieve: async (id: string): Promise<AggregatedBill & MiterError> => {
      return await APIHandler.request("/bills/" + id, "GET");
    },
    archive: async (ids: string[]): Promise<BulkUpdateResult<Bill> & MiterError> => {
      return await APIHandler.request("/bills/archive", "DELETE", { ids });
    },
    forage: async (params: ForageRequest): Promise<ForageResponse<AggregatedBill>> => {
      const q = serialize(params);
      const queryPath = "/bills/forage";

      return await handleForageResponse(APIHandler.request(queryPath, "POST", { q }));
    },
    duplicate: async (ids: string[]): Promise<BulkUpdateResult<Bill> & MiterError> => {
      return await APIHandler.request("/bills/duplicate", "POST", { ids });
    },
    import: async (body: CreateBillParams[]): Promise<ImportBillsResponse> => {
      return await APIHandler.request("/bills/import", "POST", body);
    },
    mail_paychecks: async (payment_ids: string[]): Promise<BulkCreateResponse<Bill> & MiterError> => {
      return await APIHandler.request("/bills/mail-paychecks", "POST", { payment_ids });
    },
  },
  time_off: {
    policies: {
      create: async (body: TimeOffPolicyParams): Promise<CreateTimeOffPolicyResponse> => {
        const queryPath = "/time-off-policies";
        return await APIHandler.request(queryPath, "POST", body);
      },
      retrieve: async (id: string): Promise<TimeOffPolicy & MiterError> => {
        const queryPath = "/time-off-policies/" + id;
        return await APIHandler.request(queryPath, "GET");
      },
      update: async (id: string, body: TimeOffPolicyParams): Promise<UpdateTimeOffPolicyResponse> => {
        const queryPath = "/time-off-policies/" + id;
        return await APIHandler.request(queryPath, "PATCH", body);
      },
      delete: async (id: string): Promise<TimeOffPolicy & MiterError> => {
        const queryPath = "/time-off-policies/" + id;
        return await APIHandler.request(queryPath, "DELETE");
      },
      retrieve_many: async (filterArray: MiterFilterArray): Promise<RetrieveTimeOffPoliciesResponse> => {
        const queryPath = "/time-off-policies?" + qs.stringify(filterArray);
        return await APIHandler.request(queryPath, "GET");
      },
      update_enrollees: async (
        id: string,
        employees: TimeOffEmployee[]
      ): Promise<TimeOffEnrollmentResult & MiterError> => {
        const queryPath = "/time-off-policies/" + id + "/enrollees";
        return await APIHandler.request(queryPath, "POST", { employees });
      },
      import_enrollees: async (
        payload: ImportParams<EnrolleeRecord, EnrolleeRecord> & { companyId: string; policyId?: string }
      ): Promise<ImportResult & MiterError> => {
        const queryPath = "/time-off-policies/import-enrollees";
        return await APIHandler.request(queryPath, "PATCH", payload);
      },
    },
    requests: {
      create: async (body: CreateTimeOffRequestParams): Promise<CreateTimeOffRequestResponse> => {
        const queryPath = "/time-off-requests/";
        return await APIHandler.request(queryPath, "POST", body);
      },
      split_schedule: async (
        id: string,
        body: TimeOffRequestFormSchedule[number][]
      ): Promise<UpdateTimeOffRequestResponse> => {
        const queryPath = "/time-off-requests/" + id + "/split";
        return await APIHandler.request(queryPath, "POST", body);
      },
      retrieve: async (id: string): Promise<RetrieveTimeOffRequestResponse> => {
        const queryPath = "/time-off-requests/" + id;
        return await APIHandler.request(queryPath, "GET");
      },
      update: async (
        id: string,
        body: { data: UpdateTimeoffRequestParams; opts?: { allow_approved_hours_changes?: boolean } }
      ): Promise<UpdateTimeOffRequestResponse> => {
        const queryPath = "/time-off-requests/" + id;
        return await APIHandler.request(queryPath, "PATCH", body);
      },
      update_multiple: async (p: {
        ids: string[];
        update: Partial<TimeOffRequest>;
        opts?: { allow_approved_hours_changes?: boolean };
      }): Promise<BulkUpdateResult & MiterError> => {
        return await APIHandler.request("/time-off-requests/multiple", "PATCH", p);
      },
      delete: async (id: string): Promise<DeleteTimeOffRequestResponse> => {
        const queryPath = "/time-off-requests/" + id;
        return await APIHandler.request(queryPath, "DELETE");
      },
      search: async (filterArray: MiterFilterArray): Promise<RetrieveTimeOffRequestsResponse> => {
        const queryPath = "/time-off-requests/search";
        return await APIHandler.request(queryPath, "POST", filterArray);
      },
      retrieve_many: async (filterArray: MiterFilterArray): Promise<RetrieveTimeOffRequestsResponse> => {
        const queryPath = "/time-off-requests?" + qs.stringify(filterArray);
        return await APIHandler.request(queryPath, "GET");
      },
      retrieve_for_pay_period: async (
        inputs: GetTimeOffRequestsForPayPeriodOrPayrollInputs
      ): Promise<RetrieveTimeOffRequestsResponse> => {
        return await APIHandler.request("/time-off-requests/get-pay-period-requests", "POST", inputs);
      },
      getBalanceEstimate: async (params: BuildBalanceProjectionParams): Promise<BalanceEstimate> => {
        const queryPath = "/time-off-requests/balance-estimate";
        return await APIHandler.request(queryPath, "POST", params);
      },
    },
    updates: {
      retrieve_many: async (filterArray: MiterFilterArray): Promise<RetrieveTimeOffUpdatesResponse> => {
        const queryPath = "/time-off-updates?" + qs.stringify(filterArray);
        return await APIHandler.request(queryPath, "GET");
      },
    },
    leave: {
      create_type: async (body: LeaveTypeRequest): Promise<LeaveType & MiterError> => {
        const queryPath = "/leave-types/";
        return await APIHandler.request(queryPath, "POST", body);
      },
      get_types: async (): Promise<LeaveType[] & MiterError> => {
        const queryPath = "/leave-types/";
        return await APIHandler.request(queryPath, "GET");
      },
      update_type: async (id: string, body: LeaveTypeRequest): Promise<LeaveType & MiterError> => {
        const queryPath = "/leave-types/" + id;
        return await APIHandler.request(queryPath, "PATCH", body);
      },
    },
  },
  wc_codes: {
    create: async (body: CreateWorkersCompCodeParams): Promise<CreateWorkersCompCodeResponse> => {
      return await APIHandler.request("/wc-codes", "POST", body);
    },
    update: async (id: string, body: UpdateWorkersCompCodeParams): Promise<UpdateWorkersCompCodeResponse> => {
      return await APIHandler.request("/wc-codes/" + id, "PATCH", body);
    },
    retrieve_many: async (filterArray: MiterFilterArray): Promise<RetrieveWorkersCompCodesResponse> => {
      const queryPath = "/wc-codes?" + qs.stringify(filterArray);
      return await APIHandler.request(queryPath, "GET");
    },
  },
  wc_groups: {
    create: async (body: CreateWorkersCompGroupParams): Promise<WorkersCompGroup & MiterError> => {
      return await APIHandler.request("/wc-groups", "POST", body);
    },
    search: async (filter: MiterFilterArray): Promise<WorkersCompGroup[] & MiterError> => {
      return await APIHandler.request("/wc-groups/search", "POST", { filter });
    },
    update: async (
      id: string,
      body: FrontendModel<UpdateWorkersCompGroupParams>
    ): Promise<WorkersCompGroup & MiterError> => {
      return await APIHandler.request("/wc-groups/" + id, "PATCH", body);
    },
    delete: async (id: string): Promise<WorkersCompGroup & MiterError> => {
      return await APIHandler.request("/wc-groups/" + id, "DELETE");
    },
  },
  users: {
    sendMagicLink: async (email: string): Promise<SendMagicLinkResponse> => {
      const queryPath = "/users/send-magic-link";
      return await APIHandler.request(queryPath, "POST", { email });
    },
    sendAuthCode: async (phone: string): Promise<SendAuthCodeResponse & MiterError> => {
      const queryPath = "/users/send-auth-code";
      return await APIHandler.request(queryPath, "POST", { phone });
    },
    getMiterGuidesJwt: async (): Promise<{ token: string } & MiterError> => {
      const queryPath = `/users/miter-guides-jwt`;
      return await APIHandler.request(queryPath, "POST");
    },
    reverify: async (auth_code: string, method_id: string): Promise<User & MiterError> => {
      const queryPath = "/users/reverify";
      return await APIHandler.request(queryPath, "POST", { auth_code, method_id });
    },
    authenticate: async (
      token: string,
      accountId?: string
    ): Promise<DashboardAuthenticatedUserData & MiterError> => {
      const queryPath = "/users/authenticate";
      return await APIHandler.request(queryPath, "POST", { token, account_id: accountId, app: "dashboard" });
    },

    consentToTos: async (user_id: string, version: string): Promise<User & MiterError> => {
      return await APIHandler.request("/users/consent-to-tos", "POST", { user_id, version });
    },
    update: async (id: string, body: Partial<User>): Promise<User & MiterError> => {
      return await APIHandler.request("/users/" + id, "PATCH", body);
    },
  },
  rate_differentials: {
    create: async (params: Partial<RateDifferential>): Promise<RateDifferential & MiterError> => {
      return await APIHandler.request("/rate-differentials", "POST", params);
    },
    update: async (id: string, body: Partial<RateDifferential>): Promise<RateDifferential & MiterError> => {
      return await APIHandler.request("/rate-differentials/" + id, "PATCH", body);
    },
    search: async (body: MiterQueryObject): Promise<RateDifferential[] & MiterError> => {
      return await APIHandler.request("/rate-differentials/search", "POST", body);
    },
  },
  burden_rates: {
    create: async (params: Partial<BurdenRate>): Promise<BurdenRate & MiterError> => {
      return await APIHandler.request("/burden-rates", "POST", params);
    },
    update: async (id: string, body: Partial<BurdenRate>): Promise<BurdenRate & MiterError> => {
      return await APIHandler.request("/burden-rates/" + id, "PATCH", body);
    },
    search: async (body: MiterQueryObject): Promise<BurdenRate[] & MiterError> => {
      return await APIHandler.request("/burden-rates/search", "POST", body);
    },
  },
  standard_classifications: {
    search: async (body: MiterQueryObject): Promise<StandardClassification[] & MiterError> => {
      return await APIHandler.request("/standard-classifications/search", "POST", body);
    },
  },
  roles: {
    retrieve_company_roles: async (company: string): Promise<RetrieveCompanyRolesResponse> => {
      const queryPath = "/roles/" + company;
      return await APIHandler.request(queryPath, "GET");
    },
    create: async (body: CreateRoleParams): Promise<CreateRoleResponse> => {
      const queryPath = "/roles";
      return await APIHandler.request(queryPath, "POST", body);
    },
    update_many: async (body: UpdateRolesParams): Promise<UpdateRoleResponse & MiterError> => {
      return await APIHandler.request("/roles", "PATCH", body);
    },
    update: async (id: string, body: UpdateRoleParams): Promise<Role & MiterError> => {
      return await APIHandler.request("/roles/" + id, "PATCH", body);
    },
  },
  canny: {
    get_sso_token: async (user_id: string, company_id: string): Promise<{ token: string } & MiterError> => {
      const queryPath = "/canny/sso-token";
      return await APIHandler.request(queryPath, "POST", { user_id, company_id });
    },
  },
  sessions: {
    current: async (): Promise<CurrentSessionResponse & MiterError> => {
      const queryPath = "/sessions/current";
      return await APIHandler.request(queryPath, "GET");
    },
    update: async (params: SessionUpdateParams): Promise<DashboardAuthenticatedUserData & MiterError> => {
      const queryPath = "/sessions/update";
      return await APIHandler.request(queryPath, "POST", params);
    },
    delete_current: async (): Promise<DeleteCurrentSessionResponse & MiterError> => {
      const queryPath = "/sessions/current";
      return await APIHandler.request(queryPath, "DELETE");
    },
  },
  stripe_accounts: {
    retrieve: async (
      stripe_id: string,
      query?: RetrieveStripeAccountQuery
    ): Promise<StripeAccountResponse & MiterError> => {
      return await APIHandler.request("/stripe-accounts/" + stripe_id + "?" + qs.stringify(query), "GET");
    },
    create: async (body: CreateStripeAccountBody): Promise<StripeAccountResponse & MiterError> => {
      return await APIHandler.request("/stripe-accounts/", "POST", body);
    },
    inbound_transfer: async (
      body: CreateInboundTransferBody
    ): Promise<StripeInboundTransfer & MiterError> => {
      return await APIHandler.request("/stripe-transfer/inbound-transfer", "POST", body);
    },
    list_inbound_transfers: async (query: {
      financialAccountStripeId: string;
    }): Promise<StripeInboundTransfer[] & MiterError> => {
      return await APIHandler.request("/stripe-transfer/inbound-transfer?" + qs.stringify(query), "GET");
    },
  },
  expense_cards: {
    list: async (params: MiterQueryObject): Promise<ExpenseCard[] & MiterError> => {
      return await APIHandler.request("/expense-cards/list", "POST", params);
    },
    retrieve: async (id: string): Promise<ExpenseCard & MiterError> => {
      return await APIHandler.request("/expense-cards/" + id, "GET");
    },
    create: async (body: CreateExpenseCardBody): Promise<ExpenseCard & MiterError> => {
      return await APIHandler.request("/expense-cards/", "POST", body);
    },
    update: async (id: string, body: UpdateExpenseCardBody): Promise<void & MiterError> => {
      return await APIHandler.request("/expense-cards/" + id, "PATCH", body);
    },
  },
  ledger_entries: {
    retrieve: async (body: {
      queryObject: MiterQueryObject;
      includeInvalid?: boolean;
    }): Promise<EnrichedLedgerEntry[] & MiterError> => {
      return await APIHandler.request("/ledger-entries/retrieve", "POST", body);
    },
    table: async (body: {
      queryObject: MiterQueryObject;
      includeInvalid?: boolean;
    }): Promise<TableLedgerEntry[] & MiterError> => {
      return await APIHandler.request("/ledger-entries/table", "POST", body);
    },
    update: async (
      ids: string[],
      update: UpdateLedgerEntryParams
    ): Promise<{ failures: BulkOpFailure[] } & MiterError> => {
      return await APIHandler.request("/ledger-entries", "PATCH", { ids, update });
    },
    refresh: async (
      companyId: string,
      ids: string[]
    ): Promise<{ failures: BulkOpFailure[] } & MiterError> => {
      return await APIHandler.request("/ledger-entries/refresh", "POST", { ids, companyId });
    },
    get_jonas_csv: async (ledgerEntryIds: string[], icId: string): Promise<{ csv: string } & MiterError> => {
      const queryPath = "/ledger-entries/jonas-csv";
      return await APIHandler.request(queryPath, "POST", { ledgerEntryIds, icId });
    },
    get_draft_payroll_preview: async (
      draftPayroll: AggregatedPayroll
    ): Promise<EnrichedLedgerEntry & MiterError> => {
      const queryPath = "/ledger-entries/draft-payroll-preview";
      return await APIHandler.request(queryPath, "POST", { draftPayroll });
    },
    get_intacct_csv: async (inputs: {
      ledgerEntryIds: string[];
      le?: EnrichedLedgerEntry;
      icId: string;
    }): Promise<{ csv: string } & MiterError> => {
      const queryPath = "/ledger-entries/sage-intacct-csv";
      return await APIHandler.request(queryPath, "POST", inputs);
    },
    get_foundation_csv: async (inputs: {
      ledgerEntryIds: string[];
      companyId: string;
    }): Promise<FoundationGlEntryCsvRow[] & MiterError> => {
      const queryPath = "/ledger-entries/foundation-csv";
      return await APIHandler.request(queryPath, "POST", inputs);
    },
    get_sage_300_jcd: async (inputs: {
      ledgerEntryIds: string[];
      draftPayroll?: AggregatedPayroll;
      icId: string;
    }): Promise<{ jcd: string; warnings: Sage300JcdWarning[] } & MiterError> => {
      const queryPath = "/ledger-entries/sage-300-jcd";
      return await APIHandler.request(queryPath, "POST", inputs);
    },
    get_sage_300_time_entry_csv: async (
      earnings: AggregatedMiterEarning[],
      companyId: string,
      integrationConnectionId: string,
      periodStart: string,
      periodEnd: string
    ): Promise<{ csv: string; warnings: Sage300JcdWarning[] } & MiterError> => {
      const queryPath = "/ledger-entries/sage-300-time-entry-csv-download";
      return await APIHandler.request(queryPath, "POST", {
        earnings,
        companyId,
        integrationConnectionId,
        periodStart,
        periodEnd,
      });
    },
  },
  ledger_accounts: {
    create: async (params: CreateLedgerAccountParams): Promise<LedgerAccount & MiterError> => {
      return await APIHandler.request("/ledger-accounts/", "POST", params);
    },
    update: async (id: string, params: Partial<LedgerAccount>): Promise<LedgerAccount & MiterError> => {
      return await APIHandler.request("/ledger-accounts/" + id, "PATCH", params);
    },
    bulk_update: async (
      params: BulkUpdateParams<Partial<LedgerAccount>>
    ): Promise<BulkUpdateResult<LedgerAccount> & MiterError> => {
      return await APIHandler.request("/ledger-accounts/bulk", "PATCH", params);
    },
    retrieve: async (filterArray: MiterFilterArray): Promise<LedgerAccount[] & MiterError> => {
      const queryPath = "/ledger-accounts?" + qs.stringify(filterArray);
      return await APIHandler.request(queryPath, "GET");
    },
    import: async (body: ImportLedgerAccountsParams): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/ledger-accounts/import", "POST", body);
    },
  },
  pay_schedules: {
    retrieve: async (query: MiterQueryObject): Promise<PaySchedule[] & MiterError> => {
      return await APIHandler.request("/pay-schedules/retrieve", "POST", query);
    },
    create: async (params: Partial<PaySchedule>): Promise<PaySchedule & MiterError> => {
      return await APIHandler.request("/pay-schedules", "POST", params);
    },
    update: async (id: string, update: UpdatePayScheduleParams): Promise<PaySchedule & MiterError> => {
      return await APIHandler.request("/pay-schedules/" + id, "PATCH", update);
    },
    archive: async (id: string): Promise<{ success: true } & MiterError> => {
      return await APIHandler.request("/pay-schedules/" + id + "/archive", "PATCH");
    },
    paydays: async (id: string, start?: string, end?: string): Promise<CheckPayday[] & MiterError> => {
      const path = "/pay-schedules/" + id + "/paydays?" + qs.stringify({ start, end });
      return await APIHandler.request(path, "GET");
    },
  },
  integrations: {
    retrieve: async (company_id: string): Promise<MiterIntegrationForCompany[] & MiterError> => {
      const queryPath = "/integrations/" + company_id;
      return await APIHandler.request(queryPath, "GET");
    },
    setup: async (
      companyId: string,
      key: MiterIntegrationKey
    ): Promise<SetupIntegrationResponse & MiterError> => {
      const queryPath = "/integration-connections";
      return await APIHandler.request(queryPath, "POST", { key, companyId });
    },
    get_setup_link: async (params: {
      key: MiterIntegrationKey;
      company_id?: string;
      integrationConnectionId?: string;
    }): Promise<{ link?: string } & MiterError> => {
      const queryPath = "/integration-connections/setup-link?";
      return await APIHandler.request(queryPath, "POST", params);
    },
    get_agave_link_token: async (id: string): Promise<{ token: string } & MiterError> => {
      const queryPath = "/integration-connections/" + id + "/agave-link-token";
      return await APIHandler.request(queryPath, "GET");
    },
    get_merge_link_token: async (id: string): Promise<{ token: string } & MiterError> => {
      const queryPath = "/integration-connections/" + id + "/merge-link-token";
      return await APIHandler.request(queryPath, "GET");
    },
    exchange_agave_public_token: async (
      id: string,
      publicToken: string
    ): Promise<IntegrationConnection & MiterError> => {
      const queryPath = "/integration-connections/" + id + "/exchange-agave-public-token";
      return await APIHandler.request(queryPath, "POST", { publicToken });
    },
    exchange_merge_public_token: async (
      id: string,
      publicToken: string
    ): Promise<IntegrationConnection & MiterError> => {
      const queryPath = "/integration-connections/" + id + "/exchange-merge-public-token";
      return await APIHandler.request(queryPath, "POST", { publicToken });
    },
    add_from_url: async (
      companyId: string,
      url: string,
      key: string
    ): Promise<IntegrationConnection & MiterError> => {
      const queryPath = "/integration-connections/add-from-url";
      return await APIHandler.request(queryPath, "POST", { key, companyId, url });
    },
    login_with_username_password: async (inputs: {
      id: string;
      username: string;
      password: string;
      otherLoginData?: IntegrationOtherLoginInfo;
    }): Promise<IntegrationConnection & MiterError> => {
      const { id, ...rest } = inputs;
      const queryPath = `/integration-connections/${id}/login`;
      return await APIHandler.request(queryPath, "POST", rest);
    },
    submit_access_token: async (inputs: {
      id: string;
      domain: string;
      access_token: string;
    }): Promise<IntegrationConnection & MiterError> => {
      const { id, ...rest } = inputs;
      const queryPath = `/integration-connections/${id}/connect-access-token`;
      return await APIHandler.request(queryPath, "POST", rest);
    },
    exchange_client_credentials: async (inputs: {
      clientId: string;
      clientSecret: string;
      tenantId?: string;
      integrationConnectionId: string;
    }): Promise<IntegrationConnection & MiterError> => {
      const { clientId, clientSecret, tenantId, integrationConnectionId } = inputs;
      const queryPath = `/integration-connections/${integrationConnectionId}/exchange-client-credentials`;
      return await APIHandler.request(queryPath, "POST", {
        clientId,
        clientSecret,
        tenantId,
      });
    },
    get_mgmt_link: async (
      key: MiterIntegrationKey,
      integrationConnectionId: string
    ): Promise<{ link: string } & MiterError> => {
      const queryPath = "/integrations/mgmt-link?" + qs.stringify({ key, integrationConnectionId });
      return await APIHandler.request(queryPath, "GET");
    },
    get_jonas_companies: async (integrationConnectionId: string): Promise<JonasCompany[] & MiterError> => {
      const queryPath = "/integration-connections/" + integrationConnectionId + "/jonas-companies";
      return await APIHandler.request(queryPath, "GET");
    },
    get_intacct_objects: async (
      icId: string,
      objectTypes: IntacctConfigObjectType[]
    ): Promise<GetIntacctObjectsResponse & MiterError> => {
      const queryPath =
        "/integration-connections/" + icId + "/intacct-objects?" + qs.stringify({ objectTypes });
      return await APIHandler.request(queryPath, "GET");
    },
    confirm_hh2_setup: async (id: string): Promise<{ success: true } & MiterError> => {
      const queryPath = `/integration-connections/${id}/confirm-hh2-setup`;
      return await APIHandler.request(queryPath, "POST");
    },
    get_connection_status: async (
      integrationConnectionId: string
    ): Promise<IntegrationStatusObj & MiterError> => {
      const queryPath = "/integration-connections/" + integrationConnectionId + "/status";
      return await APIHandler.request(queryPath, "GET");
    },
    update_connection: async (
      id: string,
      update: Partial<IntegrationConnection>
    ): Promise<MiterIntegrationForCompany & MiterError> => {
      const queryPath = "/integration-connections/" + id;
      return await APIHandler.request(queryPath, "PATCH", update);
    },
    disconnect: async (integrationConnectId: string): Promise<MiterIntegrationForCompany & MiterError> => {
      const queryPath = `/integration-connections/${integrationConnectId}/disconnect`;
      return await APIHandler.request(queryPath, "POST");
    },
    get_sync: async (connId: string, syncId: string): Promise<IntegrationSync & MiterError> => {
      const queryPath = "/integration-connections/" + connId + "/syncs/" + syncId;
      return await APIHandler.request(queryPath, "GET");
    },
    get_syncs: async (params: {
      integrationConnectionId: string;
      start: string;
      end: string;
    }): Promise<IntegrationSync[] & MiterError> => {
      const { start, end, integrationConnectionId: id } = params;
      const queryPath = "/integration-connections/" + id + "/syncs?" + qs.stringify({ start, end });
      return await APIHandler.request(queryPath, "GET");
    },
    get_syncable_objects: async (inputs: {
      id: string;
      direction: IntegrationSyncOpDirection;
      entity: IntegrationEntity;
      modifiedSince?: number;
      limit?: string;
    }): Promise<SyncableObjectResult & MiterError> => {
      const { direction, entity, id, modifiedSince, limit } = inputs;
      const queryPath =
        "/integration-connections/" +
        id +
        "/syncable-objects?" +
        qs.stringify({ direction, entity, modifiedSince, limit });
      return await APIHandler.request(queryPath, "GET");
    },
    get_xero_tracking_categories: async (id: string): Promise<Option<string>[] & MiterError> => {
      const queryPath = "/integration-connections/" + id + "/xero-tracking-categories";
      return await APIHandler.request(queryPath, "GET");
    },
    run_manual_sync: async (inputs: {
      id: string;
      operation?: ManualSyncOp;
      trigger: IntegrationSyncOpTrigger;
    }): Promise<IntegrationSync[] & MiterError> => {
      const { id, operation, trigger } = inputs;
      const queryPath = "/integration-connections/" + id + "/run-manual-sync";
      return await APIHandler.request(queryPath, "POST", { operation, trigger });
    },
    rerun_sync: async (id: string, syncId: string): Promise<{ success: true } & MiterError> => {
      const queryPath = "/integration-connections/" + id + "/syncs/" + syncId + "/rerun";
      return await APIHandler.request(queryPath, "POST");
    },
    get_sync_results: async (
      integrationConnectionId: string,
      syncId: string
    ): Promise<IntegrationSyncResult[] & MiterError> => {
      const queryPath =
        "/integration-connections/" + integrationConnectionId + "/syncs/" + syncId + "/results";
      return await APIHandler.request(queryPath, "GET");
    },
    acumatica: {
      retrieve_branches: async (integrationConnectionId: string): Promise<AcumaticaBranch[] & MiterError> => {
        const queryPath =
          "/integrations/acumatica/branches?integration_connection_id=" + integrationConnectionId;
        return await APIHandler.request(queryPath, "GET");
      },
      retrieve_corporate_cards: async (
        integrationConnectionId: string
      ): Promise<AcumaticaCorporateCardSourceData[] & MiterError> => {
        const queryPath =
          "/integrations/acumatica/corporate-cards?integration_connection_id=" + integrationConnectionId;
        return await APIHandler.request(queryPath, "GET");
      },
      retrieve_expense_items: async (
        integrationConnectionId: string
      ): Promise<AcumaticaNonStockItemSourceData[] & MiterError> => {
        const queryPath =
          "/integrations/acumatica/expense-items?integration_connection_id=" + integrationConnectionId;
        return await APIHandler.request(queryPath, "GET");
      },
    },
    procore: {
      list_companies: async (
        integrationConnectionId: string
      ): Promise<ProcoreCompanyCompact[] & MiterError> => {
        const query = "/integrations/procore/companies?integration_connection_id=" + integrationConnectionId;
        return await APIHandler.request(query, "GET");
      },
      validate_permissions: async (
        procoreCompanyId: number,
        integrationConnectionId: string
      ): Promise<PermissionsValidationResponse & MiterError> => {
        const query = `/integrations/procore/validate-permissions?procore_company_id=${procoreCompanyId}&integration_connection_id=${integrationConnectionId}`;
        return await APIHandler.request(query, "GET");
      },
      connect_companies: async (
        params: ConnectProcoreToMiterCompanyParams
      ): Promise<Company & MiterError> => {
        const query = "/integrations/procore/connect-companies";
        return await APIHandler.request(query, "POST", params);
      },
      list_custom_field_definitions: async (
        integrationConnectionId: string
      ): Promise<ProcoreCustomFieldDefinition[] & MiterError> => {
        const query = `/integrations/procore/custom-field-definitions?integration_connection_id=${integrationConnectionId}`;
        return await APIHandler.request(query, "GET");
      },
    },
    simplyinsured: {
      retrieve_modal_link: async (
        company: string,
        payload: { email: string }
      ): Promise<{ url: string } & MiterError> => {
        const queryPath = "/integrations/simplyinsured/companies/" + company + "/modal-link";
        return await APIHandler.request(queryPath, "POST", payload);
      },
      verify_unique_email: async (
        company: string,
        payload: { email: string }
      ): Promise<{ unique: boolean }> => {
        const queryPath = "/integrations/simplyinsured/companies/" + company + "/verify_unique_email";
        return await APIHandler.request(queryPath, "POST", payload);
      },
    },
    employee_navigator: {
      get_demographic_audit_report: async (
        company: string
      ): Promise<ENDemographicAuditRow[] & MiterError> => {
        const queryPath =
          "/integrations/employee-navigator/companies/" + company + "/demographic-audit-report";
        return await APIHandler.request(queryPath, "GET");
      },
      get_deductions_audit_report: async (company: string): Promise<ENDeductionAuditRow[] & MiterError> => {
        const queryPath =
          "/integrations/employee-navigator/companies/" + company + "/deductions-audit-report";
        return await APIHandler.request(queryPath, "GET");
      },
    },
    quickbooks: {
      get_activity_mapping_options: async (
        companyId: string,
        mapping_options = "classes,service_items"
      ): Promise<ActivityMappingOptionsResponse & MiterError> => {
        console.log({ mapping_options });
        return await APIHandler.request(
          `/integrations/quickbooks/${companyId}/activity-mapping-options?mapping_options=${mapping_options}`,
          "GET"
        );
      },
      get_qb_customers: async (
        key: "qbd" | "qbo",
        companyId: string
      ): Promise<QBCustomersOptionsResponse & MiterError> => {
        return await APIHandler.request("/integrations/quickbooks/" + companyId + "/customers/" + key, "GET");
      },
    },
    sage_100: {
      get_payroll_exports: async (
        inputs: Omit<Sage100PayrollExportInputs, "abilities">
      ): Promise<CsvDownloadObj[] & MiterError> => {
        const queryPath = "/integrations/sage-100/payroll-exports";
        return await APIHandler.request(queryPath, "POST", inputs);
      },
    },
    netsuite: {
      get_activity_mapping_options: async (
        integrationConnectionId: string
      ): Promise<{ costCodes: MiterDefinedNetSuiteCostCode[] } & MiterError> => {
        return await APIHandler.request(
          "/integration-connections/" + integrationConnectionId + "/netsuite/activity-mapping-options",
          "GET"
        );
      },
      get_subsidiaries: async (
        integrationConnectionId: string
      ): Promise<{ subsidiaries: NetSuiteSubsidiary[] } & MiterError> => {
        return await APIHandler.request(
          "/integration-connections/" + integrationConnectionId + "/netsuite/subsidiaries",
          "GET"
        );
      },
    },
  },
  allowances: {
    retrieve: async (query: MiterQueryObject): Promise<Allowance[] & MiterError> => {
      return await APIHandler.request("/allowances/retrieve", "POST", query);
    },
    create: async (params: Partial<Allowance>): Promise<Allowance & MiterError> => {
      return await APIHandler.request("/allowances", "POST", params);
    },
    import: async (payload: ImportAllowancesParams): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/allowances/import", "POST", payload);
    },
    update: async (
      ids: string[],
      update: Partial<Allowance>
    ): Promise<{ successful: number; unsuccessful: number } & MiterError> => {
      return await APIHandler.request("/allowances", "PATCH", { ids, update });
    },
    bulk_update: async (
      params: BulkUpdateParams<UpdateAllowanceParams>
    ): Promise<BulkUpdateResult & MiterError> => {
      const queryPath = "/allowances/bulk";
      return await APIHandler.request(queryPath, "PATCH", params);
    },
  },
  slack: {
    send_message: async (message: string, channel?: string): Promise<void & MiterError> => {
      return await APIHandler.request("/slack", "POST", { message, channel });
    },
  },
  assignments: {
    retrieve: async (id: string): Promise<Assignment & MiterError> => {
      return await APIHandler.request("/assignments/" + id, "GET");
    },
    search: async (filter: MiterFilterArray): Promise<Assignment[] & MiterError> => {
      return await APIHandler.request("/assignments/search", "POST", filter);
    },
    create: async (params: CreateAssignmentParams): Promise<Assignment & MiterError> => {
      return await APIHandler.request("/assignments", "POST", params);
    },
    update: async (id: string, params: UpdateAssignmentParams): Promise<Assignment & MiterError> => {
      return await APIHandler.request("/assignments/" + id, "PATCH", params);
    },
    delete: async (
      id: string,
      params?: {
        delete_recurrence: "all" | "this_and_future" | "this";
        occurrence_starts_at: number;
      }
    ): Promise<Assignment & MiterError> => {
      return await APIHandler.request("/assignments/" + id, "DELETE", params);
    },
  },
  daily_reports: {
    create: async (
      body: CreateDailyReportParams
    ): Promise<{ daily_report: AggregatedDailyReport } & MiterError> => {
      return await APIHandler.request("/daily-reports", "POST", body);
    },
    update: async (
      id: string,
      body: UpdateDailyReportParams
    ): Promise<{ daily_report: AggregatedDailyReport } & MiterError> => {
      return await APIHandler.request("/daily-reports/" + id, "PATCH", body);
    },
    retrieve: async (id: string): Promise<AggregatedDailyReport & MiterError> => {
      return await APIHandler.request("/daily-reports/" + id, "GET");
    },
    retrieve_pdfs: async (
      ids: string[],
      options: { timezone?: string }
    ): Promise<{ data: string; filename: string } & MiterError> => {
      return await APIHandler.request("/daily-reports/pdfs", "POST", { options, ids });
    },
    delete: async (id: string): Promise<AggregatedDailyReport & MiterError> => {
      return await APIHandler.request("/daily-reports/" + id, "DELETE");
    },
    search: async (
      filter: MiterFilterArray,
      options?: { aggregateTimesheets?: boolean }
    ): Promise<AggregatedDailyReport[] & MiterError> => {
      return await APIHandler.request("/daily-reports/search", "POST", { filter, ...options });
    },
    esign: async (
      id: string,
      params: CreateESignatureParams[]
    ): Promise<AggregatedDailyReport & MiterError> => {
      return await APIHandler.request("/daily-reports/" + id + "/esign", "POST", params);
    },
    approve: async (id: string): Promise<AggregatedDailyReport & MiterError> => {
      return await APIHandler.request("/daily-reports/" + id + "/approve", "POST");
    },
    unapprove: async (id: string): Promise<AggregatedDailyReport & MiterError> => {
      return await APIHandler.request("/daily-reports/" + id + "/unapprove", "POST");
    },
    invalidate_esignatures: async (id: string): Promise<AggregatedDailyReport & MiterError> => {
      return await APIHandler.request("/daily-reports/" + id + "/invalidate-esignatures", "POST");
    },
    forage: async (params: ForageRequest): Promise<ForageResponse> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/daily-reports/forage?q=" + q;

      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
  },
  equipment: {
    create: async (body: CreateEquipmentParams): Promise<Equipment & MiterError> => {
      return await APIHandler.request("/equipment", "POST", body);
    },
    update: async (id: string, body: UpdateEquipmentParams): Promise<Equipment & MiterError> => {
      return await APIHandler.request("/equipment/" + id, "PATCH", body);
    },
    retrieve: async (id: string, aggregated?: boolean): Promise<Equipment & MiterError> => {
      return await APIHandler.request("/equipment/" + id + (aggregated ? "?aggregated=true" : ""), "GET");
    },

    delete: async (id: string): Promise<Equipment & MiterError> => {
      return await APIHandler.request("/equipment/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<Equipment[] & MiterError> => {
      return await APIHandler.request("/equipment/search", "POST", { filter });
    },
    import: async (body: {
      clean_inputs: CreateEquipmentParams[];
      raw_inputs: ImportInputsRaw;
    }): Promise<ImportResult> => {
      return await APIHandler.request("/equipment/import", "POST", body);
    },
  },
  equipment_logs: {
    create: async (
      params: CreateEquipmentLogParams,
      options: { aggregated?: boolean }
    ): Promise<(EquipmentLog | AggregatedEquipmentLog) & MiterError> => {
      return await APIHandler.request("/equipment-logs", "POST", { params, options });
    },
    update: async (
      id: string,
      params: UpdateEquipmentLogParams,
      options: { aggregated?: boolean }
    ): Promise<(EquipmentLog | AggregatedEquipmentLog) & MiterError> => {
      return await APIHandler.request("/equipment-logs/" + id, "PATCH", { params, options });
    },
    retrieve: async (
      id: string,
      aggregated?: boolean
    ): Promise<(EquipmentLog[] | AggregatedEquipmentLog[]) & MiterError> => {
      return await APIHandler.request(
        "/equipment-logs/" + id + (aggregated ? "?aggregated=true" : ""),
        "GET"
      );
    },
    delete: async (
      id: string,
      options: { aggregated?: boolean }
    ): Promise<(EquipmentLog | AggregatedEquipmentLog) & MiterError> => {
      return await APIHandler.request("/equipment-logs/" + id, "DELETE", options);
    },
    search: async (
      filter: MiterFilterArray,
      options?: { aggregated?: boolean }
    ): Promise<(EquipmentLog[] | AggregatedEquipmentLog[]) & MiterError> => {
      return await APIHandler.request("/equipment-logs/search", "POST", { filter, ...options });
    },
  },
  equipment_timesheets: {
    create: async (body: CreateEquipmentTimesheetParams): Promise<EquipmentTimesheet & MiterError> => {
      return await APIHandler.request("/equipment-timesheets", "POST", body);
    },
    update: async (
      id: string,
      body: UpdateEquipmentTimesheetParams
    ): Promise<EquipmentTimesheet & MiterError> => {
      return await APIHandler.request("/equipment-timesheets/" + id, "PATCH", body);
    },
    retrieve: async (id: string): Promise<AggregatedEquipmentTimesheet & MiterError> => {
      return await APIHandler.request("/equipment-timesheets/" + id, "GET");
    },
    delete: async (id: string): Promise<EquipmentTimesheet & MiterError> => {
      return await APIHandler.request("/equipment-timesheets/" + id, "DELETE");
    },
    forage: async (params: ForageRequest): Promise<ForageResponse<AggregatedEquipmentTimesheet>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/equipment-timesheets/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "POST"));
    },
    import: async (payload: ImportEquipmentTimesheetsParams): Promise<ImportResult> => {
      return await APIHandler.request("/equipment-timesheets/import", "POST", payload);
    },
  },
  customers: {
    create: async (body: CreateCustomerParams): Promise<Customer & MiterError> => {
      return await APIHandler.request("/customers", "POST", body);
    },
    retrieve: async (id: string): Promise<Customer & MiterError> => {
      return await APIHandler.request("/customers/" + id, "GET");
    },
    update: async (id: string, body: UpdateCustomerParams): Promise<Customer & MiterError> => {
      return await APIHandler.request("/customers/" + id, "PATCH", body);
    },
    search: async (filter: MiterFilterArray): Promise<Customer[] & MiterError> => {
      return await APIHandler.request("/customers/search", "POST", { filter });
    },
  },
  departments: {
    create: async (body: CreateDepartmentParams): Promise<Department & MiterError> => {
      return await APIHandler.request("/departments", "POST", body);
    },
    search: async (filter: MiterFilterArray): Promise<Department[] & MiterError> => {
      return await APIHandler.request("/departments/search", "POST", { filter });
    },
    update: async (id: string, body: UpdateDepartmentParams): Promise<Department & MiterError> => {
      return await APIHandler.request("/departments/" + id, "PATCH", body);
    },
    updateMany: async (
      params: BulkUpdateParams<UpdateDepartmentParams>
    ): Promise<BulkUpdateResult & MiterError> => {
      return await APIHandler.request("/departments/bulk", "PATCH", params);
    },
    delete: async (id: string): Promise<Department & MiterError> => {
      return await APIHandler.request("/departments/" + id, "DELETE");
    },
  },
  locations: {
    create: async (body: CreateLocationParams): Promise<Location & MiterError> => {
      return await APIHandler.request("/locations", "POST", body);
    },
    update: async (id: string, body: UpdateLocationParams): Promise<Location & MiterError> => {
      return await APIHandler.request("/locations/" + id, "PATCH", body);
    },
    archive: async (params: {
      filter: MiterFilterArray;
    }): Promise<BulkUpdateResult<Location> & MiterError> => {
      return await APIHandler.request("/locations/archive", "DELETE", params);
    },
    forage: async (params: ForageRequest): Promise<ForageResponse<Location>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/locations/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
  },
  crews: {
    create: async (body: CreateCrewParams): Promise<Crew & MiterError> => {
      return await APIHandler.request("/crews", "POST", body);
    },
    search: async (filter: MiterFilterArray): Promise<Crew[] & MiterError> => {
      return await APIHandler.request("/crews/search", "POST", { filter });
    },
    update: async (id: string, body: UpdateCrewParams): Promise<Crew & MiterError> => {
      return await APIHandler.request("/crews/" + id, "PATCH", body);
    },
    delete: async (id: string): Promise<Crew & MiterError> => {
      return await APIHandler.request("/crews/" + id, "DELETE");
    },
  },
  permission_groups: {
    create: async (body: CreatePermissionGroupParams): Promise<PermissionGroup & MiterError> => {
      return await APIHandler.request("/permission-groups", "POST", body);
    },
    retrieve_members: async (id: string): Promise<PermissionGroupMemberList & MiterError> => {
      return await APIHandler.request("/permission-groups/" + id + "/members", "GET");
    },
    retrieve_draft_members: async (
      members: PermissionGroup["members"],
      company_id: string
    ): Promise<PermissionGroupMemberList & MiterError> => {
      return await APIHandler.request("/permission-groups/draft-members", "POST", { members, company_id });
    },
    search: async (filter: MiterFilterArray): Promise<PermissionGroup[] & MiterError> => {
      return await APIHandler.request("/permission-groups/search", "POST", { filter });
    },
    update: async (id: string, body: UpdatePermissionGroupParams): Promise<PermissionGroup & MiterError> => {
      return await APIHandler.request("/permission-groups/" + id, "PATCH", body);
    },
    duplicate: async (id: string): Promise<PermissionGroup & MiterError> => {
      return await APIHandler.request("/permission-groups/" + id + "/duplicate", "POST");
    },
    delete: async (id: string): Promise<PermissionGroup & MiterError> => {
      return await APIHandler.request("/permission-groups/" + id, "DELETE");
    },
  },
  esignature_items: {
    retrieve: async (id: string): Promise<ESignatureItem & MiterError> => {
      return await APIHandler.request("/esignature-items/" + id, "GET");
    },
    requests: {
      create: async (body: CreateESignatureRequestParams[]): Promise<ESignatureItem[] & MiterError> => {
        return await APIHandler.request("/esignature-items/requests", "POST", body);
      },
      resend: async (id: string): Promise<ESignatureItem & MiterError> => {
        return await APIHandler.request("/esignature-items/requests/" + id + "/resend", "POST");
      },
      sign: async (id: string, params: SignESignatureRequestParams): Promise<FilePickerFile & MiterError> => {
        return await APIHandler.request("/esignature-items/requests/" + id + "/sign", "POST", params);
      },
    },
    signatures: {
      create: async (body: CreateESignatureParams[]): Promise<ESignatureItem[] & MiterError> => {
        return await APIHandler.request("/esignature-items/signatures", "POST", body);
      },
    },
    delete: async (id: string): Promise<ESignatureItem & MiterError> => {
      return await APIHandler.request("/esignature-items/" + id, "DELETE");
    },
    delete_many: async (ids: string[]): Promise<ESignatureItem[] & MiterError> => {
      return await APIHandler.request("/esignature-items/", "DELETE", { ids });
    },
  },
  initialize_apps: {
    dashboard: async (p: {
      company_id: string;
      user_id: string;
      team_member_id?: string;
      role_id?: string;
    }): Promise<DashboardCompanyData & MiterError> => {
      return await APIHandler.request("/initialize-apps/dashboard", "POST", p);
    },
  },
  user_surveys: {
    create: async (params: Partial<UserSurvey>): Promise<UserSurvey & MiterError> => {
      return await APIHandler.request("/user-surveys", "POST", params);
    },
  },
  report_views: {
    create: async (params: CreateReportViewParams): Promise<ReportView & MiterError> => {
      return await APIHandler.request("/report-views", "POST", params);
    },
    retrieve: async (id: string): Promise<ReportView & MiterError> => {
      return await APIHandler.request("/report-views/" + id, "GET");
    },
    update: async (id: string, params: UpdateReportViewParams): Promise<ReportView & MiterError> => {
      return await APIHandler.request("/report-views/" + id, "PATCH", params);
    },
    delete: async (id: string): Promise<ReportView & MiterError> => {
      return await APIHandler.request("/report-views/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<ReportView[] & MiterError> => {
      return await APIHandler.request("/report-views/search", "POST", { filter });
    },
  },
  custom_fields: {
    create: async (params: CreateCustomFieldParams): Promise<CustomField & MiterError> => {
      return await APIHandler.request("/custom-fields", "POST", params);
    },
    retrieve: async (id: string): Promise<CustomField & MiterError> => {
      return await APIHandler.request("/custom-fields/" + id, "GET");
    },
    update: async (id: string, params: UpdateCustomFieldParams): Promise<CustomField & MiterError> => {
      return await APIHandler.request("/custom-fields/" + id, "PATCH", params);
    },
    delete: async (id: string): Promise<CustomField & MiterError> => {
      return await APIHandler.request("/custom-fields/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<CustomField[] & MiterError> => {
      return await APIHandler.request("/custom-fields/search", "POST", { filter });
    },
  },
  custom_field_values: {
    search: async (filter: MiterFilterArray): Promise<CustomFieldValue[] & MiterError> => {
      return await APIHandler.request("/custom-field-values/search", "POST", { filter });
    },
    save: async (params: SaveCustomFieldValueParams[]): Promise<CustomFieldValue[] & MiterError> => {
      return await APIHandler.request("/custom-field-values/", "POST", params);
    },
  },
  i_9s: {
    create: async (params: CreateI9Params): Promise<AggregatedI9 & MiterError> => {
      return await APIHandler.request("/i-9s", "POST", params);
    },
    retrieve: async (id: string): Promise<AggregatedI9 & MiterError> => {
      return await APIHandler.request("/i-9s/" + id, "GET");
    },
    retrieve_pdf: async (id: string): Promise<Response & MiterError> => {
      return await APIHandler.request("/i-9s/" + id + "/pdf", "POST");
    },
    update: async (id: string, params: UpdateI9Params): Promise<AggregatedI9 & MiterError> => {
      return await APIHandler.request("/i-9s/" + id, "PATCH", params);
    },
    delete: async (id: string): Promise<AggregatedI9 & MiterError> => {
      return await APIHandler.request("/i-9s/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<AggregatedI9[] & MiterError> => {
      return await APIHandler.request("/i-9s/search", "POST", { filter });
    },
    complete: async (id: string, params: CompleteI9Params): Promise<AggregatedI9 & MiterError> => {
      return await APIHandler.request("/i-9s/" + id + "/complete", "POST", params);
    },
  },
  notes: {
    create: async (params: CreateNoteParams): Promise<Note & MiterError> => {
      return await APIHandler.request("/notes", "POST", params);
    },
    retrieve: async (id: string): Promise<Note & MiterError> => {
      return await APIHandler.request("/notes/" + id, "GET");
    },
    update: async (id: string, params: UpdateNoteParams): Promise<Note & MiterError> => {
      return await APIHandler.request("/notes/" + id, "PATCH", params);
    },
    delete: async (id: string): Promise<Note & MiterError> => {
      return await APIHandler.request("/notes/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<Note[] & MiterError> => {
      return await APIHandler.request("/notes/search", "POST", { filter });
    },
  },
  holiday_schedules: {
    create: async (params: CreateHolidayScheduleParams): Promise<HolidaySchedule & MiterError> => {
      return await APIHandler.request("/holiday-schedules", "POST", params);
    },
    update: async (
      id: string,
      params: UpdateHolidayScheduleParams
    ): Promise<HolidaySchedule & MiterError> => {
      return await APIHandler.request("/holiday-schedules/" + id, "PATCH", params);
    },
    archive: async (id: string): Promise<TeamMember[] & MiterError> => {
      return await APIHandler.request("/holiday-schedules/" + id + "/archive", "PATCH");
    },
    list: async (filter: MiterFilterArray): Promise<HolidaySchedule[] & MiterError> => {
      return await APIHandler.request("/holiday-schedules/list", "POST", filter);
    },
  },
  check: {
    bank_accounts: {
      list: async (entity_id: string): Promise<CheckBankAccount[] & MiterError> => {
        return await APIHandler.request("/check/bank_accounts?entity_id=" + entity_id, "GET");
      },
    },
    payments: {
      list: async (entity_id: string): Promise<CheckPayment[] & MiterError> => {
        return await APIHandler.request("/check/payments?entity_id=" + entity_id, "GET");
      },
      retry: async (id: string, params: RetryCheckPaymentBodyParams): Promise<CheckPayment & MiterError> => {
        return await APIHandler.request(`/check/payments/${id}/retry`, "POST", params);
      },
      refund: async (id: string): Promise<CheckPayment & MiterError> => {
        return await APIHandler.request(`/check/payments/${id}/refund`, "POST");
      },
    },
    tax_params_juris: {
      list: async (
        entity_id: string
      ): Promise<{ tax_params: CheckTaxParameter[]; jurisdictions: CheckJurisdiction[] } & MiterError> => {
        return await APIHandler.request("/check/tax-params-juris?entity_id=" + entity_id, "GET");
      },
    },
    net_pay_splits: {
      retrieve: async (id: string): Promise<CheckNetPaySplit & MiterError> => {
        return await APIHandler.request(`/check/net-pay-splits/${id}`, "GET");
      },
      create: async (params: CheckNetPaySplitCreationParams): Promise<CheckNetPaySplit & MiterError> => {
        return await APIHandler.request("/check/net-pay-splits", "POST", params);
      },
    },
  },
  clasp: {
    get_employer_component_url: async (companyId: string): Promise<{ url: string } & MiterError> => {
      const queryPath = "/clasp/employer/" + companyId + "/component-url";
      return await APIHandler.request(queryPath, "POST");
    },
    get_employer_auth_token: async (
      companyId: string
    ): Promise<({ access_token: string; expires_at: string } | undefined) & MiterError> => {
      const queryPath = "/clasp/employer/" + companyId + "/auth-token";
      return await APIHandler.request(queryPath, "POST");
    },
    get_member_auth_token: async (
      memberId: string
    ): Promise<({ access_token: string; expires_at: string } | undefined) & MiterError> => {
      const queryPath = "/clasp/member/" + memberId + "/auth-token";
      return await APIHandler.request(queryPath, "POST");
    },
  },
  tags: {
    create: async (params: CreateTagParams): Promise<Tag & MiterError> => {
      return await APIHandler.request("/tags", "POST", params);
    },
    retrieve: async (id: string): Promise<Tag & MiterError> => {
      return await APIHandler.request("/tags/" + id, "GET");
    },
    update: async (id: string, params: UpdateTagParams): Promise<Tag & MiterError> => {
      return await APIHandler.request("/tags/" + id, "PATCH", params);
    },
    delete: async (id: string): Promise<Tag & MiterError> => {
      return await APIHandler.request("/tags/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<Tag[] & MiterError> => {
      return await APIHandler.request("/tags/search", "POST", { filter });
    },
  },
  cost_types: {
    create: async (
      params: PickAndRestPartial<CostType, "company_id" | "label">
    ): Promise<CostType & MiterError> => {
      return await APIHandler.request("/cost-types", "POST", params);
    },
    update: async (id: string, params: Partial<CostType>): Promise<CostType & MiterError> => {
      return await APIHandler.request("/cost-types/" + id, "PATCH", params);
    },
    archive: async (id: string): Promise<CostType & MiterError> => {
      return await APIHandler.request("/cost-types/" + id, "DELETE");
    },
    search: async (params: MiterQueryObject): Promise<CostType[] & MiterError> => {
      return await APIHandler.request("/cost-types/search", "POST", params);
    },
  },
  forms: {
    create: async (params: CreateFormParams): Promise<Form & MiterError> => {
      return await APIHandler.request("/forms", "POST", params);
    },
    retrieve: async (id: string): Promise<AggregatedForm & MiterError> => {
      return await APIHandler.request("/forms/" + id, "GET");
    },
    update: async (id: string, params: UpdateFormParams): Promise<Form & MiterError> => {
      return await APIHandler.request("/forms/" + id, "PATCH", params);
    },
    duplicate: async (id: string): Promise<Form & MiterError> => {
      return await APIHandler.request("/forms/" + id + "/duplicate", "POST");
    },
    delete: async (id: string): Promise<Form & MiterError> => {
      return await APIHandler.request("/forms/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<AggregatedForm[] & MiterError> => {
      return await APIHandler.request("/forms/search", "POST", { filter });
    },
    search_basic_forms: async (filter: MiterFilterArray): Promise<FormWithSubmissionCount[] & MiterError> => {
      return await APIHandler.request("/forms/search-basic-forms", "POST", { filter });
    },
    send_submission_requests: async (
      params: SendSubmissionRequestsParams
    ): Promise<FormSubmission[] & MiterError> => {
      return await APIHandler.request("/forms" + "/send-submission-requests", "POST", params);
    },
  },
  form_submissions: {
    create: async (params: CreateFormSubmissionParams): Promise<FormSubmission & MiterError> => {
      return await APIHandler.request("/form-submissions", "POST", params);
    },
    retrieve: async (id: string): Promise<FormSubmission & MiterError> => {
      return await APIHandler.request("/form-submissions/" + id, "GET");
    },
    update: async (id: string, params: UpdateFormSubmissionParams): Promise<FormSubmission & MiterError> => {
      return await APIHandler.request("/form-submissions/" + id, "PATCH", params);
    },
    delete: async (id: string): Promise<FormSubmission & MiterError> => {
      return await APIHandler.request("/form-submissions/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<FormSubmission[] & MiterError> => {
      return await APIHandler.request("/form-submissions/search", "POST", { filter });
    },
    download_pdf: async (id: string): Promise<{ data: string; filename: string } & MiterError> => {
      return await APIHandler.request("/form-submissions/" + id + "/download-pdf", "GET");
    },
    send_reminders: async (
      ids: string[],
      parent_type?: FormParentType
    ): Promise<BulkOperationResponse & MiterError> => {
      return await APIHandler.request("/form-submissions/send-reminders", "POST", { ids, parent_type });
    },
  },
  performance_review_cycles: {
    create: async (
      params: CreatePerformanceReviewCycleParams
    ): Promise<AggregatedPerformanceReviewCycle & MiterError> => {
      return await APIHandler.request("/performance-review-cycles", "POST", params);
    },
    forage: async (params: ForageRequest): Promise<ForageResponse> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/performance-review-cycles/forage?q=" + q;

      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
    retrieve_many: async (body: MiterFilterArray): Promise<AggregatedLiveTimesheet[] & MiterError> => {
      return await APIHandler.request("/performance-review-cycles/retrieve-many", "POST", body);
    },
    update: async (
      id: string,
      params: UpdatePerformanceReviewCycleParams
    ): Promise<PerformanceReviewCycle & MiterError> => {
      return await APIHandler.request("/performance-review-cycles/" + id, "PATCH", params);
    },
    archive: async (body: { ids: string[]; company_id: string }): Promise<ArchiveResponse & MiterError> => {
      return await APIHandler.request("/performance-review-cycles/archive", "DELETE", body);
    },
    start_review_cycle: async (
      id: string,
      params: { timezone: string }
    ): Promise<PerformanceReviewCycle & MiterError> => {
      return await APIHandler.request(
        "/performance-review-cycles/" + id + "/start-review-cycle",
        "POST",
        params
      );
    },
  },

  performance_reviews: {
    create: async (params: CreatePerformanceReviewParams): Promise<PerformanceReview & MiterError> => {
      return await APIHandler.request("/performance-reviews", "POST", params);
    },
    forage: async (params: ForageRequest): Promise<ForageResponse> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/performance-reviews/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
    archive: async (body: { ids: string[]; company_id: string }): Promise<ArchiveResponse & MiterError> => {
      return await APIHandler.request("/performance-reviews/archive", "DELETE", body);
    },
    unique_review_cycles: async (params: ForageRequest): Promise<ForageResponse> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/performance-reviews/unique-review-cycles?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
  },
  expenses: {
    third_party_cards: {
      list: async (body: MiterQueryObject): Promise<ThirdPartyCard[] & MiterError> => {
        return await APIHandler.request("/expenses/third-party-cards/", "POST", body);
      },
      update: async (
        id: string,
        params: UpdateThirdPartyCardParams
      ): Promise<ThirdPartyCard & MiterError> => {
        return await APIHandler.request("/expenses/third-party-cards/" + id, "PATCH", params);
      },
    },
    card_programs: {
      list: async (body: MiterQueryObject): Promise<AggregatedCardProgram[] & MiterError> => {
        return await APIHandler.request("/expenses/card-programs/", "POST", body);
      },
      update: async (body: Partial<CardProgram>): Promise<AggregatedCardProgram & MiterError> => {
        return await APIHandler.request("/expenses/card-programs/", "PATCH", body);
      },
      combine: async (body: {
        selected_card_program_ids: string[];
        card_program_to_keep: string;
      }): Promise<AggregatedCardProgram & MiterError> => {
        return await APIHandler.request("/expenses/card-programs/combine", "POST", body);
      },
      split: async (body: { selected_card_ids: string[] }): Promise<AggregatedCardProgram & MiterError> => {
        return await APIHandler.request("/expenses/card-programs/split", "POST", body);
      },
      archive: async (id: string): Promise<CardProgram & MiterError> => {
        return await APIHandler.request(`/expenses/card-programs/${id}/archive`, "PATCH");
      },
    },
    forage: async (params: ForageRequest): Promise<ForageResponse<AggregatedExpense>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/expenses/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
    update: async (body: BulkUpdateParams<UpdateExpenseParams>): Promise<BulkUpdateResult & MiterError> => {
      return await APIHandler.request("/expenses", "PATCH", body);
    },
    update_approval_status: async (
      body: BulkUpdateParams<UpdateExpenseParams>
    ): Promise<BulkUpdateResult & MiterError> => {
      return await APIHandler.request("/expenses/approval_status", "PATCH", body);
    },
    import: async (payload: ImportExpensesParams): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/expenses/import", "POST", payload);
    },
    split: async (
      updates: BulkUpdateParams<UpdateExpenseParams>,
      newSplits: CreateSplitCardTransactionParam[]
    ): Promise<{ updated: BulkUpdateResult; created: BulkUpdateResult } & MiterError> => {
      return await APIHandler.request("/expenses/split", "POST", {
        updates,
        new_splits: newSplits,
      });
    },
    categories: {
      list: async (body: MiterQueryObject): Promise<CardTransactionCategory[] & MiterError> => {
        return await APIHandler.request("/card-transaction-categories/list", "POST", body);
      },
      create_or_update: async (
        body: CardTransactionCategoryCreationParams
      ): Promise<CardTransactionCategory & MiterError> => {
        return await APIHandler.request("/card-transaction-categories", "PUT", body);
      },
      archive: async (id: string): Promise<CardTransactionCategory & MiterError> => {
        return await APIHandler.request("/card-transaction-categories/" + id, "DELETE");
      },
    },
  },
  astrada: {
    request_access_token: async (): Promise<AstradaAccessTokenResponse & MiterError> => {
      return await APIHandler.request("/astrada/request-access-token", "GET");
    },
    connect_new_card: async (
      subscriptionId: string,
      cardProgramId?: string
    ): Promise<ThirdPartyCard & MiterError> => {
      return await APIHandler.request("/astrada/card", "POST", {
        subscription_id: subscriptionId,
        card_program_id: cardProgramId,
      });
    },
    create_subaccount: async (): Promise<IntegrationConnection & MiterError> => {
      return await APIHandler.request("/integration-connections/astrada", "POST");
    },
  },
  import_results: {
    create: async (body: CreateImportResultParams): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/import-results", "POST", body);
    },
    retrieve: async (id: string): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/import-results/" + id, "GET");
    },
    update: async (id: string, body: UpdateImportResultParams): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/import-results/" + id, "PATCH", body);
    },
    search: async (filter: MiterFilterArray): Promise<ImportResult[] & MiterError> => {
      return await APIHandler.request("/import-results/search", "POST", { filter });
    },
  },
  action_center: {
    actionable_items: async (teamMemberId: string): Promise<ActionableItems & MiterError> => {
      const queryPath = `/actionable-items/${teamMemberId}/actionable-items`;
      return await APIHandler.request(queryPath, "GET");
    },
    approvals: {
      expenses: async (
        teamMemberId: string,
        params: ForageRequest
      ): Promise<ForageResponse<AggregatedExpense>> => {
        const q = encodeURIComponent(serialize(params));
        const queryPath = `/actionable-items/${teamMemberId}/expenses/?q=${q}`;
        return await handleForageResponse(APIHandler.request(queryPath, "GET"));
      },
      reimbursements: async (
        teamMemberId: string,
        params: ForageRequest
      ): Promise<ForageResponse<AggregatedExpenseReimbursement>> => {
        const q = encodeURIComponent(serialize(params));
        const queryPath = `/actionable-items/${teamMemberId}/reimbursements/?q=${q}`;
        return await handleForageResponse(APIHandler.request(queryPath, "GET"));
      },
      timesheets: async (
        teamMemberId: string,
        params: ForageRequest
      ): Promise<ForageResponse<AggregatedTimesheet>> => {
        const q = encodeURIComponent(serialize(params));
        const queryPath = `/actionable-items/${teamMemberId}/timesheets/?q=${q}`;
        return await handleForageResponse(APIHandler.request(queryPath, "GET"));
      },
      time_off_requests: async (
        teamMemberId: string,
        params: ForageRequest
      ): Promise<ForageResponse<AggregatedTimeOffRequest>> => {
        const q = encodeURIComponent(serialize(params));
        const queryPath = `/actionable-items/${teamMemberId}/time-off-requests/?q=${q}`;
        return await handleForageResponse(APIHandler.request(queryPath, "GET"));
      },
      change_requests: async (
        teamMemberId: string,
        params: ForageRequest
      ): Promise<ForageResponse<AggregatedChangeRequest>> => {
        console.log("change params", params);
        const q = encodeURIComponent(serialize(params));
        const queryPath = `/actionable-items/${teamMemberId}/team-member-change-requests/?q=${q}`;
        return await handleForageResponse(APIHandler.request(queryPath, "GET"));
      },
    },
    needs_attention: {
      expenses: async (
        teamMemberId: string,
        params: ForageRequest
      ): Promise<ForageResponse<AggregatedExpense>> => {
        const q = encodeURIComponent(serialize(params));
        const queryPath = `/needs-attention/${teamMemberId}/expenses/?q=${q}`;
        return await handleForageResponse(APIHandler.request(queryPath, "GET"));
      },
      reimbursements: async (
        teamMemberId: string,
        params: ForageRequest
      ): Promise<ForageResponse<AggregatedExpenseReimbursement>> => {
        const q = encodeURIComponent(serialize(params));
        const queryPath = `/needs-attention/${teamMemberId}/reimbursements/?q=${q}`;
        return await handleForageResponse(APIHandler.request(queryPath, "GET"));
      },
      timesheets: async (
        teamMemberId: string,
        params: ForageRequest
      ): Promise<ForageResponse<AggregatedTimesheet>> => {
        const q = encodeURIComponent(serialize(params));
        const queryPath = `/needs-attention/${teamMemberId}/timesheets/?q=${q}`;
        return await handleForageResponse(APIHandler.request(queryPath, "GET"));
      },
      timeOffRequests: async (
        teamMemberId: string,
        params: ForageRequest
      ): Promise<ForageResponse<AggregatedTimeOffRequest>> => {
        const q = encodeURIComponent(serialize(params));
        const queryPath = `/needs-attention/${teamMemberId}/time-off-requests/?q=${q}`;
        return await handleForageResponse(APIHandler.request(queryPath, "GET"));
      },
      fillableDocuments: async (input: { teamMemberId: string }): Promise<AggregatedFile[]> => {
        return await APIHandler.request("/needs-attention/fillable-documents", "POST", input);
      },
    },
  },
  approvable_items: {
    get_approver_group: async (
      itemType: string,
      itemId: string,
      teamMemberId: string
    ): Promise<ApproverGroup> => {
      const queryPath = `/approvable-items/${itemType}/${itemId}/approver-group/${teamMemberId}`;
      return await APIHandler.request(queryPath, "GET");
    },
    kick_back: async (params: KickBackItemsParams): Promise<TakeActionOnItemsResponse & MiterError> => {
      const queryPath = `/approvable-items/kick-back`;
      return await APIHandler.request(queryPath, "POST", params);
    },
  },
  job_postings: {
    forage: async (params: ForageRequest): Promise<ForageResponse<AggregatedJobPosting>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/job-postings/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
    create: async (body: CreateJobPostingParams): Promise<AggregatedJobPosting & MiterError> => {
      return await APIHandler.request("/job-postings", "POST", body);
    },
    update: async (id: string, body: UpdateJobPostingParams): Promise<AggregatedJobPosting & MiterError> => {
      return await APIHandler.request("/job-postings/" + id, "PATCH", body);
    },
    archive: async (body: { ids: string[]; company_id: string }): Promise<ArchiveResponse & MiterError> => {
      return await APIHandler.request("/job-postings/archive", "DELETE", body);
    },
    jotai_retrieve: async (filterArray: MiterFilterArray): Promise<AggregatedJobPosting[] & MiterError> => {
      const queryPath = "/job-postings/retrieve-many";
      return await APIHandler.request(queryPath, "POST", filterArray);
    },
  },
  job_applications: {
    forage: async (params: ForageRequest): Promise<ForageResponse<AggregatedJobApplication>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/job-applications/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },

    update: async (payload: BulkUpdateParams<UpdateJobApplicationParams>): Promise<BulkUpdateResult> => {
      return await APIHandler.request("/job-applications/", "PATCH", payload);
    },
    archive: async (body: { ids: string[]; company_id: string }): Promise<ArchiveResponse & MiterError> => {
      return await APIHandler.request("/job-applications/archive", "DELETE", body);
    },
    import: async (payload: ImportJobApplicationsParams): Promise<ImportResult & MiterError> => {
      return await APIHandler.request("/job-applications/import", "POST", payload);
    },
  },
  candidates: {
    retrieve_many: async (filter: MiterFilterArray): Promise<AggregatedCandidate[] & MiterError> => {
      return await APIHandler.request("/candidates/retrieve-many", "POST", filter);
    },
    forage: async (params: ForageRequest): Promise<ForageResponse<Candidate>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/candidates/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
  },
  policies: {
    create_or_update: async (body: PolicyCreationParams): Promise<Policy & MiterError> => {
      return await APIHandler.request("/policies", "PUT", body);
    },
    search: async (body: MiterQueryObject): Promise<Policy[] & MiterError> => {
      return await APIHandler.request("/policies/search", "POST", body);
    },
    archive: async (id: string): Promise<Policy & MiterError> => {
      return await APIHandler.request("/policies/" + id, "DELETE");
    },
  },

  screenings: {
    forage: async (params: ForageRequest): Promise<ForageResponse<Screening>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/screenings/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
    create: async (body: CreateScreeningParams): Promise<Screening & MiterError> => {
      return await APIHandler.request("/screenings", "POST", body);
    },
    packages: async (body: {
      company_id: string;
    }): Promise<
      {
        packages: CheckrPackage[];
        nodes: HierarchicalNode[];
      } & MiterError
    > => {
      return await APIHandler.request("/screenings/packages", "POST", body);
    },
  },

  onboarding_checklists: {
    retrieve_many: async (filter: MiterFilterArray): Promise<OnboardingChecklist[] & MiterError> => {
      return await APIHandler.request("/onboarding-checklists/retrieve-many", "POST", filter);
    },
    create: async (body: CreateOnboardingChecklistParams): Promise<OnboardingChecklist & MiterError> => {
      return await APIHandler.request("/onboarding-checklists", "POST", body);
    },
    update: async (
      id: string,
      body: UpdateOnboardingChecklistParams
    ): Promise<OnboardingChecklist & MiterError> => {
      return await APIHandler.request("/onboarding-checklists/" + id, "PATCH", body);
    },
    archive: async (body: { ids: string[]; company_id: string }): Promise<ArchiveResponse & MiterError> => {
      return await APIHandler.request("/onboarding-checklists/archive", "DELETE", body);
    },
  },

  onboarding_checklist_items: {
    retrieve: async (id: string): Promise<AggregatedOnboardingChecklistItem & MiterError> => {
      const queryPath = "/onboarding-checklist-items/" + id;
      return await APIHandler.request(queryPath, "GET");
    },

    update: async (
      id: string,
      update: UpdateOnboardingChecklistItemParams
    ): Promise<AggregatedOnboardingChecklistItem & MiterError> => {
      const queryPath = "/onboarding-checklist-items/" + id;
      return await APIHandler.request(queryPath, "PATCH", update);
    },

    archive: async (id: string): Promise<OnboardingChecklistItem & MiterError> => {
      return await APIHandler.request("/onboarding-checklist-items/" + id, "DELETE");
    },

    update_team_member: async (params: { tm_id: string; checklist_item_id: string }): Promise<MiterError> => {
      return await APIHandler.request(
        "/onboarding-checklist-items/update-team-member-with-milestone-statuses",
        "POST",
        params
      );
    },

    assign: async (params: {
      tm_id: string;
      checklist_id: string;
    }): Promise<OnboardingChecklistItem & MiterError> => {
      return await APIHandler.request("/onboarding-checklist-items/assign", "POST", params);
    },

    completion_data: async (params: {
      new_hire_id: string;
      assignee_user_id: string;
      checklist_id: string;
    }): Promise<OnboardingChecklistCompletionData & MiterError> => {
      return await APIHandler.request("/onboarding-checklist-items/completion-data", "POST", params);
    },

    list: async (filter: MiterFilterArray): Promise<AggregatedOnboardingChecklistItem[] & MiterError> => {
      return await APIHandler.request("/onboarding-checklist-items/list", "POST", { filter });
    },

    remind_of_task: async (params: {
      assignee_user_id: string;
      checklist_item_id: string;
      task_id: string;
      new_hire_user_id: string;
    }): Promise<MiterError> => {
      return await APIHandler.request("/onboarding-checklist-items/remind-of-task", "POST", params);
    },
  },

  mitos: {
    impersonate_user: async (
      user: string,
      authToken: string
    ): Promise<DashboardAuthenticatedUserData & MiterError> => {
      const queryPath = "/mitos/impersonate-user";
      return await APIHandler.request(queryPath, "POST", { user, app: "dashboard" }, { authToken });
    },
  },
  audit_logs: {
    get_for_item: async (item_id: string): Promise<AuditLog[] & MiterError> => {
      return await APIHandler.request("/audit-logs/" + item_id, "GET");
    },
  },
  certification_types: {
    create: async (body: CreateCertificationTypeParams): Promise<CertificationType & MiterError> => {
      return await APIHandler.request("/certification-types", "POST", body);
    },
    update: async (
      id: string,
      body: UpdateCertificationTypeParams
    ): Promise<CertificationType & MiterError> => {
      return await APIHandler.request("/certification-types/" + id, "PATCH", body);
    },
    retrieve_many: async (filter: MiterFilterArray): Promise<AggregatedCertificationType[] & MiterError> => {
      return await APIHandler.request("/certification-types/retrieve-many", "POST", { filter });
    },

    archive: async (ids: string[]): Promise<ArchiveResponse & MiterError> => {
      return await APIHandler.request("/certification-types", "DELETE", { ids });
    },

    retrieve: async (id: string): Promise<AggregatedCertificationType & MiterError> => {
      return await APIHandler.request("/certification-types/" + id, "GET");
    },
  },
  certifications: {
    create: async (
      body: CreateCertificationParams[]
    ): Promise<BulkCreateResponse<Certification> & MiterError> => {
      return await APIHandler.request("/certifications", "POST", body);
    },
    update: async (id: string, body: UpdateCertificationParams): Promise<Certification & MiterError> => {
      return await APIHandler.request("/certifications/" + id, "PATCH", body);
    },
    forage: async (params: ForageRequest): Promise<ForageResponse<AggregatedCertification>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/certifications/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
    retrieve: async (id: string): Promise<AggregatedCertification & MiterError> => {
      return await APIHandler.request("/certifications/" + id, "GET");
    },
    archive: async (params: { company_id: string; ids: string[] }): Promise<ArchiveResponse & MiterError> => {
      return await APIHandler.request("/certifications/", "DELETE", params);
    },
  },
  change_requests: {
    create: async (body: CreateChangeRequestParams): Promise<ChangeRequest & MiterError> => {
      const queryPath = "/change-requests/";
      return await APIHandler.request(queryPath, "POST", body);
    },
    retrieve: async (id: string): Promise<AggregatedChangeRequest & MiterError> => {
      const queryPath = "/change-requests/" + id;
      return await APIHandler.request(queryPath, "GET");
    },
    retrieve_many: async (filter: MiterFilterArray): Promise<AggregatedChangeRequest[] & MiterError> => {
      const queryPath = "/change-requests/?" + qs.stringify(filter);
      return await APIHandler.request(queryPath, "GET");
    },
    update: async (id: string, body: Partial<ChangeRequest>): Promise<ChangeRequest & MiterError> => {
      const queryPath = "/change-requests/" + id;
      return await APIHandler.request(queryPath, "PATCH", body);
    },
    update_many: async (p: {
      ids: string[];
      update: Partial<ChangeRequest>;
    }): Promise<BulkUpdateResult & MiterError> => {
      const queryPath = "/change-requests/multiple";
      return await APIHandler.request(queryPath, "PATCH", p);
    },
    delete_many: async (ids: string[]): Promise<BulkUpdateResult & MiterError> => {
      const queryPath = "/change-requests/multiple";
      return await APIHandler.request(queryPath, "DELETE", { ids });
    },
    forage: async (params: ForageRequest): Promise<ForageResponse<AggregatedChangeRequest>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/change-requests/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
  },
  bank_accounts: {
    forage: async (params: ForageRequest): Promise<ForageResponse<BankAccount>> => {
      const q = encodeURIComponent(serialize(params));
      const queryPath = "/bank-accounts/forage?q=" + q;
      return await handleForageResponse(APIHandler.request(queryPath, "GET"));
    },
    reveal_full_account_number: async (id: string): Promise<{ account_number: string } & MiterError> => {
      const queryPath = `/bank-accounts/${id}/account-number`;
      return await APIHandler.request(queryPath, "GET");
    },
    create: async (body: CreateRawBankAccountParams): Promise<BankAccount & MiterError> => {
      const queryPath = "/bank-accounts/";
      return await APIHandler.request(queryPath, "POST", body);
    },
    archive: async (id: string): Promise<BankAccount & MiterError> => {
      const queryPath = "/bank-accounts/" + id;
      return await APIHandler.request(queryPath, "DELETE");
    },
    update: async (id: string, update: DeepPartial<BankAccount>): Promise<BankAccount & MiterError> => {
      const queryPath = "/bank-accounts/" + id;
      return await APIHandler.request(queryPath, "PATCH", update);
    },
  },
  fillable_templates: {
    create: async (body: CreateFillableTemplateParams): Promise<AggregatedFillableTemplate & MiterError> => {
      return await APIHandler.request("/fillable-templates", "POST", body);
    },
    update: async (body: {
      id: string;
      update: UpdateFillableTemplateParams;
    }): Promise<AggregatedFillableTemplate & MiterError> => {
      return await APIHandler.request(`/fillable-templates/${body.id}`, "PATCH", body.update);
    },

    limit: async (body: { id: string }): Promise<AggregatedFillableTemplate & MiterError> => {
      return await APIHandler.request(`/fillable-templates/limit/${body.id}`, "PATCH");
    },
    retrieve_many: async (filter: MiterFilterArray): Promise<AggregatedFillableTemplate[] & MiterError> => {
      return await APIHandler.request("/fillable-templates/list/", "POST", { filter });
    },
    archive: async (params: { company_id: string; ids: string[] }): Promise<ArchiveResponse & MiterError> => {
      return await APIHandler.request("/fillable-templates/", "DELETE", params);
    },
    template_editor_link: async (params: { id: string }): Promise<GenerateEmbedURLResponse & MiterError> => {
      return await APIHandler.request("/fillable-templates/editor-link", "POST", params);
    },
    template_inputs: async (params: { id: string }): Promise<FetchAnvilFieldsResponse & MiterError> => {
      return await APIHandler.request(`/fillable-templates/inputs/${params.id}`, "GET");
    },
  },
  custom_tasks: {
    create: async (params: CreateCustomTaskParams): Promise<CustomTask & MiterError> => {
      return await APIHandler.request("/custom-tasks", "POST", params);
    },
    retrieve: async (id: string): Promise<CustomTask & MiterError> => {
      return await APIHandler.request("/custom-tasks/" + id, "GET");
    },
    update: async (id: string, params: UpdateCustomTaskParams): Promise<CustomTask & MiterError> => {
      return await APIHandler.request("/custom-tasks/" + id, "PATCH", params);
    },
    delete: async (id: string): Promise<CustomTask & MiterError> => {
      return await APIHandler.request("/custom-tasks/" + id, "DELETE");
    },
    search: async (filter: MiterFilterArray): Promise<CustomTask[] & MiterError> => {
      return await APIHandler.request("/custom-tasks/search", "POST", { filter });
    },
  },
  // Onboarding checklists 2.0
  team_member_onboarding_checklists: {
    create: async (
      body: CreateTeamMemberOnboardingChecklistParams
    ): Promise<TeamMemberOnboardingChecklist & MiterError> => {
      return await APIHandler.request("/team-member-onboarding-checklists", "POST", body);
    },
    retrieve: async (id: string): Promise<TeamMemberOnboardingChecklist & MiterError> => {
      return await APIHandler.request("/team-member-onboarding-checklists/" + id, "GET");
    },
    retrieve_default_tasks: async (
      team_member_id: string
    ): Promise<TeamMemberOnboardingTask[] & MiterError> => {
      return await APIHandler.request(
        "/team-member-onboarding-checklists/default-tasks?team_member_id=" + team_member_id,
        "GET"
      );
    },
    update: async (
      id: string,
      params: UpdateTeamMemberOnboardingChecklistParams
    ): Promise<TeamMemberOnboardingChecklist & MiterError> => {
      return await APIHandler.request("/team-member-onboarding-checklists/" + id, "PATCH", params);
    },
    update_tasks: async (
      id: string,
      params: { updatedTasks: TeamMemberOnboardingTask[] }
    ): Promise<TeamMemberOnboardingChecklist & MiterError> => {
      return await APIHandler.request("/team-member-onboarding-checklists/" + id + "/tasks", "PATCH", params);
    },
    delete: async (id: string): Promise<TeamMemberOnboardingChecklist & MiterError> => {
      return await APIHandler.request("/team-member-onboarding-checklists/" + id, "DELETE");
    },
    search: async (
      filter: MiterFilterArray,
      getAggregatedChecklists?: boolean
    ): Promise<
      (AggregatedTeamMemberOnboardingChecklist[] | TeamMemberOnboardingChecklist[]) & MiterError
    > => {
      return await APIHandler.request("/team-member-onboarding-checklists/search", "POST", {
        filter,
        getAggregatedChecklists,
      });
    },
  },
  offer_templates: {
    create: async (body: CreateOfferTemplateParams): Promise<OfferTemplate & MiterError> => {
      return await APIHandler.request("/offer-templates", "POST", body);
    },
    update: async (id: string, params: UpdateOfferTemplateParams): Promise<OfferTemplate & MiterError> => {
      return await APIHandler.request("/offer-templates/" + id, "PATCH", params);
    },
    archive: async (params: { company_id: string; ids: string[] }): Promise<ArchiveResponse & MiterError> => {
      return await APIHandler.request("/offer-templates/", "DELETE", params);
    },
    list: async (filter: MiterFilterArray): Promise<OfferTemplate[] & MiterError> => {
      return await APIHandler.request("/offer-templates/list", "POST", { filter });
    },
  },
  offer_letters: {
    create: async (body: CreateOfferLetterParams): Promise<OfferLetter & MiterError> => {
      return await APIHandler.request("/offer-letters", "POST", body);
    },
    retrieve: async (id: string): Promise<OfferLetter & MiterError> => {
      return await APIHandler.request("/offer-letters/" + id, "GET");
    },
    update: async (id: string, params: UpdateOfferLetterParams): Promise<OfferLetter & MiterError> => {
      return await APIHandler.request("/offer-letters/" + id, "PATCH", params);
    },
    archive: async (params: { company_id: string; ids: string[] }): Promise<ArchiveResponse & MiterError> => {
      return await APIHandler.request("/offer-letters", "DELETE", params);
    },
    list: async (filter: MiterFilterArray): Promise<OfferLetter[] & MiterError> => {
      return await APIHandler.request("/offer-letters/list", "POST", { filter });
    },
    remind: async (id: string): Promise<OfferLetter & MiterError> => {
      return await APIHandler.request("/offer-letters/" + id + "/remind", "POST");
    },
  },
  public_offer_letters: {
    retrieve_with_anvil_link: async (id: string): Promise<OfferLetterWithAnvilLink & MiterError> => {
      return await APIHandler.request("/public-offer-letters/" + id, "GET");
    },
    mark_as_viewed: async (id: string): Promise<OfferLetter & MiterError> => {
      return await APIHandler.request("/public-offer-letters/" + id + "/viewed", "POST");
    },
    mark_as_signed: async (id: string): Promise<OfferLetter & MiterError> => {
      return await APIHandler.request("/public-offer-letters/" + id + "/signed", "POST");
    },
  },
};

type SendMagicLinkResponse = { success: true } & MiterError;

export type BaseAuthenticatedUserData = {
  user: User;
  activeTeamMember?: AggregatedTeamMember;
  availableTeamMembers?: AggregatedTeamMember[];
  authToken?: string;
};

type CurrentSessionResponse = DashboardAuthenticatedUserData;

type DeleteCurrentSessionResponse = {
  error?: string;
  success?: boolean;
};

type UpdateActiveRoleResponse = {
  error?: string;
  roles: AggregatedRole[];
  authToken: string;
};

type RetrieveCompanyRolesResponse = UpdateActiveRoleResponse;

type CreateRoleResponse = {
  error?: string;
  role: Role;
};

type CreateCompanyResponse = Company & {
  error?: string;
};

type CreateCompanyBody = {
  trade_name: string;
  legal_name: string;
  phone: string;
  address: Address;
  signer_first: string;
  signer_last: string;
  signer_email: string;
  signer_type: string;
  email?: string;
};

export type FormAddress = {
  line1: string;
  line2?: string | null | undefined;
  city: string;
  state: Option<string>;
  postal_code: string;
};

const authPaths = [
  "/users/send-magic-link",
  "/users/send-auth-code",
  "/users/authenticate",
  "/sessions/current",
  "/sessions/update",
];

/**************************************************************************
 * This is a basic class we built to wrap fetch into a cleaner class that
 * we could reuse above.
 ***************************************************************************/

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

class APIHandler {
  static async request(
    path: string,
    method: Method,
    body?: $TSFixMe,
    opts?: { authToken?: string }
  ): Promise<$TSFixMe> {
    // Get the auth token that was passed in (if impersonating) or from the defaultAtomStore (if dashboard) or from localStorage (if team portal)
    const authToken =
      opts?.authToken || defaultAtomStore.get(authTokenAtom) || localStorage.getItem("authToken");

    const baseUrl =
      (path.startsWith("/reports") && process.env.REACT_APP_MITER_BACKEND_REPORTS_API) ||
      process.env.REACT_APP_MITER_BACKEND_API;

    const res = await fetch(baseUrl + path, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authToken}`,
        "client-service": "dashboard",
        "client-release-name": process.env.REACT_APP_SENTRY_RELEASE_NAME || "development-release",
      },
      ...(body ? { body: JSON.stringify(body) } : {}),
    });

    // Give the error time to show up in the Notifier.error before redirecting
    if (!authPaths.includes(path) && res.status === 401) {
      defaultAtomStore.set(hitUnauthorizedErrorAtom, true);
    }

    detectNewVersion(res.headers.get("miter-release"));

    if (this.isJSON(res)) {
      const json = await res.json();
      if (json.error) json.error_status = res.status;
      return json;
    }

    return res;
  }

  static isJSON(response: Response): boolean {
    const content_type = response.headers.get("content-type");
    return !!(content_type && content_type.slice(0, 16) === "application/json");
  }
}

const isOfficialVersionRelease = (s: string | undefined | null) => {
  return s?.startsWith("miter@v") && s.length < 25;
};

const isMinorOrMajorRelease = (dashboardRelease: string, backendRelease: string): boolean => {
  // Sometimes the release name uses the commit hash instead of the tag in which case we can't parse it and should assume it's a patch
  if (!isOfficialVersionRelease(dashboardRelease)) return false;
  if (!isOfficialVersionRelease(backendRelease)) return false;

  const [dMaj, dMin] = dashboardRelease.slice(7).split(".");
  const [bMaj, bMin] = backendRelease.slice(7).split(".");
  if (dMaj !== bMaj) return true;
  if (dMin !== bMin) return true;
  return false;
};

const detectNewVersion = (backendRelease: string | null) => {
  // `newReleaseAvailableAtom` is set to true immediately the first time we see a new release is available
  // We then set a timer for 7 minutes to set `showNewReleaseModalAtom` to true to give the frontend time to deploy since it takes 7 minutes longer than the backend
  // This means we only ever set one timer instead of setting dozens of timers if the user makes many requests during those 7 minutes
  const dashboardRelease = process.env.REACT_APP_SENTRY_RELEASE_NAME;

  // Do nothing if...
  // versions aren't set
  if (!backendRelease || !dashboardRelease) return;
  // version is not a major or minor upgrade
  if (!isMinorOrMajorRelease(dashboardRelease, backendRelease)) return;
  // new release modal has already been triggered
  if (defaultAtomStore.get(newReleaseAvailableAtom)) return;

  defaultAtomStore.set(newReleaseAvailableAtom, true);
  const user = defaultAtomStore.get(userAtom);
  if (!user || user.miter_admin) console.log("Setting timer to show new release modal");
  sleep(7 * 60 * 1000).then(() => defaultAtomStore.set(showNewReleaseModalAtom, true));
};
