import { Formblock, Notifier, SettingsCard } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";
import { MiterGuidesIcon } from "dashboard/components/MiterGuidesIcon";
import { useActiveCompany, useTimesheetPolicyOptions } from "dashboard/hooks/atom-hooks";
import Banner from "dashboard/components/shared/Banner";
import { PoliciesTable } from "dashboard/components/policies/PoliciesTable";

interface TimesheetPolicySettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => Promise<void>;
}

const TimesheetPolicySettings: React.FC<TimesheetPolicySettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  const activeCompany = useActiveCompany();
  const timesheetPolicyOptions = useTimesheetPolicyOptions();
  const hasPoliciesEnabled = timesheetSettings.enable_timesheet_policies;
  const hasTimesheetPolicies = timesheetPolicyOptions.length > 0;
  const hasDefaultTimesheetPolicy = timesheetSettings.default_policy_id;
  const hasSubjobsEnabled = activeCompany?.settings?.jobs?.enabled_sub_jobs;
  return (
    <>
      <SettingsCard
        title={
          <div className="flex">
            Timesheet policies
            <MiterGuidesIcon path="/time-tracking/timesheets/timesheet-policies-beta" marginLeft={5} />
          </div>
        }
        info={
          "Setup timesheet policies to customize who should approve a timesheet, what fields should be shown/required on a timesheet, and add sign off requirements to timesheets."
        }
      >
        {hasPoliciesEnabled && !hasTimesheetPolicies && (
          <>
            <Banner
              type="warning"
              content={
                "You have turned on timesheet policies but you have not created any policies. If you don't create a default timesheet policy, your employees will not be able to create and approve timesheets as expected."
              }
            />
            <div className="vertical-spacer-small" />
          </>
        )}

        <Formblock
          type="checkbox"
          name="enable_timesheet_policies"
          text="Enable timesheet policies"
          onChange={(e) => {
            // If there are no policies, don't allow the user to enable timesheet policies and show a warning
            if (!hasTimesheetPolicies || !hasDefaultTimesheetPolicy) {
              Notifier.error("You must create & add default policy before enabling timesheet policies.");
              return;
            }

            handleChange({ enable_timesheet_policies: e.target.checked });
          }}
          checked={timesheetSettings.enable_timesheet_policies}
          editing={true}
          style={{ zIndex: 2, width: "50%" }}
          labelInfo="If enabled, timesheet policies will be used instead of your current field/approval settings"
        />
        <hr style={{ border: "1px solid #eee", borderBottom: "none", marginTop: 20 }} />

        <Formblock
          type="select"
          name="default_policy_id"
          label="Default policy"
          placeholder="Select a default policy"
          options={timesheetPolicyOptions}
          onChange={(o) => handleChange({ default_policy_id: o?.value || null })}
          defaultValue={timesheetSettings.default_policy_id}
          editing={true}
          labelStyle={{ marginRight: 0 }}
          style={{ width: 350, marginBottom: -60, marginTop: 25 }}
        />

        <PoliciesTable policyType="timesheet" />
      </SettingsCard>
      {hasSubjobsEnabled && (
        <SettingsCard title="Field requirements options">
          <Formblock
            type="checkbox"
            name="require_sub_job_selection"
            text="Require subjob selection during clock in if the timesheet policy requires the job field"
            editing={true}
            defaultValue={timesheetSettings.require_sub_job_selection}
            onChange={(e) => handleChange({ require_sub_job_selection: e.target.checked })}
          />
        </SettingsCard>
      )}
    </>
  );
};

export default TimesheetPolicySettings;
