import React, { FC } from "react";
import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react";
import { SmartField } from "dashboard/types/offers-types";

export type BlobInfo = {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
};

/** Constants */
const familyFonts =
  "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats";
const plugins = [
  "preview",
  "advlist",
  "autolink",
  "lists",
  "link",
  "image",
  "charmap",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "wordcount",
  "format",
  "mergetags",
  "mergetags-customisation",
  "powerpaste",
  "help",
];
const DEFAULT_TOOLBAR =
  "mergetags image link | blocks | " +
  "bold italic forecolor | alignleft aligncenter " +
  "alignright alignjustify | bullist numlist outdent indent | " +
  "table tabledelete | tableprops tablerowprops tablecellprops | " +
  "preview";

export type EditorProps = {
  smartFields: { value: SmartField; title: string }[];
  defaultHTML?: string;
  editorRef: React.MutableRefObject<TinyMCEEditor | undefined>;
  toolbar?: string;
  handleImageUpload: (blob: BlobInfo) => Promise<string>;
  height?: number;
  disabled?: boolean;
};

const Editor: FC<EditorProps> = ({
  smartFields,
  defaultHTML,
  editorRef,
  handleImageUpload,
  height,
  toolbar,
  disabled,
}) => {
  const finalToolbar = toolbar || DEFAULT_TOOLBAR;

  const setup = (editor) => {
    // We must be careful in referring to state here as it will be fixed
    // to the value it was when the editor displayed.
    editor.editorManager.PluginManager.add("mergetags-customisation", (editor) => {
      const original = editor.ui.registry.getAll()["buttons"]["mergetags"];

      editor.ui.registry.addMenuButton("mergetags", {
        ...original,
        text: "Smart tokens",
        icon: null,
        search: false,
      });
    });
  };

  return (
    <TinyMCEEditor
      apiKey={process.env.REACT_APP_TINYMCE_EDITOR_API_KEY}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onInit={(_evt, editor) => (editorRef.current = editor)}
      initialValue={defaultHTML}
      init={{
        height: height || 500,
        font_family_formats: familyFonts,
        menubar: "format",
        plugins,
        paste_data_images: true,
        mergetags_list: smartFields,
        branding: false,
        toolbar: finalToolbar,
        images_upload_handler: handleImageUpload,
        setup,
      }}
      disabled={!!disabled}
    />
  );
};

export default Editor;
