import React from "react";
import { SettingsCard, Formblock } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { DeepPartial } from "utility-types";
import { IntegrationConnection } from "dashboard/miter";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { useForm } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { Option } from "ui/form/Input";
import {
  BuildOpsConnectionMetadata,
  BuildOpsJobStatus,
  BuildOpsProjectStatus,
} from "backend/services/buildops/buildops-types";
import { IntegrationEarningTypeConfig } from "../IntegrationEarningTypeConfig";
import { expandedEarningTypeOptions } from "dashboard/pages/timesheets/TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { TimesheetEarningType } from "backend/models/timesheet";

export const BUILDOPS_INPUT_LENGTH = 250;
export const DEFAULT_BUILDOPS_LABEL_STYLE = { maxWidth: 230 };

const jobActiveStatuses: BuildOpsJobStatus[] = [
  "Overdue",
  "Open",
  "Scheduled",
  "Unscheduled",
  "In Progress",
  "On Hold",
  "Completed",
];
const projectActiveStatuses: BuildOpsProjectStatus[] = [
  "Bid",
  "Accepted",
  "Work In Progress",
  "Work Complete",
  "Closed",
  "Void",
  "On Hold",
];
const jobStatusOptions = jobActiveStatuses.map((value) => ({ label: value, value }));
const projectStatusOptions = projectActiveStatuses.map((value) => ({ label: value, value }));

export const BuildOpsConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();

  const { tenantId, jobsSync } = integration.connection?.metadata?.buildops || {};
  const { earningTypeMapping } = integration.connection?.metadata?.buildops?.timesheets || {};
  const { defaultCPRProjectNumber, activeJobStatuses, pullJobAddresses, activeProjectStatuses } =
    jobsSync || {};

  const updateTenantId = async (value: string) => {
    const update = {
      tenantId: value,
    };

    await updateBuildOpsMetadata(update);
  };

  const updateDefaultCPRInfo = async (value: string) => {
    const update = {
      jobsSync: { defaultCPRProjectNumber: value },
    };

    await updateBuildOpsMetadata(update);
  };

  const debouncedTenantIdUpdate = useDebouncedCallback(updateTenantId, 1000);
  const debouncedDefaultCPRInfoUpdate = useDebouncedCallback(updateDefaultCPRInfo, 1000);

  const updateBuildOpsMetadata = async (
    update: DeepPartial<BuildOpsConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => {
    const raw: DeepPartial<IntegrationConnection> = { metadata: { buildops: update } };
    const collapseCount = opts?.collapseCount;
    const flattened = buildAtomicMongoUpdateFromNested(raw, {
      collapseCount: collapseCount != null ? collapseCount + 2 : undefined,
    });

    await updateIntegrationConnection(flattened);
  };

  const saveEarningTypeCallback = async (newEarningTypeMap: Record<string, TimesheetEarningType>) => {
    await updateBuildOpsMetadata(
      {
        timesheets: { earningTypeMapping: newEarningTypeMap },
      },
      { collapseCount: 2 }
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Timesheets">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: DEFAULT_BUILDOPS_LABEL_STYLE }}
          name="tenantId"
          type="text"
          labelInfo="Your Tenant ID (provided by BuildOps)."
          label="Tenant ID"
          defaultValue={tenantId}
          onChange={(e) => debouncedTenantIdUpdate(e.target.value)}
          labelStyle={DEFAULT_BUILDOPS_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Jobs">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: DEFAULT_BUILDOPS_LABEL_STYLE }}
          name="pullJobAddresses"
          type="checkbox"
          labelInfo="When pulling jobs from BuildOps, pull the addresses for the jobs."
          label="Pull Job Addresses"
          defaultValue={!!pullJobAddresses}
          onChange={(e) =>
            updateBuildOpsMetadata({
              jobsSync: {
                pullJobAddresses: e.target.checked,
              },
            })
          }
          labelStyle={DEFAULT_BUILDOPS_LABEL_STYLE}
          editing={true}
        />
        <Formblock
          form={form}
          options={jobStatusOptions}
          name="activeJobStatusValues"
          type="multiselect"
          defaultValue={activeJobStatuses}
          onChange={(options: Option<BuildOpsJobStatus>[] | null) => {
            const statuses = options?.map((o) => o.value);
            updateBuildOpsMetadata({ jobsSync: { activeJobStatuses: statuses } });
          }}
          label="Active Job Statuses"
          editing={true}
          labelInfo="BuildOps Job statuses Miter will consider to be 'Active' when syncing jobs"
          underlineTooltip
          isClearable
          inputProps={{ style: DEFAULT_BUILDOPS_LABEL_STYLE }}
        />
        <Formblock
          form={form}
          options={projectStatusOptions}
          name="activeProjectStatusValues"
          type="multiselect"
          defaultValue={activeProjectStatuses}
          onChange={(options: Option<BuildOpsProjectStatus>[] | null) => {
            const statuses = options?.map((o) => o.value);
            updateBuildOpsMetadata({ jobsSync: { activeProjectStatuses: statuses } });
          }}
          label="Active Project Statuses"
          editing={true}
          labelInfo="BuildOps Project statuses Miter will consider to be 'Active' when syncing jobs"
          underlineTooltip
          isClearable
          inputProps={{ style: DEFAULT_BUILDOPS_LABEL_STYLE }}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: DEFAULT_BUILDOPS_LABEL_STYLE }}
          name="defaultCPRProjectNumber"
          type="text"
          labelInfo="The default value to use for the CPR Project Number (dummy value) when syncing jobs."
          label="Default CPR Project Number"
          defaultValue={defaultCPRProjectNumber}
          onChange={(e) => debouncedDefaultCPRInfoUpdate(e.target.value)}
          labelStyle={DEFAULT_BUILDOPS_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Earning type mapping">
        <IntegrationEarningTypeConfig
          saveEarningTypeCallback={saveEarningTypeCallback}
          earningTypeMapping={earningTypeMapping}
          earningTypeOptions={expandedEarningTypeOptions}
        />
      </SettingsCard>
    </div>
  );
};
