import InfoButton from "dashboard/components/information/information";
import { useActiveCompany, useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { set } from "lodash";
import { CodeSimple, WarningCircle } from "phosphor-react";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Formblock, IconWithTooltip, SettingsCard } from "ui";
import styles from "./Ats.module.css";

export const RecruitingSettings: React.FC = () => {
  /** Navigation  */
  const navigate = useNavigate();

  /** Atom hooks  */
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();

  /** Form management */
  const form = useForm();

  /** State management */
  const [recruitingSettings, setRecruitingSettings] = useState(activeCompany?.settings.ats);

  /* Constants */

  const iframeSnippet = `<iframe
  src={"${process.env.REACT_APP_CAREER_PAGE_URL}/${activeCompany?.slug}?iframe=true"}
  style={{
    width: "100%",
    height: 700,
    border: "2px solid rgb(221, 221, 221)",
    borderRadius: 5,
  }}
  scrolling="no"
></iframe>`;

  /** Handlers + utility functions */

  const copyIframeURL = () => {
    navigator.clipboard.writeText(iframeSnippet);
    Notifier.success("Successfully copied iframe snippet to clipboard!");
  };

  const careerPageTitle = () => {
    const hasWebsite = !!activeCompany?.website_url;
    if (hasWebsite) {
      return "Career page";
    }

    return (
      <div className="flex">
        <div>Career page</div>
        <IconWithTooltip
          PhosphorIcon={WarningCircle}
          tooltip={"Please add your company's website from the Company tab of the Settings page."}
          size={14}
          place="right"
          style={{ color: "#CB3331", marginLeft: 5 }}
          weight="fill"
        />
      </div>
    );
  };

  /** Saves the recruiting settings to the backend */

  const handleChange = async (input: { path: string; value: string | boolean }) => {
    if (!activeCompanyId) {
      Notifier.error("No active company found. Please refresh the page and try again.");
      return;
    }
    const { path, value } = input;
    const updatedSettings = { ...recruitingSettings };
    set(updatedSettings, path, value);

    setRecruitingSettings(updatedSettings);

    try {
      await MiterAPI.companies.update(activeCompanyId, {
        $set: { "settings.ats": updatedSettings },
      });
      Notifier.success("Settings updated successfully!");
    } catch (error: $TSFixMe) {
      Notifier.error(`Failed to update settings: ${error.message}`);
    }
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Recruiting settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={() => navigate("/recruiting/job-postings")}>
          Back to recruiting
        </div>
        <div className="flex">
          <h1>Recruiting settings</h1>
          <div className="flex-1"></div>
        </div>
        <SettingsCard title={"Notifications"}>
          <div className={styles["settings-card-content"]}>
            <div className="flex" style={{ alignItems: "flex-start" }}>
              <input
                type="checkbox"
                name="notifications.new_application.hiring_manager"
                checked={recruitingSettings?.notifications?.new_application?.hiring_manager}
                onChange={(e) =>
                  handleChange({
                    path: "notifications.new_application.hiring_manager",
                    value: !!e.target.checked,
                  })
                }
              />
              <span style={{ marginLeft: 10 }}>
                <strong>Hiring manager: </strong>
                {`should receive a notification for each new job application.`}
              </span>
            </div>
            <div className="flex" style={{ alignItems: "flex-start", marginTop: 8 }}>
              <input
                type="checkbox"
                name="notifications.new_application.hiring_team"
                checked={recruitingSettings?.notifications?.new_application?.hiring_team}
                onChange={(e) =>
                  handleChange({
                    path: "notifications.new_application.hiring_team",
                    value: !!e.target.checked,
                  })
                }
              />
              <span style={{ marginLeft: 10 }}>
                <strong>Hiring team: </strong>
                {`should receive a notification for each new job application.`}
              </span>
            </div>
          </div>
        </SettingsCard>
        <SettingsCard title={"Job boards"}>
          <div className={styles["settings-card-content"]}>
            <div className="flex" style={{ alignItems: "flex-start" }}>
              <input
                type="checkbox"
                name="job_boards.indeed.enabled"
                checked={recruitingSettings?.job_boards?.indeed?.enabled}
                onChange={(e) =>
                  handleChange({
                    path: "job_boards.indeed.enabled",
                    value: !!e.target.checked,
                  })
                }
              />
              <span style={{ marginLeft: 10, display: "flex", alignItems: "center" }}>
                Sync recruiting jobs from Miter to the{" "}
                <b style={{ marginLeft: 4, marginRight: 4 }}> Indeed </b> job board. Candidates will be able
                to apply to your jobs through Indeed.
                <InfoButton
                  text={
                    "This (free) integration streamlines your recruitment process by automatically posting your open positions on Indeed and allowing candidates to apply directly through the platform."
                  }
                  place={"right"}
                />
              </span>
            </div>
          </div>
        </SettingsCard>
        <SettingsCard title={careerPageTitle()}>
          <div className={"flex" + " " + styles["settings-card-content"]} style={{ marginBottom: "10px" }}>
            Embed your career page into your company&apos;s website to display all open roles.
            <InfoButton
              text={
                "Share this snippet with your website manager to display open positions and accept applications directly from your company website!"
              }
              place={"right"}
            />
          </div>
          <div className="flex" style={{ alignItems: "flex-start" }}>
            <Button onClick={copyIframeURL} className="button-1" style={{ marginLeft: 2, height: 31 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CodeSimple size={16} style={{ marginRight: 5 }}></CodeSimple>Copy snippet
              </div>
            </Button>
            <div style={{ marginLeft: 8 }}>
              <Formblock
                style={{ minWidth: 500 }}
                type="text"
                defaultValue={iframeSnippet}
                editing={true}
                disabled={true}
                form={form}
              />
            </div>
          </div>
        </SettingsCard>
      </div>
    </div>
  );
};
