import {
  useActiveCompanyId,
  useActivityOptions,
  useDepartmentOptions,
  useJobNameFormatter,
  useJobOptions,
  useLocationOptions,
} from "dashboard/hooks/atom-hooks";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { MiterAPI } from "dashboard/miter";
import { isTimesheetScoped } from "dashboard/pages/activities/activityUtils";
import { isJobWFMScoped } from "dashboard/pages/jobs/jobUtils";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Notifier } from "ui";
import { Option } from "ui/form/Input";
import { JobInput } from "../shared/JobInput";

type Props = {
  selectedTimesheetIds: string[];
  onSubmit: () => void;
  hide: () => void;
};

const REMOVE = "__REMOVE__";

export const RecodeTimesheetsModal: React.FC<Props> = ({ selectedTimesheetIds, onSubmit, hide }) => {
  const integrationKeyToIntegrationMap = useHasIntegration();

  const onModalClose = () => {
    onSubmit();
    hide();
  };

  const { setFailures, renderFailuresModal } = useFailuresModal({ onClose: onModalClose });

  const hasQbOrXero =
    integrationKeyToIntegrationMap.has("qbo") ||
    integrationKeyToIntegrationMap.has("qbd") ||
    integrationKeyToIntegrationMap.has("xero");

  const jobOptions = useJobOptions({
    predicate: isJobWFMScoped,
    includeCustomOption: { label: "(none, remove job from timesheets)", value: REMOVE },
  });
  const jobNameFormatter = useJobNameFormatter();

  const form = useForm();
  const companyId = useActiveCompanyId();

  const [loading, setLoading] = useState(false);
  const [createGlEntry, setCreateGlEntry] = useState(false);
  const [selectedJob, setSelectedJob] = useState<string | undefined>();

  const activityOptions = useActivityOptions(selectedJob, {
    predicate: isTimesheetScoped,
    includeCustomOption: { label: "(none, remove activity from timesheets)", value: REMOVE },
  });

  const deptOptions = useDepartmentOptions({
    includeCustomOption: { label: "(none, remove department from timesheets)", value: REMOVE },
  });

  const locationOptions = useLocationOptions({
    includeCustomOption: { label: "(none, remove location from timesheets)", value: REMOVE },
  });

  const save = async (data) => {
    if (!companyId) return;
    setLoading(true);
    try {
      const glPostDataInput = form.getValues("gl_post_date");
      const rawActivityId = form.getValues("activity_id")?.value;
      const rawDeptId = form.getValues("department_id")?.value;
      const rawLocationId = form.getValues("location_id")?.value;
      const finalDeptId = rawDeptId === REMOVE ? null : rawDeptId || undefined;
      const finalLocationId = rawLocationId === REMOVE ? null : rawLocationId || undefined;
      const finalActivityId = rawActivityId === REMOVE ? null : rawActivityId || undefined;
      const finalJobId = selectedJob === REMOVE ? null : selectedJob || undefined;
      if (
        finalJobId === undefined &&
        finalActivityId === undefined &&
        finalDeptId === undefined &&
        finalLocationId === undefined
      ) {
        throw new Error("You must select a dimension to recode to.");
      }

      const params = {
        companyId,
        timesheetIds: selectedTimesheetIds,
        jobId: finalJobId,
        activityId: finalActivityId,
        departmentId: finalDeptId,
        locationId: finalLocationId,
        createGlEntry,
        note: (createGlEntry && data.note) || undefined,
        glPostDate: glPostDataInput
          ? DateTime.fromISO(glPostDataInput).startOf("day").plus({ hours: 6 }).toISO()
          : DateTime.now().toISO(),
      };
      const response = await MiterAPI.timesheets.recode(params);
      if (response.error) throw new Error(response.error);

      if (response.errors?.length) {
        setFailures(response.errors.map((r) => ({ label: r.label || r._id, message: r.message })));
      } else {
        onSubmit();
        hide();
      }
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const renderMainModal = () => (
    <ActionModal
      headerText={`Recode timesheet(s)`}
      onHide={hide}
      showCancel={true}
      bodyStyle={{ overflow: "visible", maxHeight: "100%" }}
      onCancel={hide}
      showSubmit={true}
      onSubmit={form.handleSubmit(save)}
      submitText={"Submit"}
      loading={loading}
    >
      <div>
        <div style={{ height: 15 }}></div>
        <JobInput
          label="New job"
          className="modal"
          type="select"
          form={form}
          options={jobOptions}
          value={selectedJob ? { label: jobNameFormatter(selectedJob), value: selectedJob } : null}
          onChange={(o: Option<string> | null) => setSelectedJob(o?.value)}
          name="job_id"
          editing={true}
          isClearable
        />
        <Formblock
          label="New activity"
          className="modal"
          type="select"
          form={form}
          options={activityOptions}
          name="activity_id"
          editing={true}
          isClearable
        />
        <Formblock
          label="New department"
          className="modal"
          type="select"
          form={form}
          options={deptOptions}
          name="department_id"
          editing={true}
          isClearable
        />
        <Formblock
          label="New location"
          className="modal"
          type="select"
          form={form}
          options={locationOptions}
          name="location_id"
          editing={true}
          isClearable
        />
        {!hasQbOrXero && (
          <Formblock
            label="Create GL entry"
            className="modal"
            text="Yes, create a new GL entry for this recoding."
            type="checkbox"
            form={form}
            onChange={(e) => setCreateGlEntry(e.target.checked)}
            name="create_gl_entry"
            editing={true}
          />
        )}
        {createGlEntry && (
          <>
            <div className="yellow-text-container">
              Miter will not directly edit the original payroll&apos;s GL entry. Instead, a new GL entry will
              be created to account for this recoding.
              <br />
              <br />
              You should only check this box if the existing GL entry for the selected timesheets has already
              synced to your accounting system and has been locked.
            </div>
            <div style={{ height: 15 }}></div>
            <Formblock
              label="GL post date"
              className="modal"
              type="datetime"
              dateOnly={true}
              form={form}
              name="gl_post_date"
              editing={true}
            />
            <Formblock label="Reason" className="modal" type="text" form={form} name="note" editing={true} />
          </>
        )}
        <div className="vertical-spacer"></div>
      </div>
    </ActionModal>
  );

  return (
    <>
      {renderMainModal()}
      {renderFailuresModal()}
    </>
  );
};
