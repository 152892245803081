import { useCallback, useMemo } from "react";
import { useMiterAbilities } from "./useMiterAbilities";

type OfferTemplateAction = "create" | "read" | "update" | "delete";
type OfferTemplateAbilities = {
  can: (action: OfferTemplateAction) => boolean;
  cannot: (action: OfferTemplateAction) => boolean;
};

export const useOfferTemplateAbilities = (): OfferTemplateAbilities => {
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: OfferTemplateAction) => {
      switch (action) {
        case "create":
          return can_("offers:offer_templates:create");
        case "read":
          return can_("offers:offer_templates:read");
        case "update":
          return can_("offers:offer_templates:update");
        case "delete":
          return can_("offers:offer_templates:delete");
        default:
          return false;
      }
    },
    [can_]
  );

  const cannot = useCallback(
    (action: OfferTemplateAction) => {
      return !can(action);
    },
    [can]
  );

  return useMemo(() => ({ can, cannot }), [can, cannot]);
};
