import { MiterAPI, MiterFilterArray } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { DateTime } from "luxon";
import React, { ReactElement, RefObject, useContext, useMemo, useState } from "react";
import { Popover } from "react-tiny-popover";
import Select, { ActionMeta, ValueType } from "react-select";
import { bulkEditTimesheetSelectStyles } from "../BulkCreateTimesheets/SelectStyles";
import styles from "./BulkUpdateTimesheetsPopover.module.css";
import { Button } from "ui";
import {
  useActiveCompany,
  useActivityOptions,
  useClassificationOptions,
  useCostTypeOptions,
  useDepartmentOptions,
  useJobOptions,
  useRateDifferentialOptions,
  useRateDifferentials,
  useTeamOptions,
  useWcCodeOptions,
} from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";
import { isTimesheetScoped } from "dashboard/pages/activities/activityUtils";
import FailuresModal, { FailureItem } from "dashboard/components/shared/FailuresModal";
import { baseEarningTypeOptions } from "../TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import AppContext from "dashboard/contexts/app-context";
import CustomFieldValuesForm from "dashboard/components/custom-fields/CustomFieldValuesForm";
import { useForm } from "react-hook-form";
import { PartialCustomFieldValue } from "backend/services/custom-field-value-service";
import { cleanCustomFieldValueParams } from "miter-utils";
import { isCostTypeTimesheetScoped } from "dashboard/components/cost-types/costTypeUtils";
import { useJobScopesPredicate } from "../../jobs/jobUtils";

type Props = {
  ref?: RefObject<$TSFixMe>;
  show: boolean;
  children?: ReactElement;
  setShow: (show: boolean) => void;
  onFinish: () => void;
  findParams: { ids: string[] } | { filter: MiterFilterArray };
};

type FormData = {
  team_member?: string;
  clock_in_time?: string;
  clock_out_time?: string;
  break_time_hours?: number;
  break_time_minutes?: number;
  job?: string | null | undefined;
  cost_type_id?: string | null | undefined;
  department_id?: string | null | undefined;
  earning_type?: string;
  activity?: string;
  classification_override?: string;
  wc_code?: string;
  rate_differential_id?: string;
  notes?: string;
};

type PreparedFormData = {
  team_member?: string;
  clock_in?: number;
  clock_out?: number;
  unpaid_break_time?: number;
  job?: string | null | undefined;
  activity?: string | null;
  cost_type_id?: string | null | undefined;
  department_id?: string | null | undefined;
  earning_type?: string | null;
  classification_override?: string | null;
  wc_code?: string | null;
  rate_differential_id?: string | null;
  notes?: string;
};

export const BulkUpdateTimesheetsPopover: React.FC<Props> = ({
  show,
  setShow,
  children,
  ref,
  onFinish,
  findParams,
}) => {
  const company = useActiveCompany();
  const enableAdminWcCode = company?.settings?.timesheets.enable_admin_wc_code;
  const abilities = useMiterAbilities();
  const canReadWcCode = abilities.can("lists:workers_comp_codes:read");
  const timesheetAbilities = useTimesheetAbilities();
  const { wfmJobScopeWithAbility } = useJobScopesPredicate(timesheetAbilities.jobPredicate("update"));

  const teamOptions = useTeamOptions({ predicate: timesheetAbilities.teamPredicate("update") });

  const jobOptions = useJobOptions({
    includeBlank: true,
    predicate: wfmJobScopeWithAbility,
  });
  const departmentOptions = useDepartmentOptions({ includeBlank: true });
  const costTypeOptions = useCostTypeOptions({ includeBlank: true, predicate: isCostTypeTimesheetScoped });
  const classificationOptions = useClassificationOptions({});
  const wcCodeOptions = useWcCodeOptions();
  const rateDifferentials = useRateDifferentials();
  const rateDifferentialOptions = useRateDifferentialOptions({ includeBlank: true });

  const { customFields } = useContext(AppContext);
  const tsCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === "timesheet"),
    [customFields]
  );

  const customFieldsForm = useForm();
  const {
    formState: { isDirty },
  } = customFieldsForm;

  const [updating, setUpdating] = useState(false);
  const [formData, setFormData] = useState<FormData>({});
  const [failures, setFailures] = useState<FailureItem[]>([]);
  const [formErrors, setFormErrors] = useState(false);

  const activityOptions = useActivityOptions(formData.job, {
    includeBlank: true,
    predicate: isTimesheetScoped,
  });

  const preparedUpdateParams = (): PreparedFormData => {
    // Initialize the data
    const params: PreparedFormData = {
      team_member: formData.team_member,
      job: formData.job === "" ? null : formData.job,
      activity: formData.activity === "" ? null : formData.activity,
      cost_type_id: formData.cost_type_id === "" ? null : formData.cost_type_id,
      classification_override:
        formData.classification_override === "" ? null : formData.classification_override,
      notes: formData.notes,
      earning_type: formData.earning_type === "" ? null : formData.earning_type,
      wc_code: formData.wc_code === "" ? null : formData.wc_code,
      rate_differential_id: formData.rate_differential_id === "" ? null : formData.rate_differential_id,
    };

    // Format and add clock in, clock out, and break time to the params
    if (formData.clock_in_time) {
      params.clock_in = DateTime.fromISO(formData.clock_in_time).toSeconds();
    }
    if (formData.clock_out_time) {
      params.clock_out = DateTime.fromISO(formData.clock_out_time).toSeconds();
    }
    if (formData.break_time_hours || formData.break_time_minutes) {
      params.unpaid_break_time =
        (formData.break_time_hours || 0) * 3600 + (formData.break_time_minutes || 0) * 60;
    }

    if (formData.department_id != null) {
      params.department_id = formData.department_id === "" ? null : formData.department_id;
    }

    // Make sure clock in, clock out, and unpaid break time are numbers if set
    if (
      (params.clock_in && isNaN(params.clock_in)) ||
      (params.clock_out && isNaN(params.clock_out)) ||
      (params.unpaid_break_time && isNaN(params.unpaid_break_time))
    ) {
      throw new Error("Invalid clock in, clock out, or unpaid break time");
    }

    // Make sure clock in is before clock out
    if (params.clock_in && params.clock_out && params.clock_in > params.clock_out) {
      throw new Error("Clock in time must be before clock out time");
    }

    // Make sure clock out - clock in is less than 24 hours
    if (params.clock_in && params.clock_out && params.clock_out - params.clock_in > 86400) {
      throw new Error("Clock out time must be less than 24 hours after clock in time");
    }

    // Make sure break time is not greater than total clock in - clock out time
    if (params.clock_in && params.clock_out && params.unpaid_break_time) {
      const totalTime = params.clock_out - params.clock_in;

      if (params.unpaid_break_time > totalTime) {
        throw new Error("Break time cannot be greater than total timesheet duration.");
      }
    }

    return params;
  };

  const handleSaveCustomFields = (): Promise<PartialCustomFieldValue[]> => {
    return new Promise((resolve, reject) => {
      customFieldsForm.handleSubmit(async (customFieldsData) => {
        try {
          const result = await buildCustomFieldValueParams(customFieldsData);
          setFormErrors(false);
          resolve(result);
        } catch (error: $TSFixMe) {
          setFormErrors(true);
          reject(error);
        }
      })();
    });
  };

  const buildCustomFieldValueParams = async (customFieldsData): Promise<PartialCustomFieldValue[]> => {
    if (!company || !tsCustomFields.length || !isDirty) return [];
    const changedData = Object.fromEntries(Object.entries(customFieldsData).filter(([, value]) => !!value));
    const customFieldParams: PartialCustomFieldValue[] = Object.entries(changedData).map(([key, value]) => ({
      custom_field_id: key,
      value: cleanCustomFieldValueParams(value),
    }));
    return customFieldParams;
  };

  const updateTimesheets = async () => {
    if (!company) return;

    // save custom fields
    const customFieldUpdate = await handleSaveCustomFields();
    if (formErrors) return;

    setUpdating(true);
    try {
      const update = preparedUpdateParams();
      const response = await MiterAPI.timesheets.update_many({
        ...findParams,
        update,
        custom_field_values: customFieldUpdate,
      });
      setShow(false);
      setFormData({});
      if (response.error) {
        throw new Error(response.error);
      } else if (response.errors.length) {
        const newFailures = response.errors.map((bulkError) => {
          return { label: bulkError.label || bulkError._id, message: bulkError.message };
        });
        setFailures(newFailures);
      } else {
        Notifier.success("Timesheets updated successfully");
        setFailures([]);
        onFinish();
      }
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }

    setUpdating(false);
  };

  /****************************************************************
   * Bulk input field handlers
   ****************************************************************/

  const handleBulkSelectChange = (
    option: Option<string> | null | undefined,
    element: ActionMeta<Option<string>>
  ): void => {
    if (option) {
      setFormData({
        ...formData,
        [element.name as string]: option.value,
      });
    } else {
      setFormData({
        ...formData,
        [element.name as string]: null,
      });
    }
  };

  const handleBulkTextChange = (e) => {
    if (e.target.name.includes("break_time_hours")) {
      if (e.target.value && (isNaN(e.target.value) || e.target.value < 0 || e.target.value > 24)) {
        e.target.value = "";
        return;
      }
    }

    if (e.target.name.includes("break_time_minutes")) {
      if (e.target.value && (isNaN(e.target.value) || e.target.value < 0 || e.target.value > 59)) {
        e.target.value = "";
        return;
      }
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBulkSubmit = async () => {
    await updateTimesheets();
  };

  const handleCancel = () => {
    setFormData({});
    setShow(false);
  };

  const renderFields = () => {
    return (
      <>
        <div className={styles["bulk-edit-fields"]}>
          <div className={styles["bulk-edit-input-container"]}>
            <Select
              key={"bulk-team-member-selector"}
              name="team_member"
              options={teamOptions}
              width="100%"
              height="32px"
              onChange={(value: ValueType<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) =>
                handleBulkSelectChange(value, actionMeta)
              }
              styles={bulkEditTimesheetSelectStyles}
              isClearable={true}
              menuPlacement={"bottom"}
              placeholder={"Select the team member"}
            />
          </div>
          <div className={styles["bulk-edit-input-container"]}>
            <Select
              key={"bulk-job-selector"}
              name="job"
              options={jobOptions}
              width="100%"
              height="32px"
              onChange={(value: ValueType<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) =>
                handleBulkSelectChange(value, actionMeta)
              }
              styles={bulkEditTimesheetSelectStyles}
              isClearable={true}
              menuPlacement={"bottom"}
              placeholder={"Select the job"}
              disabled={updating}
            />
          </div>
          <div className={styles["bulk-edit-input-container"]}>
            <Select
              key={"bulk-activity-selector"}
              name="activity"
              options={activityOptions}
              width="100%"
              height="32px"
              onChange={(value: ValueType<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) =>
                handleBulkSelectChange(value, actionMeta)
              }
              styles={bulkEditTimesheetSelectStyles}
              isClearable={true}
              menuPlacement={"bottom"}
              placeholder={"Select the activity"}
              disabled={updating}
            />
          </div>
          <div className={styles["bulk-edit-input-container"]}>
            <Select
              key={"bulk-cost-type-selector"}
              name="cost_type_id"
              options={costTypeOptions}
              width="100%"
              height="32px"
              onChange={(value: ValueType<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) =>
                handleBulkSelectChange(value, actionMeta)
              }
              styles={bulkEditTimesheetSelectStyles}
              isClearable={true}
              menuPlacement={"bottom"}
              placeholder={"Select the cost type"}
              disabled={updating}
            />
          </div>
          <div className={styles["bulk-edit-input-container"]}>
            <Select
              key={"bulk-classification-selector"}
              name="classification_override"
              options={classificationOptions}
              width="100%"
              height="32px"
              onChange={(value: ValueType<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) =>
                handleBulkSelectChange(value, actionMeta)
              }
              styles={bulkEditTimesheetSelectStyles}
              isClearable={true}
              menuPlacement={"bottom"}
              placeholder={"Select the classification"}
              disabled={updating}
            />
          </div>
          <div className={styles["bulk-edit-input-container"]}>
            <Select
              key={"bulk-department-selector"}
              name="department_id"
              options={departmentOptions}
              width="100%"
              height="32px"
              onChange={(value: ValueType<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) =>
                handleBulkSelectChange(value, actionMeta)
              }
              styles={bulkEditTimesheetSelectStyles}
              isClearable={true}
              menuPlacement={"bottom"}
              placeholder={"Select the department"}
              disabled={updating}
            />
          </div>
          <div className={styles["bulk-edit-input-container"]}>
            <Select
              key={"bulk-earning-type-selector"}
              name="earning_type"
              options={baseEarningTypeOptions}
              width="100%"
              height="32px"
              onChange={(value: ValueType<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) =>
                handleBulkSelectChange(value, actionMeta)
              }
              styles={bulkEditTimesheetSelectStyles}
              isClearable={true}
              menuPlacement={"bottom"}
              placeholder={"Select the earning type"}
              disabled={updating}
            />
          </div>
          <div className={styles["bulk-edit-input-container"]}>
            <input
              className={"form2-text " + styles["bulk-edit-input"]}
              name="clock_in_time"
              type="text"
              onChange={handleBulkTextChange}
              placeholder={"Clock in time"}
              onFocus={(e) => (e.target.type = "datetime-local")}
              value={formData.clock_in_time}
              disabled={updating}
            />
          </div>
          <div className={styles["bulk-edit-input-container"]}>
            <input
              className={"form2-text " + styles["bulk-edit-input"]}
              name="clock_out_time"
              type="text"
              onChange={handleBulkTextChange}
              placeholder={"Clock out time"}
              onFocus={(e) => (e.target.type = "datetime-local")}
              value={formData.clock_out_time}
              disabled={updating}
            />
          </div>
          <div className={styles["bulk-edit-input-container"] + " " + styles["break-time-input-container"]}>
            <input
              className={"form2-text " + styles["bulk-edit-input"]}
              name="break_time_hours"
              type="number"
              onChange={handleBulkTextChange}
              placeholder={"Break time (hours)"}
              min={0}
              max={24}
              value={formData.break_time_hours}
              disabled={updating}
            />
          </div>
          <div className={styles["bulk-edit-input-container"] + " " + styles["break-time-input-container"]}>
            <input
              className={"form2-text " + styles["bulk-edit-input"]}
              name="break_time_minutes"
              type="number"
              onChange={handleBulkTextChange}
              placeholder="Break time (minutes)"
              min={0}
              max={59}
              value={formData.break_time_minutes}
              disabled={updating}
            />
          </div>
          {canReadWcCode && enableAdminWcCode && (
            <div className={styles["bulk-edit-input-container"] + " " + styles["break-time-input-container"]}>
              <Select
                key={"bulk-wc-selector"}
                name="wc_code"
                options={wcCodeOptions}
                width="100%"
                height="32px"
                onChange={(value: ValueType<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) =>
                  handleBulkSelectChange(value, actionMeta)
                }
                styles={bulkEditTimesheetSelectStyles}
                isClearable={true}
                menuPlacement={"bottom"}
                placeholder={"Select workers' comp code"}
                disabled={updating}
              />
            </div>
          )}
          {!!rateDifferentials.length && (
            <div className={styles["bulk-edit-input-container"] + " " + styles["break-time-input-container"]}>
              <Select
                key={"bulk-rate-differential-selector"}
                name="rate_differential_id"
                options={rateDifferentialOptions}
                width="100%"
                height="32px"
                onChange={(value: ValueType<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) =>
                  handleBulkSelectChange(value, actionMeta)
                }
                styles={bulkEditTimesheetSelectStyles}
                isClearable={true}
                menuPlacement={"bottom"}
                placeholder={"Select rate differential"}
                disabled={updating}
              />
            </div>
          )}
        </div>
        <div className={styles["bulk-edit-input-container"]} style={{ marginBottom: 10, marginTop: 10 }}>
          <textarea
            className={"form2-text " + styles["bulk-edit-input"] + " " + styles["bulk-edit-textarea"]}
            name="notes"
            onChange={handleBulkTextChange}
            placeholder="Notes"
            value={formData.notes}
            disabled={updating}
          />
        </div>
        {renderCustomFields()}
      </>
    );
  };
  const renderCustomFields = () => {
    return tsCustomFields.length ? (
      <div className={styles["custom-field-form"]}>
        <CustomFieldValuesForm
          form={customFieldsForm}
          customFields={tsCustomFields}
          defaultValues={[]}
          formblockClassName={styles["timesheet-custom-fields"]}
          inputClassName={styles["timesheet-custom-fields"]}
          replaceLabelWithPlaceholder={true}
          editing={true}
          selectStyles={bulkEditTimesheetSelectStyles}
        />
      </div>
    ) : null;
  };

  const renderContent = () => {
    return (
      <div className={styles["bulk-edit-fields-container"]}>
        {renderFields()}
        <Button
          loading={updating && !formErrors}
          className={"button-2 no-margin " + styles["bulk-edit-submit-button"]}
          onClick={handleBulkSubmit}
        >
          Submit
        </Button>
        <Button className={"button-1 no-margin " + styles["bulk-edit-cancel-button"]} onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    );
  };

  return (
    <>
      {failures.length > 0 && (
        <FailuresModal
          headerText={"Timesheet update failures"}
          onClose={() => {
            onFinish();
            setFailures([]);
          }}
          failures={failures}
        />
      )}
      {children && (
        <Popover
          ref={ref}
          isOpen={show}
          positions={["bottom"]}
          containerStyle={{ zIndex: "5" }}
          align="end"
          content={renderContent()}
        >
          {children}
        </Popover>
      )}
    </>
  );
};
