import {
  useActiveCompany,
  useActiveRecruitingConversation,
  useLookupJobPostings,
  usePaginatedRecruitingConversations,
  useRecruitingTwilioClient,
  useSetActiveRecruitingConversation,
  useTeam,
} from "dashboard/hooks/atom-hooks";
import { Article, CaretDown, Circle, Trash, Download, ArrowLeft, ArrowRight } from "phosphor-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Badge,
  Breadcrumbs,
  Button,
  DeleteModal,
  DropdownButton,
  Loader,
  Notifier,
  Toggler,
  Popup,
} from "ui";
import styles from "./Ats.module.css";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { useQuery } from "miter-utils";
import Notes from "dashboard/components/notes/Notes";
import { JobApplicationStatus } from "backend/models/ats/job-application";
import Select from "react-select";
import { selectStyles } from "ui/form/styles";
import TeamMemberWizard, { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { AggregatedJobApplication } from "dashboard/types/ats";
import {
  APPLICATION_STATUS_VALUE_LABEL,
  CANDIDATE_STATUSES,
  JobApplicationPointer,
  sortJobApplicationPointers,
} from "dashboard/utils/ats";
import FormSubmissionAnswerTable from "dashboard/components/forms/FormSubmissionAnswersTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { RecruitingConversationContainer } from "dashboard/components/chat/recruiting/RecruitingConversationContainer";
import { buildChatConversation, getTwilioConversations } from "dashboard/utils/chat";
import { DocumentDisplay } from "miter-components";
import { downloadFiles } from "miter-utils";
import { useHasAccessToOfferLetters, useHasAccessToRecruitingChat } from "dashboard/gating";
import { OfferLetterWizard } from "dashboard/components/offers/offer-letter-wizard/OfferLetterWizard";
import { OfferLetter } from "../../components/offers/OfferLetter";
import { useOfferLetterAbilities } from "dashboard/hooks/abilities-hooks/useOfferLetterAbilities";

export const JobApplication: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string; view: string }>();
  const [searchParams] = useSearchParams();
  const candidatePath = searchParams.get("path") === "candidate";
  const view = useQuery().get("view");
  const company = useActiveCompany();
  const { can, cannot } = useMiterAbilities();
  const twilioClient = useRecruitingTwilioClient();

  const [resume, setResume] = useState<{ url: string; type: string } | null>();
  const [jobApplication, setJobApplication] = useState<AggregatedJobApplication | null>(null);
  const [applicationStatus, setApplicationStatus] = useState<JobApplicationStatus | undefined>(
    jobApplication?.status
  );
  const [isArchiveModalOpen, setArchiveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOnboardModalOpen, setOnboardModal] = useState(false);
  const [offerWizard, setOfferWizard] = useState(false);
  const [associatedTeamMember, setAssociatedTeamMember] = useState<AggregatedTeamMember | null>(null);
  const teamMembers = useTeam();
  const fullName = jobApplication?.candidate?.first_name + " " + jobApplication?.candidate?.last_name;
  const lookupJobPosting = useLookupJobPostings();
  const jobPosting = lookupJobPosting(jobApplication?.job_posting_id);
  const [jobApplications, setJobApplications] = useState<JobApplicationPointer[]>([]);

  const [applicationIndex, setApplicationIndex] = useState(0);

  const setActiveRecruitingConversation = useSetActiveRecruitingConversation();
  const activeRecruitingConversation = useActiveRecruitingConversation();
  const recruitingConversations = usePaginatedRecruitingConversations();
  const hasChatAccess = useHasAccessToRecruitingChat();
  const hasOfferLetterAccess = useHasAccessToOfferLetters();
  const offerLetterAbilities = useOfferLetterAbilities();

  const fetchJobApplication = useCallback(async () => {
    if (!id) {
      navigate("/recruiting/job-postings", { replace: true });
      return;
    }
    try {
      const res = await MiterAPI.job_applications.forage({
        filter: [{ field: "_id", value: id, type: "_id" }],
      });
      if (res.data.length !== 1) throw new Error(`${res.data.length} jobs were returned for id ${id}`);

      setJobApplication(res.data[0] || null);
    } catch (err: $TSFixMe) {
      Notifier.error(`Failed to fetch job application: ${err.message}`);
    }
  }, [id, navigate]);

  // Fetches the job application, and redirects if there's no job application id
  useEffect(() => {
    fetchJobApplication();
  }, [fetchJobApplication]);

  useEffect(() => {
    if (jobApplications.length > 0) {
      setApplicationIndex(jobApplications.findIndex((app) => app._id === id));
    }
  }, [jobApplications, id]);

  const createChat = async (): Promise<boolean> => {
    try {
      if (!company || !jobApplication) return false;

      const res = await MiterAPI.chat.recruiting.create(company._id, jobApplication?.candidate._id);
      if (res.error) throw new Error(res.error);

      if (!twilioClient) {
        window.location.reload();
        return false;
      }

      const twilioConversations = await getTwilioConversations(twilioClient);
      const matchingTwilioConversation = twilioConversations.find(
        (conversation) => conversation.sid === res.conversation_sid
      );
      if (!matchingTwilioConversation) {
        throw new Error("Failed to create chat: Twilio conversation not found.");
      }

      const fullConversation = await buildChatConversation(res, matchingTwilioConversation);

      setActiveRecruitingConversation(fullConversation);
      return true;
    } catch (e: $TSFixMe) {
      Notifier.error(`Failed to create chat: ${e.message}`);
      return false;
    }
  };

  // Fetches the resume URL and sets the application status
  useEffect(() => {
    if (!jobApplication) return;
    if (!jobApplication?.resume) setResume(null);
    else {
      MiterAPI.files
        .get_urls({
          filter: [{ field: "_id", value: jobApplication?.resume._id, type: "_id" }],
        })
        .then((res) => {
          if (res.urls.length === 0) return;
          const [resUrl, resType] = [res?.urls[0]?.value.url, res?.urls[0]?.file?.type];
          if (resUrl && resType) {
            setResume({ url: resUrl, type: resType });
          }
        });
    }

    setApplicationStatus(jobApplication.status);
  }, [jobApplication]);

  useEffect(() => {
    setActiveRecruitingConversation(
      recruitingConversations.find((c) => c.candidate_id === jobApplication?.candidate._id) || null
    );
  }, [jobApplication?.candidate._id, recruitingConversations, setActiveRecruitingConversation]);

  const forageJobApplications = useCallback(async () => {
    try {
      setLoading(true);
      const res = await MiterAPI.job_applications.forage({
        filter: [
          {
            field: "company_id",
            value: company?._id,
          },
          {
            field: "job_posting_id",
            value: jobApplication?.job_posting._id,
          },
        ],
        select: [
          {
            field: "status",
            show: true,
          },
          {
            field: "created_at",
            show: true,
          },
          {
            field: "candidate.first_name",
            show: true,
          },
          {
            field: "candidate.last_name",
            show: true,
          },
        ],
      });

      setJobApplications(sortJobApplicationPointers(res.data));
    } catch (e: $TSFixMe) {}
    setLoading(false);
  }, [company?._id, jobApplication?.job_posting._id]);

  useEffect(() => {
    forageJobApplications();
  }, [forageJobApplications]);

  // Finds the associated team member if the candidate has already been converted into a TM
  useEffect(() => {
    const associatedTM = teamMembers.find((tm) => tm.candidate_id === jobApplication?.candidate?._id);
    setAssociatedTeamMember(associatedTM || null);
  }, [jobApplication?.candidate?._id, teamMembers]);

  /* Constants and Handlers */

  const canOnboard = applicationStatus === "hired" && !associatedTeamMember;
  const canOffer =
    !jobApplication?.offer_letter &&
    hasOfferLetterAccess &&
    offerLetterAbilities.can("create") &&
    applicationStatus !== "rejected";
  const canOfferForNonHiredCandidate = applicationStatus !== "hired" && canOffer;
  const canOfferForHiredCandidate = applicationStatus === "hired" && canOffer;

  const tMParams = useMemo(() => {
    if (!jobApplication) return null;
    return {
      first_name: jobApplication.candidate.first_name,
      last_name: jobApplication.candidate.last_name,
      email: jobApplication.candidate.email,
      phone: jobApplication.candidate.phone,
      employment_type: jobApplication.job_posting.employment_type === "contract" ? "contractor" : "employee",
      title: jobApplication.job_posting.title,
      // We don't know whether the hourly worker position is union or not, so we don't set it
      pay_type: jobApplication.job_posting.pay.type === "salary" ? "salary" : undefined,
      candidate_id: jobApplication.candidate._id,
    } as WizardTeamMember;
  }, [jobApplication]);

  const handleArchive = async (id) => {
    if (!company) {
      Notifier.error("Failed to delete job application: company not found");
      return;
    }

    if (cannot("recruiting:job_applications:delete")) {
      Notifier.error("You do not have permission to delete job applications");
      return;
    }

    try {
      setLoading(true);
      const res = await MiterAPI.job_applications.archive({
        ids: [id],
        company_id: company?._id,
      });
      if (res.error) throw new Error(res.error);
      Notifier.success("Successfully deleted job application");
      navigate("/recruiting/job-postings/" + jobApplication?.job_posting._id, { replace: true });
    } catch (err: $TSFixMe) {
      Notifier.error(`Failed to delete job application: ${err.message}`);
    }
    setLoading(false);
  };

  const handleStatusChange = async (option) => {
    if (!jobApplication) return;

    if (cannot("recruiting:job_applications:update")) {
      Notifier.error("You do not have permission to update job applications");
      return;
    }

    try {
      const newStatus = option.value;
      const res = await MiterAPI.job_applications.update([
        { _id: jobApplication._id, params: { status: newStatus } },
      ]);
      if (res.errors.length) throw new Error(res.errors[0]!.message);
      setApplicationStatus(newStatus);
      Notifier.success("Status successfully updated");
    } catch (err: $TSFixMe) {
      Notifier.error(`Failed to update status: ${err.message}`);
    }
  };

  const openArchiveApplicationModal = () => {
    setArchiveModal(true);
  };
  const closeArchiveApplicationModal = () => {
    setArchiveModal(false);
  };
  const openOnboardModal = () => {
    setOnboardModal(true);
  };
  const closeOnboardModal = () => {
    setOnboardModal(false);
  };

  const downloadResume = useCallback(() => {
    if (!jobApplication?.resume?._id) return;
    downloadFiles([jobApplication?.resume._id], () => {});
  }, [jobApplication?.resume?._id]);

  const openOfferWizard = () => {
    setOfferWizard(true);
  };
  const closeOfferWizard = async () => {
    await fetchJobApplication();
    setOfferWizard(false);
  };

  const onOfferComplete = async () => {
    if (!jobApplication) return;
    const newStatus = applicationStatus === "hired" ? "hired" : "offer";
    const res = await MiterAPI.job_applications.update([
      { _id: jobApplication._id, params: { status: newStatus } },
    ]);
    if (res.errors.length) throw new Error(res.errors[0]!.message);
    setApplicationStatus(newStatus);
    await closeOfferWizard();
  };

  const previousApplication = useMemo(() => {
    if (applicationIndex <= 0) return null;
    return jobApplications[applicationIndex - 1];
  }, [applicationIndex, jobApplications]);

  const nextApplication = useMemo(() => {
    if (applicationIndex >= jobApplications.length - 1) return null;
    return jobApplications[applicationIndex + 1];
  }, [applicationIndex, jobApplications]);

  const actions = useMemo(
    () => [
      {
        label: "Send offer letter",
        /** Will open the creation Wizard in the next PR */
        action: openOfferWizard,
        icon: <Article style={{ marginBottom: -2, marginRight: 7 }} />,
        shouldShow: () => canOfferForHiredCandidate,
      },
      {
        label: "Delete application",
        action: openArchiveApplicationModal,
        icon: <Trash style={{ marginBottom: -2, marginRight: 7 }} />,
        shouldShow: () => can("recruiting:job_applications:delete"),
      },
      {
        label: "Download resume",
        action: downloadResume,
        icon: <Download style={{ marginBottom: -2, marginRight: 7 }} />,
        shouldShow: () => !!resume,
      },
    ],

    [downloadResume, canOfferForHiredCandidate, can, resume]
  );

  const togglerConfig = useMemo(
    () => [
      { path: "profile", label: "Profile" },
      { path: "screener-response", label: "Screener response" },
      { path: "notes", label: "Notes" },
      ...(!!jobApplication?.offer_letter && hasOfferLetterAccess && offerLetterAbilities.can("read")
        ? [{ path: "offer", label: "Offer" }]
        : []),
    ],
    [jobApplication?.offer_letter, hasOfferLetterAccess, offerLetterAbilities]
  );

  const toggle = (page) =>
    navigate("/recruiting/job-applications/" + jobApplication?._id.toString() + "?" + `view=${page}`, {
      replace: true,
    });

  /* Render Functions */

  const renderLabel = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={styles["label"]}>Conversation</div>
        {activeRecruitingConversation?.unread && (
          <Circle weight="fill" color="#cc2553" size={10} style={{ marginRight: -5, marginLeft: 5 }} />
        )}
      </div>
    );
  };

  const renderBreadcrumbs = () => {
    if (!jobApplication) return;

    if (candidatePath) {
      return (
        <Breadcrumbs
          crumbs={[
            { label: "Candidates", path: "/recruiting/candidates" },
            {
              label: fullName,
              path: "/recruiting/candidates/" + jobApplication.candidate._id,
            },
            {
              label: jobApplication.job_posting.title,
              path: "/recruiting/job-applications/" + jobApplication._id + "?path=candidate",
            },
          ]}
        />
      );
    }

    return (
      <Breadcrumbs
        crumbs={[
          { label: "Job postings", path: "/recruiting/job-postings" },
          {
            label: jobApplication.job_posting.title,
            path: "/recruiting/job-postings/" + jobApplication.job_posting._id,
          },
          {
            label: fullName,
            path: "/recruiting/job-applications/" + jobApplication._id,
          },
        ]}
      />
    );
  };

  const renderActions = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          height: "100%",
        }}
      >
        <div className="flex">
          <Select
            options={CANDIDATE_STATUSES.map((status) => ({
              value: status,
              label: APPLICATION_STATUS_VALUE_LABEL[status],
            }))}
            width={"200px"}
            zIndex={1000}
            onChange={handleStatusChange}
            placeholder={"Status"}
            menuPortalTarget={document.body}
            menuPlacement="auto"
            noOptionsMessage={() => "No location found"}
            height="32px"
            isDisabled={!!associatedTeamMember || cannot("recruiting:job_applications:update")}
            value={
              applicationStatus
                ? { value: applicationStatus, label: APPLICATION_STATUS_VALUE_LABEL[applicationStatus] }
                : undefined
            }
            styles={{
              control: (provided, state) => ({
                // @ts-expect-error styles
                ...selectStyles.control!(provided, state),
                fontFamily: "Karla",
              }),
              // Need the below for option dropdown to appear when used in modals
              menuPortal: (base) => ({ ...base, zIndex: 1000 }),
            }}
          />
          {canOnboard ? (
            <Button onClick={openOnboardModal} className="button-2 no-margin" style={{ marginLeft: 5 }}>
              Onboard
            </Button>
          ) : null}

          {canOfferForNonHiredCandidate ? (
            <Button onClick={openOfferWizard} className="button-2 no-margin" style={{ marginLeft: 5 }}>
              Send offer letter
            </Button>
          ) : null}

          <DropdownButton
            buttonStyle={{ height: 32 }}
            className={"button-1 "}
            options={actions}
            closeOnClick={true}
          >
            Actions
            <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
          </DropdownButton>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() =>
              previousApplication && navigate(`/recruiting/job-applications/${previousApplication._id}`)
            }
            className="button-1 no-margin"
            style={{ marginRight: 5 }}
            disabled={!previousApplication}
          >
            <ArrowLeft weight="bold" style={{ marginRight: previousApplication ? 5 : 0 }} />
            {previousApplication
              ? `${previousApplication.candidate.first_name} ${previousApplication.candidate.last_name}`
              : ""}
          </Button>

          <Button
            onClick={() => nextApplication && navigate(`/recruiting/job-applications/${nextApplication._id}`)}
            className="button-1 no-margin"
            style={{ marginRight: 5 }}
            disabled={!nextApplication}
          >
            {nextApplication
              ? `${nextApplication.candidate.first_name} ${nextApplication.candidate.last_name}`
              : ""}
            <ArrowRight weight="bold" style={{ marginLeft: nextApplication ? 5 : 0 }} />
          </Button>
        </div>
      </div>
    );
  };

  const renderResponses = () => {
    if (!jobPosting?.question_form || !jobApplication?.response_submission) return null;
    return (
      <FormSubmissionAnswerTable
        form={jobPosting?.question_form}
        formSubmission={jobApplication.response_submission}
      />
    );
  };

  const renderProfile = () => {
    return (
      <div>
        <div className={styles["profile-container"]}>
          <div className={styles["profile-box"]}>
            <div className={styles["profile-box-header"]}>
              <h3>Resume</h3>
            </div>

            {resume ? (
              <DocumentDisplay uri={resume.url} />
            ) : (
              <div className={styles["profile-box-body"]}>
                <div>No resume was submitted.</div>
              </div>
            )}
          </div>
          <div className={styles["profile-box"]}>
            <div className={styles["profile-box-header"]}>
              <h3>Basic information</h3>
            </div>
            <div className={styles["profile-box-body"] + " " + styles["no-flex"]}>
              <div className={styles["profile-box-row"]}>
                <span className={styles["profile-box-label"]}>Email</span>
                <span className={styles["profile-box-value"]}>{jobApplication?.candidate?.email || "-"}</span>
              </div>
              <div className={styles["profile-box-row"]}>
                <span className={styles["profile-box-label"]}>Phone</span>
                <span className={styles["profile-box-value"]}>{jobApplication?.candidate?.phone || "-"}</span>
              </div>
              {/* Job information */}
              <div className={styles["profile-box-row"]}>
                <span className={styles["profile-box-label"]}>Job Title</span>
                <span className={styles["profile-box-value"]}>{jobApplication?.current_job_title}</span>
              </div>
            </div>
          </div>
          {hasChatAccess ? (
            <div className={styles["profile-box"] + " " + styles["chat-container"]}>
              <Popup label={renderLabel()} defaultOpen={false} canOpen={createChat}>
                <RecruitingConversationContainer isInvidualChat={true} />
              </Popup>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderToggler = () => <Toggler config={togglerConfig} active={view || "profile"} toggle={toggle} />;
  const renderView = () => {
    if (!jobApplication) return;
    switch (view) {
      case "notes":
        return (
          <Notes
            parentId={jobApplication._id}
            parentType={"job_application"}
            readonly={cannot("recruiting:job_applications:update")}
          />
        );
      case "screener-response":
        return renderResponses();
      case "offer":
        if (!jobApplication.offer_letter) return null;
        return (
          <OfferLetter
            offerLetter={jobApplication.offer_letter}
            candidateInformation={candidateInformation}
          />
        );
      default:
        return renderProfile();
    }
  };

  const renderArchiveModal = () => {
    if (!jobApplication || !isArchiveModalOpen) return;
    return (
      <DeleteModal
        header={"Are you sure?"}
        body={<div>Deleting this job application will permanently delete it.</div>}
        cancelText={"Cancel"}
        onHide={closeArchiveApplicationModal}
        deleteText={"Yes, delete application"}
        onDelete={() => handleArchive(jobApplication._id)}
        loading={loading}
      />
    );
  };

  if (!jobApplication) return <Loader />;

  const candidateInformation = {
    candidate_email: jobApplication.candidate.email || "(no email)",
    candidate_first_name: jobApplication.candidate.first_name,
    candidate_last_name: jobApplication.candidate.last_name,
    id: jobApplication.candidate._id,
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>{fullName} | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <div>
          {renderBreadcrumbs()}
          <h1>{fullName}</h1>
          <div className={"member-info"}>
            <span>Candidate</span>
            <div style={{ marginLeft: 5 }}>
              <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                {jobApplication?.offer_letter?.status === "signed" ? (
                  <Badge className="no-margin" text={"Offer signed"} color={"green"} />
                ) : null}
                {associatedTeamMember ? (
                  <Badge className="no-margin" text={"Onboarded"} color={"blue"} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
        {renderActions()}
      </div>
      {renderToggler()}
      {renderView()}
      {renderArchiveModal()}
      {isOnboardModalOpen && tMParams ? (
        <TeamMemberWizard
          onComplete={closeOnboardModal}
          onExit={closeOnboardModal}
          mode="create"
          teamMember={tMParams}
        />
      ) : null}

      {offerWizard ? (
        <OfferLetterWizard
          candidateInfo={candidateInformation}
          jobApplicationId={jobApplication._id}
          onComplete={onOfferComplete}
          onExit={closeOfferWizard}
        />
      ) : null}
    </div>
  );
};
