import React, { useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ClickAwayListener } from "@material-ui/core";
import { Formblock, TextWithTooltip } from "ui";
import { ModalHeader } from "ui";

import { DateTime } from "luxon";
import { Button } from "ui";
import { AggregatedPayroll, MiterAPI, MiterError, Payroll } from "dashboard/miter";
import * as vals from "dashboard/utils/validators";
import Notifier from "dashboard/utils/notifier";
import { useNavigate } from "react-router";
import { GetPayrollQuery } from "backend/controllers/payrolls-controller";
import { CheckPayroll } from "backend/utils/check/check-types";
import { RJW_COMPANY_ID } from "dashboard/utils";
import { useActiveCompany, useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { frequencyOptions } from "dashboard/utils/paySchedules";
import { PayrollAdjustment } from "backend/utils/payroll/types";

type Props = {
  edit?: boolean;
  payroll?: AggregatedPayroll;
  getPayroll?: (options?: GetPayrollQuery) => Promise<AggregatedPayroll | MiterError | null | void>;
  hide: () => void;
  adjustments?: PayrollAdjustment[];
};

const OffCyclePayrollModal: React.FC<Props> = (props: Props) => {
  const { payroll, getPayroll, adjustments } = props;
  const checkPayroll = payroll?.check_payroll;

  // Hooks
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();
  const { register, errors, setError, control, handleSubmit } = useForm({ shouldUnregister: false });
  useWatch({ control });

  const navigate = useNavigate();

  // State
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<DateTime | undefined>(
    props.edit ? DateTime.fromISO(payroll!.check_payroll.period_start) : undefined
  );
  const [endDate, setEndDate] = useState<DateTime | undefined>(
    props.edit ? DateTime.fromISO(payroll!.check_payroll.period_end) : undefined
  );
  const [payday, setPayday] = useState<DateTime | undefined>(
    props.edit ? DateTime.fromISO(payroll!.check_payroll.payday) : undefined
  );

  const submit = async (data) => {
    if (!activeCompanyId || (props.edit && !payroll)) return;
    setLoading(true);
    try {
      const payload: Partial<CheckPayroll> & Partial<Payroll> = {
        ...data,
        period_start: data.period_start.toISODate(),
        period_end: data.period_end.toISODate(),
        payday: data.payday.toISODate(),
        pay_frequency: data.pay_frequency?.value,
      };
      if (!props.edit) {
        payload.company = activeCompanyId;
        payload.type = "off_cycle";
      }
      if (activeCompanyId === RJW_COMPANY_ID) {
        payload.off_cycle_options!.apply_post_tax_deductions = true;
      }
      let response: Payroll & MiterError;
      if (props.edit) {
        response = await MiterAPI.payrolls.update_payroll(payroll!._id, payload);
      } else {
        response = await MiterAPI.payrolls.create({ ...payload, adjustments });
      }
      if (response.error) {
        if (response.error_type === "payroll_date_invalid") {
          setError("payday", {
            message: response.error,
          });
          setLoading(false);
          return;
        } else {
          throw new Error(response.error);
        }
      }
      const successMsg = props.edit ? "Off-cycle settings updated." : "Payroll successfully created.";
      Notifier.success(successMsg);
      if (!props.edit) {
        navigate("/payrolls/" + response._id);
      } else {
        getPayroll?.();
        props.hide();
      }
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      if (props.edit) {
        getPayroll?.();
      }
      props.hide();
      console.log(e);
    }
    setLoading(false);
  };

  const [twoYearsAgo, inOneYear, minPayday] = useMemo(() => {
    const now = DateTime.now();
    return [
      now.minus({ years: 2 }).startOf("day"),
      now.plus({ years: 1 }).startOf("day"),
      now.plus({
        days: now.setZone("America/Los_Angeles").hour >= 17 ? 1 : 0,
      }),
    ];
  }, []);

  if (!activeCompanyId) return null;

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className="modal-wrapper form">
          <ModalHeader
            heading={props.edit ? "Update off-cycle settings" : "Create off-cycle payroll"}
            onHide={props.hide}
          />
          <div className="modal-body form" style={{ minHeight: 535 }}>
            <div className="vertical-spacer"></div>
            <Formblock
              label="Pay period start"
              type="datetime"
              control={control}
              dateOnly={true}
              className="modal dt"
              rules={vals.required}
              name="period_start"
              errors={errors}
              onChange={setStartDate}
              defaultValue={startDate}
              editing={true}
              min={twoYearsAgo}
              max={inOneYear}
            />
            <Formblock
              label="Pay period end"
              type="datetime"
              control={control}
              dateOnly={true}
              rules={vals.required}
              name="period_end"
              className="modal dt"
              onChange={setEndDate}
              errors={errors}
              defaultValue={endDate}
              editing={true}
              min={twoYearsAgo}
              max={inOneYear}
            />
            <Formblock
              label="Payday"
              type="datetime"
              control={control}
              dateOnly={true}
              rules={vals.required}
              name="payday"
              onChange={setPayday}
              className="modal dt"
              errors={errors}
              defaultValue={payday}
              editing={true}
              min={minPayday}
              max={inOneYear}
            />
            <Formblock
              label="Tax interval"
              labelInfo="The pay period interval Miter will use to calculate taxes for this off-cycle payroll."
              control={control}
              type="select"
              defaultValue={
                payroll?.check_payroll.pay_frequency || activeCompany?.check_company.pay_frequency || "weekly"
              }
              options={frequencyOptions}
              name="pay_frequency"
              className="modal dt"
              errors={errors}
              editing={true}
            />
            <Formblock
              label="Label (optional)"
              type="text"
              control={control}
              register={register}
              name="label"
              className="modal"
              errors={errors}
              editing={true}
              defaultValue={payroll?.label}
              placeholder="Off-cycle"
            />
            <div className="vertical-spacer-small"></div>
            <div className="bold" style={{ marginBottom: "10px" }}>
              Off-cycle options
            </div>
            <Formblock
              register={register}
              type="checkbox"
              name="off_cycle_options.force_supplemental_withholding"
              className="modal"
              errors={errors}
              defaultValue={
                props.edit ? checkPayroll?.off_cycle_options?.force_supplemental_withholding : null
              }
              editing={true}
            >
              <TextWithTooltip
                id="tax-supplemental"
                text="Tax all earnings as supplemental earnings"
                tooltip="Supplemental earnings typically have higher tax withholding rates"
              />
            </Formblock>
            <Formblock
              register={register}
              type="checkbox"
              name="off_cycle_options.apply_benefits"
              className="modal"
              errors={errors}
              defaultValue={props.edit ? checkPayroll?.off_cycle_options?.apply_benefits : null}
              editing={true}
            >
              <TextWithTooltip
                id="apply-benefits"
                text="Apply benefit contributions"
                tooltip="Select to have normal benefit contributions & deductions applied on this payroll (e.g., 401k contributions)"
              />
            </Formblock>
            {activeCompanyId !== RJW_COMPANY_ID && (
              <Formblock
                register={register}
                type="checkbox"
                name="off_cycle_options.apply_post_tax_deductions"
                className="modal"
                defaultValue={props.edit ? checkPayroll?.off_cycle_options?.apply_post_tax_deductions : null}
                errors={errors}
                editing={true}
              >
                <TextWithTooltip
                  id="apply-ptds"
                  text="Apply post-tax deductions"
                  tooltip="Select to have post-tax deductions deducted on this payroll (e.g., child support)"
                />
              </Formblock>
            )}
          </div>
          <div className="modal-footer form">
            <button className="button-1" onClick={props.hide}>
              Cancel
            </button>
            <Button
              className="button-2"
              text={props.edit ? "Save" : "Create payroll"}
              onClick={handleSubmit(submit)}
              loading={loading}
            />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default OffCyclePayrollModal;
