import { useCallback, useMemo } from "react";
import { useMiterAbilities } from "./useMiterAbilities";

type OfferLetterAction = "create" | "read" | "update" | "delete";
type OfferLetterAbilities = {
  can: (action: OfferLetterAction) => boolean;
  cannot: (action: OfferLetterAction) => boolean;
};

export const useOfferLetterAbilities = (): OfferLetterAbilities => {
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: OfferLetterAction) => {
      switch (action) {
        case "create":
          return can_("offers:offer_letters:create");
        case "read":
          return can_("offers:offer_letters:read");
        case "update":
          return can_("offers:offer_letters:update");
        case "delete":
          return can_("offers:offer_letters:delete");
        default:
          return false;
      }
    },
    [can_]
  );

  const cannot = useCallback(
    (action: OfferLetterAction) => {
      return !can(action);
    },
    [can]
  );

  return useMemo(() => ({ can, cannot }), [can, cannot]);
};
