import { LedgerAccount, LedgerMapping } from "dashboard/miter";
import { GranularityLineType } from "backend/utils/accounting";
import { useLedgerAccountLabeler } from "dashboard/hooks/atom-hooks";
import React, { useMemo } from "react";
import { useBaseEarningTypeOptions } from "../payrolls/viewPayroll/viewPayrollUtils";
import { CheckBenefitType, CheckEarningType } from "backend/utils/check/check-types";
import { Option } from "ui/form/Input";
import { benefitSelections } from "../benefits/benefitsUtils";

/** These line items can't be broken out by job or activity because they're generally unrelated to work performed */
export const noJobNoActivityLineTypes: GranularityLineType[] = [
  "child_support_liabilities",
  "prepaid_child_support",
  "paper_check_liabilities",
  "tax_liabilities",
  "misc_ptds",
  "tax_receivables",
  "net_pay_receivables",
];

/** Sometimes users want to allocate the labor burden into the same account as the actual earnings, in a dynamic fashion. This option lets us capture that. */
export const INCLUDE_WITH_EARNING_ACCOUNT = "__ALLOCATE__";

export const includeWithEarningOption: Option<string> = {
  label: "(include with associated earning)",
  value: INCLUDE_WITH_EARNING_ACCOUNT,
};

type LedgerMappingCategory =
  | "payroll"
  | "expense_mgmt"
  | "earning_types"
  | "benefit_type_expenses"
  | "benefit_type_liabilities";

export const ledgerMappingCategoryLookup: Record<LedgerMappingCategory, string> = {
  payroll: "Payroll Defaults",
  expense_mgmt: "Spend Management",
  earning_types: "Earning Type Overrides",
  benefit_type_expenses: "Benefit Expense Overrides by Type",
  benefit_type_liabilities: "Benefit Liability Overrides by Type",
};

export type LedgerMappingLineInfo = {
  _id: string;
  category: LedgerMappingCategory;
  flavor: "Asset" | "Expense" | "Liability";
  label: string;
  description: string;
  creditOrDebit: "Credit" | "Debit";
  accountId?: string | null | undefined;
  forDefaultMappingOnly?: boolean;
};

export type GranularityLineConfig = Omit<LedgerMappingLineInfo, "accountId"> & {
  department?: boolean;
  location?: boolean;
  teamMember?: boolean;
  job?: boolean;
  activity?: boolean;
  costType?: boolean;
};

export type LedgerAccountSource =
  | "Quickbooks Online"
  | "Quickbooks Desktop"
  | "Manual"
  | "Xero"
  | "Sage Intacct"
  | "Foundation"
  | "Vista"
  | "Sage 300"
  | "Acumatica"
  | "NetSuite";

export const getLedgerAccountSource = (ledgerAccount?: LedgerAccount): LedgerAccountSource => {
  if (ledgerAccount?.integrations?.qbo) {
    return "Quickbooks Online";
  } else if (ledgerAccount?.integrations?.qbd) {
    return "Quickbooks Desktop";
  } else if (ledgerAccount?.integrations?.xero) {
    return "Xero";
  } else if (ledgerAccount?.integrations?.sage_intacct) {
    return "Sage Intacct";
  } else if (ledgerAccount?.integrations?.foundation) {
    return "Foundation";
  } else if (ledgerAccount?.integrations?.vista) {
    return "Vista";
  } else if (ledgerAccount?.integrations?.sage_300) {
    return "Sage 300";
  } else if (ledgerAccount?.integrations?.acumatica) {
    return "Acumatica";
  } else if (ledgerAccount?.integrations?.netsuite) {
    return "NetSuite";
  } else {
    return "Manual";
  }
};

export type LedgerLineInfoType =
  | "granularity"
  | "company"
  | "employee"
  | "contractor"
  | "job"
  | "activity"
  | "department";

/** This outputs an array of ledger entry line types with some detail / added information */
export const LEDGER_LINE_TYPE_ID_SEPARATOR = "__";
export const useLedgerLineTypes = (inputs: {
  isDefault?: boolean;
  mapping?: LedgerMapping;
  isGranularity?: boolean;
}): LedgerMappingLineInfo[] => {
  const { mapping, isDefault, isGranularity } = inputs;
  const earningOptions = useBaseEarningTypeOptions();

  return React.useMemo(() => {
    const lineItemTypes = allDefaultMappingLineTypes.filter((t) => {
      if (!isDefault && t.forDefaultMappingOnly) return false;
      return true;
    });

    for (const line of lineItemTypes) {
      line.accountId = mapping?.defaults?.[line._id];
    }

    // If we're generating a list of line items type for the granularity configuration,
    // We don't need to include the earning/benefit types, since they're not relevant.
    if (!isGranularity) {
      for (const earningType of earningOptions) {
        const checkType = earningType.value as CheckEarningType;
        lineItemTypes.push({
          _id: checkType,
          category: "earning_types",
          flavor: "Expense",
          label: earningType.label,
          description: `${earningType.label} earnings paid to employees.`,
          creditOrDebit: "Debit",
          accountId: mapping?.earning_types?.[checkType],
        });
      }

      for (const benefitType of benefitSelections) {
        const checkType = benefitType.value as CheckBenefitType;
        lineItemTypes.push({
          _id: benefitType.value + LEDGER_LINE_TYPE_ID_SEPARATOR + "expense",
          category: "benefit_type_expenses",
          flavor: "Expense",
          label: benefitType.label,
          description: `${benefitType.label} employer contributions.`,
          creditOrDebit: "Debit",
          accountId: mapping?.benefit_type_expenses?.[checkType],
        });
        lineItemTypes.push({
          _id: benefitType.value + LEDGER_LINE_TYPE_ID_SEPARATOR + `liability`,
          category: "benefit_type_liabilities",
          flavor: "Liability",
          label: benefitType.label,
          description: `${benefitType.label} liabilities owed to third-parties.`,
          creditOrDebit: "Credit",
          accountId: mapping?.benefit_type_liabilities?.[checkType],
        });
      }
    }

    return lineItemTypes;
  }, [earningOptions, mapping, isGranularity, isDefault]);
};

export type LedgerMappingForDataBox = {
  lineType: string;
  accountLabel: string;
};

export const useLedgerMappingsForDataBox = (
  mapping: LedgerMapping | undefined
): LedgerMappingForDataBox[] => {
  const mappingLineTypes = useLedgerLineTypes({ mapping, isDefault: false });
  const accountLabeler = useLedgerAccountLabeler();
  return useMemo(() => {
    return mappingLineTypes
      .filter((t) => !!t.accountId)
      .map((t) => {
        const accountLabel = accountLabeler(t.accountId);
        return {
          lineType: t.category === "earning_types" ? `Earning type override: ${t.label}` : t.label,
          accountLabel,
        };
      });
  }, [accountLabeler, mappingLineTypes]);
};

export const allDefaultMappingLineTypes: LedgerMappingLineInfo[] = [
  {
    _id: "payroll_funds_source",
    category: "payroll",
    flavor: "Asset",
    label: "Funding bank account",
    description: "Bank account from which funds are withdrawn to cover the payroll cash requirement.",
    creditOrDebit: "Credit",
    forDefaultMappingOnly: true,
  },
  {
    _id: "employee_earnings",
    category: "payroll",
    flavor: "Expense",
    label: "Employee earnings",
    description: "Gross wages earned by employees.",
    creditOrDebit: "Debit",
  },
  {
    _id: "contractor_earnings",
    category: "payroll",
    flavor: "Expense",
    label: "Contractor earnings",
    description: "Gross wages earned by 1099 contractors.",
    creditOrDebit: "Debit",
  },
  {
    _id: "employer_benefit_contributions",
    category: "payroll",
    flavor: "Expense",
    label: "Benefit contributions",
    description: "Employer benefit contributions.",
    creditOrDebit: "Debit",
  },
  {
    _id: "fringe_contributions",
    category: "payroll",
    flavor: "Expense",
    label: "Fringe contributions",
    description: "Employer fringe contributions.",
    creditOrDebit: "Debit",
  },
  {
    _id: "employer_payroll_taxes",
    category: "payroll",
    flavor: "Expense",
    label: "Employer payroll taxes",
    description: "Payroll taxes owed by the employer.",
    creditOrDebit: "Debit",
  },
  {
    _id: "workers_comp_expenses",
    label: "Workers' comp expenses",
    category: "payroll",
    flavor: "Expense",
    description: "Estimated workers' comp expenses.",
    creditOrDebit: "Debit",
  },
  {
    _id: "burden_rate_expenses",
    label: "Burden rate expenses",
    category: "payroll",
    flavor: "Expense",
    description: "Burden rate expenses.",
    creditOrDebit: "Debit",
  },
  {
    _id: "paper_check_liabilities",
    category: "payroll",
    flavor: "Liability",
    label: "Paper check liabilities",
    description: "Paper checks owed to team members not enrolled in direct deposit.",
    creditOrDebit: "Credit",
  },
  {
    _id: "net_pay_receivables",
    category: "payroll",
    flavor: "Asset",
    label: "Net pay-related receivables",
    description: "Receivables related to net pay, like failed direct-deposit refunds and voided payments",
    creditOrDebit: "Debit",
  },
  {
    _id: "benefit_liabilities",
    category: "payroll",
    flavor: "Liability",
    label: "Benefit liabilities",
    description: "Employer and employee contributions related to benefits found in HR > Benefits.",
    creditOrDebit: "Credit",
  },
  {
    _id: "fringe_liabilities",
    category: "payroll",
    flavor: "Liability",
    label: "Fringe liabilities",
    description:
      "Employer and employee contributions related to pay rate group fringes and activity-level contributions.",
    creditOrDebit: "Credit",
  },
  {
    _id: "tax_liabilities",
    category: "payroll",
    flavor: "Liability",
    label: "Unremitted taxes",
    description: "Taxes not remitted by Miter and therefore owed to relevant tax agencies.",
    creditOrDebit: "Credit",
  },
  {
    _id: "tax_receivables",
    category: "payroll",
    flavor: "Asset",
    label: "Tax receivables",
    description: "Receivables for taxes related to voided payments",
    creditOrDebit: "Debit",
  },
  {
    _id: "child_support_liabilities",
    category: "payroll",
    flavor: "Liability",
    label: "Child support liabilities",
    description:
      "Child support payments not remitted by Miter and therefore owed to relevant child support agencies.",
    creditOrDebit: "Credit",
  },
  {
    _id: "prepaid_child_support",
    category: "payroll",
    flavor: "Asset",
    label: "Prepaid child support",
    description:
      "Child support payments remitted by Miter on behalf of team members, but subsequently voided and not refunded by the relevant agency.",
    creditOrDebit: "Debit",
  },
  {
    _id: "misc_ptds",
    category: "payroll",
    flavor: "Liability",
    label: "Other post-tax deductions",
    description: "Miscellaneous deductions from employee post-tax pay.",
    creditOrDebit: "Credit",
  },
  {
    _id: "workers_comp_liabilities",
    category: "payroll",
    flavor: "Liability",
    label: "Workers' comp liabilities",
    description: "Estimated workers' comp liabilities.",
    creditOrDebit: "Credit",
  },
  {
    _id: "burden_rate_offset",
    label: "Burden rate offset",
    category: "payroll",
    flavor: "Asset",
    description: "Burden rate offset.",
    creditOrDebit: "Credit",
  },
  {
    _id: "reimbursements",
    category: "expense_mgmt",
    flavor: "Expense",
    label: "Reimbursements",
    description: "Reimbursements earned by employees or 1099 contractors.",
    creditOrDebit: "Debit",
  },
  {
    _id: "manual_reimbursements",
    category: "expense_mgmt",
    flavor: "Liability",
    label: "Reimbursements liabilities (manual only)",
    description:
      "Liability acount for manual reimbursements (payroll and ACH reimbursements will be paid from the corresponding bank account used)",
    creditOrDebit: "Credit",
  },
  {
    _id: "card_transactions",
    category: "expense_mgmt",
    flavor: "Expense",
    label: "Card Transactions",
    description: "Card transactions on Miter-issued or third party cards.",
    creditOrDebit: "Debit",
  },
];
