import React, { useState } from "react";
import { Formblock, Label } from "ui";
import { HolidaySchedule, MiterAPI } from "dashboard/miter";
import { useForm } from "react-hook-form";
import { getFailuresFromPromiseAllSettled, HolidayTabProps } from "./utils";
import { Notifier } from "dashboard/utils";
import { useDebouncedCallback } from "use-debounce";
import * as vals from "dashboard/utils/validators";
import { useActiveCompanyId, useHolidaySchedulesAtom } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { sleep } from "miter-utils";

export type HolidayScheduleBasicsFormData = {
  title: string;
  is_default: boolean;
  fringe_offset_hours_per_year?: number | null;
  enable_fringe_offset: boolean;
  min_tenure?: number | null;
};

export const HolidayScheduleBasics: React.FC<HolidayTabProps> = ({
  originalHolidaySchedule,
  updateHolidaySchedule,
}) => {
  const { cannot } = useMiterAbilities();
  const [showFringeField, setShowFringeField] = useState(
    !!originalHolidaySchedule.fringe_offset_hours_per_year
  );
  const activeCompanyId = useActiveCompanyId();
  const [holidaySchedules, setHolidaySchedules] = useHolidaySchedulesAtom();

  const form = useForm<HolidayScheduleBasicsFormData>({
    defaultValues: {
      title: originalHolidaySchedule.title,
      is_default: originalHolidaySchedule.is_default,
      fringe_offset_hours_per_year: originalHolidaySchedule.fringe_offset_hours_per_year,
      min_tenure: originalHolidaySchedule.min_tenure,
    },
  });

  const onSave = async (data: HolidayScheduleBasicsFormData) => {
    if (!activeCompanyId) return;
    // if true, then there might be another default schedule to unset
    try {
      const { enable_fringe_offset, ...params } = data;

      if (params.fringe_offset_hours_per_year != null) {
        const num = Number(params.fringe_offset_hours_per_year) || 0;
        params.fringe_offset_hours_per_year = num <= 0 ? null : num;
      }

      if (params.title === null || params.title === "") {
        throw new Error("Holiday schedule title cannot be empty");
      }

      if (enable_fringe_offset === false) {
        params.fringe_offset_hours_per_year = null;
      }

      if (params.is_default && !originalHolidaySchedule.is_default) {
        // there should only be 0 or 1 previous defaultHolidaySchedules, but if there's
        // more, we will not throw an error but continue setting as not-default
        const defaultHolidaySchedules = holidaySchedules.filter((hs) => hs.is_default);
        if (defaultHolidaySchedules.length > 1) {
          console.error(
            `More than 1 holiday schedule was marked as default before update: ${defaultHolidaySchedules.map(
              (dhs) => dhs._id
            )}`
          );
        }
        const removedDefaults = await Promise.allSettled(
          defaultHolidaySchedules.map((prevDefaultHoliday) =>
            MiterAPI.holiday_schedules.update(prevDefaultHoliday._id, {
              is_default: false,
            })
          )
        );

        setHolidaySchedules((prev) => {
          const newVals: HolidaySchedule[] = [];
          for (const promiseRes of removedDefaults) {
            if (promiseRes.status === "fulfilled") newVals.push(promiseRes.value);
          }
          return prev.concat(newVals);
        });

        const failedDefaultUpdates = getFailuresFromPromiseAllSettled(removedDefaults);
        if (failedDefaultUpdates.length) {
          console.log(`Failed updates: ${JSON.stringify(failedDefaultUpdates)}`);
          throw new Error("Issue unsetting previous default holiday schedule(s)");
        }
      }
      await updateHolidaySchedule(params);
    } catch (e: $TSFixMe) {
      console.log("Error updating holiday schedule basics:", e);
      Notifier.error(e.message);
    }
  };

  const updateSaveDebounced = useDebouncedCallback(() => {
    form.handleSubmit(onSave)();
  }, 500);

  const handleEnableFringeOffsetChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const doShow = e.target.checked;
    setShowFringeField(doShow);
    if (doShow) {
      // Reset the value to 0 (instead of using the default) in case they uncheck and recheck the box
      await sleep(0);
      form.setValue("fringe_offset_hours_per_year", 0);
    } else {
      updateSaveDebounced();
    }
  };

  const styler: React.CSSProperties = { marginTop: 5 };
  if (showFringeField) {
    styler.width = 300;
  }

  return (
    <div>
      <div className="vertical-spacer-medium" />
      <Formblock
        label="Title"
        type="text"
        name="title"
        form={form}
        val={vals.required}
        onChange={updateSaveDebounced}
        editing={true}
        labelStyle={styler}
        disabled={cannot("time_off:holidays:manage")}
      />
      <Formblock
        label="Default"
        labelInfo="New employees will automatically be enrolled into this holiday schedule."
        type="checkbox"
        name="is_default"
        onChange={updateSaveDebounced}
        form={form}
        editing={true}
        labelStyle={styler}
        disabled={cannot("time_off:holidays:manage")}
      />
      <Formblock
        label="Fringe offset"
        labelInfo="Should this holiday schedule contribute an hourly amount to offset classification fringe rates?"
        type="checkbox"
        name="enable_fringe_offset"
        onChange={handleEnableFringeOffsetChange}
        form={form}
        editing={true}
        labelStyle={styler}
        disabled={cannot("time_off:holidays:manage")}
        checked={showFringeField}
      />
      {showFringeField && (
        <Formblock
          type="number"
          name="fringe_offset_hours_per_year"
          label="Annual offset hours"
          labelInfo="Total annual hours in scope for the fringe offset"
          form={form}
          placeholder="0"
          editing={true}
          style={{ marginTop: 10 }}
          onChange={updateSaveDebounced}
          labelStyle={styler}
          val={vals.numberValidator({ required: false, min: 0 })}
          disabled={cannot("time_off:holidays:manage")}
        />
      )}
      <div style={{ display: "flex" }}>
        <Formblock
          type="number"
          name="min_tenure"
          label="Waiting Period"
          labelInfo="Specify the number of days the employee needs to be employed before they are eligible for holiday pay."
          form={form}
          editing={true}
          style={{ marginTop: 10 }}
          className="margin-left"
          onChange={updateSaveDebounced}
          labelStyle={{ ...styler, width: "100%" }}
          val={vals.numberValidator({ required: false, min: 0 })}
          disabled={cannot("time_off:holidays:manage")}
        />
        <Label label={"days"} style={{ marginLeft: 10, marginTop: 15 }} />
      </div>
    </div>
  );
};
