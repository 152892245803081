import React, { FC, useMemo, useState } from "react";
import { ActionModal, Button, Formblock, Notifier } from "ui";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { getPlaidLinkToken } from "dashboard/utils/expenses";
import PlaidLink from "dashboard/components/banking/PlaidLink";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { MiterAPI } from "dashboard/miter";
import { CreateRawBankAccountParams } from "../../../backend/services/bank-accounts/bank-accounts-service";
import { Option } from "ui/form/Input";
import { capitalize } from "lodash";
import { BankAccountSubtype } from "backend/utils/plaid";

type Props = {
  teamMemberId?: string;
  companyId: string; // necessary for Plaid
  onSubmit: () => void;
  onClose: () => void;
  hideCancel?: boolean;
  hideExit?: boolean;
};

type CreateBankAccountForm = {
  account_number: string;
  routing_number: string;
  account_subtype: Option<string>;
};

export const CreateBankAccountModal: FC<Props> = ({
  teamMemberId,
  companyId,
  onSubmit,
  onClose,
  hideCancel,
  hideExit,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation<$TSFixMe>();
  const { control, errors, register, handleSubmit, watch } = useForm<CreateBankAccountForm>();
  const formData = watch();

  const [loadingPlaid, setLoadingPlaid] = useState(false);
  const [plaidLinkToken, setPlaidLinkToken] = useState<string>();
  const [submitLoading, setSubmitLoading] = useState(false);

  const [plaidSuccessData, setPlaidSuccessData] = useState<{
    public_token: string;
    metadata: PlaidLinkOnSuccessMetadata;
  }>();

  const accountSubtypeOptions = useMemo(
    () => [
      { label: capitalize(t("checking")), value: "checking" },
      { label: capitalize(t("savings")), value: "savings" },
    ],
    [t]
  );

  const isFormComplete = useMemo(() => {
    return formData.account_number && formData.routing_number && formData.account_subtype;
  }, [formData]);

  // saves form data to backend
  const createNewRawBankAccount = async (data: CreateBankAccountForm) => {
    const cleanedData: CreateRawBankAccountParams = {
      full_account_number: data.account_number,
      routing_number: data.routing_number,
      account_subtype: data.account_subtype.value as BankAccountSubtype,
      company_id: companyId,
      team_member_id: teamMemberId,
      external_financial_account_type: teamMemberId ? "team_member" : "company",
    };

    setSubmitLoading(true);
    try {
      const response = await MiterAPI.bank_accounts.create(cleanedData);
      if (response.error) {
        throw new Error(response.error);
      }
      Notifier.success(t("Bank account created."));
      onSubmit();
      onClose();
    } catch (err: $TSFixMe) {
      Notifier.error(t("Could not create bank account.") + " " + err.message);
    }
    setSubmitLoading(false);
  };

  // saves new Plaid account(s) to backend
  const createNewPlaidbankAccount = async () => {
    if (!plaidSuccessData) return;

    setSubmitLoading(true);
    try {
      const { public_token, metadata } = plaidSuccessData;

      const res = await MiterAPI.banking.plaid.connect_accounts({
        company: companyId,
        team_member_id: teamMemberId,
        external_financial_account_type: teamMemberId ? "team_member" : "company",
        public_token,
        metadata,
      });

      // // this will throw if there was an error for the Plaid item or earlier steps, not for individual accounts
      if (res.error) throw new Error(res.error);
      for (const connectAccountResult of res) {
        const last4 = connectAccountResult.account?.mask;
        // wasn't connected
        if (connectAccountResult.error) {
          Notifier.error(`Account ending in ${last4} wasn't connected to Miter.`);
        } else {
          Notifier.success(`Account ending in ${last4} connected to Miter.`);
        }
      }

      onSubmit();
      onClose();
    } catch (err: $TSFixMe) {
      Notifier.error(t("Could not connect account to Miter.") + " " + err.message);
    }
    setSubmitLoading(false);
  };

  // displays what the user has linked via Plaid
  const renderPlaidSuccessData = () => {
    if (!plaidSuccessData) return;

    return (
      <div>
        <h4>Linked accounts from {plaidSuccessData.metadata.institution?.name}</h4>
        {/* can link multiple accounts from the same item! ex. one checking, one savings */}
        {plaidSuccessData.metadata.accounts.map((account) => (
          <p key={account.id}>
            {account.name} ····{account.mask}
          </p>
        ))}
      </div>
    );
  };

  const renderManualEntryForm = () => {
    return (
      <div className="margin-top-15">
        <Formblock
          label={t("Routing number")}
          type="text"
          name="routing_number"
          className="modal"
          editing={true}
          control={control}
          errors={errors}
          register={register(vals.isValidBankRoutingNumber)}
        />
        <Formblock
          label={t("Account number")}
          type="text"
          name="account_number"
          className="modal"
          editing={true}
          control={control}
          errors={errors}
          register={register(vals.isValidBankAccountNumber)}
        />
        <Formblock
          label={t("Type")}
          type="select"
          name="account_subtype"
          className="modal"
          options={accountSubtypeOptions}
          editing={true}
          control={control}
          errors={errors}
          requiredSelect
        />
      </div>
    );
  };

  return (
    <>
      <ActionModal
        headerText={t("Add bank account")}
        onHide={onClose}
        loading={submitLoading}
        showSubmit
        submitDisabled={!plaidSuccessData && !isFormComplete}
        onSubmit={plaidSuccessData ? createNewPlaidbankAccount : handleSubmit(createNewRawBankAccount)}
        showCancel={!hideCancel}
        hideExit={hideExit}
      >
        <>
          {plaidSuccessData ? (
            renderPlaidSuccessData()
          ) : (
            <div className="margin-top-25">
              <Button
                onClick={() =>
                  getPlaidLinkToken({
                    company: companyId,
                    external_financial_account_type: teamMemberId ? "team_member" : "company",
                    setLoading: setLoadingPlaid,
                    setPlaidLinkToken,
                    products: ["auth"],
                  })
                }
                text={t("Connect with Plaid")}
                loading={loadingPlaid}
                className="button-2"
              />
            </div>
          )}
          {!plaidSuccessData && (
            <div className="pointer margin-top-15 color-gray" onClick={() => setOpen(!open)}>
              <div className="flex space-between width-100-percent margin-right-15">
                <p>{t("Or add manually")}</p>
                {open ? <FaChevronUp className="font-size-12" /> : <FaChevronDown className="font-size-12" />}
              </div>
            </div>
          )}
          {open && !plaidSuccessData && <>{renderManualEntryForm()}</>}
        </>
      </ActionModal>
      {plaidLinkToken && (
        <PlaidLink
          token={plaidLinkToken}
          onSuccess={(public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
            // save this data for later, to be saved when user presses "submit"
            setPlaidSuccessData({ public_token, metadata });
          }}
          onExit={() => setPlaidLinkToken(undefined)}
        />
      )}
    </>
  );
};
