import { AggregatedTeamMember, Company, TeamMember } from "dashboard/miter";
import { formatDate, roundTo, getWorkWeeksInYear } from "dashboard/utils/utils";
import { usdString } from "ui";
import { DateTime } from "luxon";
import { deparameterizeCapitalizeFirstLetter, unwindCustomFieldValuesAndPrependPrefix } from "miter-utils";
import { Assign } from "utility-types";
import { CheckEmployee } from "backend/utils/check/check-types";
import { TeamMemberChangeCategory } from "backend/models/change-request";
import { DEFAULT_WORK_WEEKS_IN_YEAR } from "dashboard/utils/constants";
import { TMPayType } from "backend/models/teamMember/team-member";
import { Option } from "ui/form/Input";
import { useLookupBenefitsEligibilityGroups } from "dashboard/hooks/atom-hooks";

const MONTHS_PER_YEAR = 12.0;

export const getPayrollStatus = (
  tm: AggregatedTeamMember | undefined
): CheckEmployee["onboard"]["status"] | "non_payroll" => {
  if (!tm?.check_tm) {
    return "non_payroll";
  }
  return tm.check_tm.onboard.status;
};

const getType = (tm: AggregatedTeamMember) => {
  if (tm.end_date) {
    return "dismissed";
  } else {
    return tm.employment_type;
  }
};

export type TableReadyTm = Assign<
  AggregatedTeamMember & {
    onboarded?: boolean;
    start_date_string?: string | null;
    type: "employee" | "contractor" | "dismissed";
    departmentName?: string;
    locationName?: string;
    payroll_status: CheckEmployee["onboard"]["status"] | "non_payroll";
    active_status: "active" | "dismissed" | "not_started" | "upcoming_dismissal" | "on_leave";
  },
  {
    pay_type?: TMPayType | null | "union_rate";
    crews?: string[];
    pay_rate_group?: string | null;
    union_rate?: string | null;
    benefits_eligibility_groups?: Option<string>[];
  }
>;

export const renderPayRateLabel = (tm: AggregatedTeamMember): string => {
  if (tm.pay_type === "salary") {
    if (tm.salary_rate_display === "month") {
      return "Pay rate (monthly)";
    } else if (tm.salary_rate_display === "week") {
      return "Pay rate (weekly)";
    }
    return "Pay rate (annual)";
  }
  return "Pay rate";
};

// Always stored as a yearly rate on the backend, so need to convert
// on the frontend for readability.
export function convertAnnualRateToDisplayRate<T extends { settings: Company["settings"] }>(
  company: T | null,
  pay_type: string | null | undefined,
  display_type: string | undefined | null,
  pay_rate: number | undefined | null
): number {
  if (!pay_rate) return 0;

  let formattedPayRate = Number(pay_rate);
  const workWeeksInYear = company ? getWorkWeeksInYear(company) : DEFAULT_WORK_WEEKS_IN_YEAR;
  if (pay_type === "salary") {
    if (display_type === "month") {
      formattedPayRate /= MONTHS_PER_YEAR;
    } else if (display_type === "week") {
      formattedPayRate /= workWeeksInYear;
    }
  }
  return roundTo(formattedPayRate);
}

export function convertDisplayRateToAnnualRate<T extends { settings: Company["settings"] }>(
  company: T | null,
  display_type: string,
  pay_rate: string | number
): number {
  pay_rate = Number(pay_rate);
  const workWeeksInYear = company ? getWorkWeeksInYear(company) : DEFAULT_WORK_WEEKS_IN_YEAR;
  if (display_type === "week") {
    return pay_rate * workWeeksInYear;
  } else if (display_type === "month") {
    return pay_rate * MONTHS_PER_YEAR;
  }
  return pay_rate;
}

export function convertAnnualRateToDisplayRateString<T extends { settings: Company["settings"] }>(
  company: T | null,
  display_type: string | undefined,
  pay_rate: number
): string {
  const displayRate = convertAnnualRateToDisplayRate(company, "salary", display_type, pay_rate);

  // Show in terms of thousands of dollars if 10K or more
  let displayRateString: string;
  if (displayRate >= 1e4) {
    displayRateString = usdString(roundTo(displayRate / 1e3, 0)).slice(0, -3) + "K";
  } else {
    displayRateString = usdString(displayRate);
    if (displayRateString.slice(-2) == "00") displayRateString = displayRateString.slice(0, -3);
  }

  if (display_type === "week") {
    displayRateString += "/week";
  } else if (display_type === "month") {
    displayRateString += "/month";
  } else {
    displayRateString += "/year";
  }

  return displayRateString;
}

export const buildTeamBaseFilterPredicate = (
  today: string
): ((t: AggregatedTeamMember | TeamMember) => boolean) => {
  return (t: AggregatedTeamMember | TeamMember): boolean =>
    !t.archived && !t.invisible && (!t.end_date || t.end_date >= today);
};

export const employeeFilterWithCheckPredicate = (t: AggregatedTeamMember): boolean =>
  t.employment_type === "employee" && !!t.check_tm && !!t.check_id;

export const isTmActive = (tm: AggregatedTeamMember | TeamMember): boolean => {
  const predicate = buildTeamBaseFilterPredicate(DateTime.now().toISODate());
  return predicate(tm);
};

export const defaultTmJobTooltip =
  "This job will be attached to automatic salary, time off earnings, and allowances but not timesheets";
export const stdTmClassificationTooltip =
  "If a TM hasn't been assigned a specific classification within a pay rate group, Miter will look for a team member's standard classification when determining the pay rate for a timesheet";
export const tmHsaLimitTooltip =
  "When applying pay rate group HSA fringes, this limit will be used for this employee.";
export const defaultTmActivityTooltip =
  "This activity will be attached to automatic salary, time off earnings, and allowances but not timesheets";
export const defaultTmCostTypeTooltip =
  "This cost type will override the earning type's default cost type for all direct earnings";
export const benefitsEligibilityStatusTooltip =
  "Override this team member's benefits eligibility status. This will affect their ability to enroll in benefits via Miter's benefits administration system.";

// this type is being used to ensure that TeamMemberChangeCategory values for change requests correspond to editing types in EditTeamMemberModal
// categories not included in the ChangeCategory type do not have change requests enabled
export type TeamMemberEditingModalCategory =
  | TeamMemberChangeCategory
  | "onboarding"
  | "custom-fields"
  | "benefits"
  | "payment"
  | "jobs"
  | "policies"
  | "cprs";

export const getUserFriendlyTmFieldName = (field: string): string => {
  switch (field) {
    case "email":
      return "Email address";
    case "ssn_last_four":
      return "Social security last four digits";
    case "dob":
      return "Date of birth";
    case "address":
      return "Residence";
    case "friendly_id":
      return "ID";
    case "department_id":
      return "Department";
    case "pay_schedule_id":
      return "Pay schedule ID";
    case "disable_multi_workplace_payrolls":
      return "Disabled multi-workplace payrolls";
    case "check_id":
      return "Check ID";
    case "check_tm":
      return "Check team member";
    case "ssn_id":
      return "SSN ID";
    case "pay_type":
      return "Pay type";
    case "pay_rate":
      return "Pay rate";
    case "salary_rate_display":
      return "Salary rate unit";
    case "default_ot_rule_id":
      return "Default overtime rule";
    case "default_job_id":
      return "Default job";
    case "default_activity_id":
      return "Default activity";
    case "default_cost_type_id":
      return "Default cost type";
    case "ledger_mapping_id":
      return "GL mapping";
    case "standard_classification_id":
      return "Standard classification";
    case "can_enable_kiosk":
      return "Can enable kiosk mode";
    case "is_universal_supervisor":
      return "Universal supervisor permission";
    case "pto_days":
      return "PTO days";
    case "time_off":
      return "Time off policies";
    case "chat":
      return "Chat ID";
    case "conversation":
      return "Conversation ID";
    case "wc_code":
      return "Workers' compensation code";
    case "onboarded":
      return "Onboarding status";
    case "accept_sms":
      return "SMS Notifications";
    case "holiday_schedule_id":
      return "Holiday schedule";
    case "kiosk_pin":
      return "Kiosk clock-in pin";
    case "payment_method_preference":
      return "Preferred payment method";
    case "enroll_in_payroll":
      return "Enrolled in payroll";
    case "permission_group_ids":
      return "Permission groups";
    case "prg_classifications":
      return "Classifications";
    default:
      return deparameterizeCapitalizeFirstLetter(field);
  }
};

export const getUserFriendlyTmChangeCategory = (category: TeamMemberChangeCategory | undefined): string => {
  if (!category) {
    return "Not set";
  } else {
    switch (category) {
      case "employment":
        return "Employment";
      case "pay":
        return "Pay";
      case "defaults":
        return "Defaults";
      case "classifications":
        return "Classifications";
      case "personal":
        return "Personal";
      case "demographics":
        return "Demographics";
      case "emergency-contacts":
        return "Emergency contacts";
      case "all":
        return "All";
      default:
        return "Not set";
    }
  }
};

export const getTmIntacctLocationId = <T extends Pick<TeamMember, "integrations">>(
  tm: T | undefined
): string | undefined => {
  return (
    tm?.integrations?.sage_intacct?.stagedLocationId ||
    tm?.integrations?.sage_intacct?.intacctEmployee?.LOCATIONID
  );
};

export const getTeamMemberActiveStatus = <T extends Pick<TeamMember, "start_date" | "end_date" | "on_leave">>(
  tm: T
): "active" | "not_started" | "upcoming_dismissal" | "dismissed" | "on_leave" => {
  const dt = DateTime.now().toISODate();
  let employmentStatus;
  if (tm.start_date && tm.start_date > dt) {
    employmentStatus = "not_started";
  } else if (tm.end_date && tm.end_date >= dt) {
    employmentStatus = "upcoming_dismissal";
  } else if (tm.end_date) {
    employmentStatus = "dismissed";
  } else if (
    tm.on_leave &&
    tm.on_leave.start_date <= dt &&
    (!tm.on_leave.end_date || tm.on_leave.end_date >= dt)
  )
    employmentStatus = "on_leave";
  else {
    employmentStatus = "active";
  }
  return employmentStatus;
};

export const employmentCategoryOptions: Option<"full_time" | "part_time">[] = [
  { label: "Full-Time", value: "full_time" },
  { label: "Part-Time", value: "part_time" },
];

export const employmentCategoryFormatter = (employmentCategory: "full_time" | "part_time"): string => {
  const employmentCategoryMap = {
    full_time: "Full-Time",
    part_time: "Part-Time",
  };

  return employmentCategoryMap[employmentCategory];
};

export const employmentTermOptions: Option<"temporary" | "seasonal" | "permanent">[] = [
  { label: "Temporary", value: "temporary" },
  { label: "Seasonal", value: "seasonal" },
  { label: "Permanent", value: "permanent" },
];

type CleanTeamFunction = <T extends AggregatedTeamMember>(rawTms: T[]) => TableReadyTm[];
export const useCleanTeamFromBackend = (): CleanTeamFunction => {
  const lookupBenefitsEligibilityGroup = useLookupBenefitsEligibilityGroups();
  return (team) => {
    const cleaned: TableReadyTm[] = team.map((tm) => {
      const payType = tm.union_rate ? "union_rate" : tm.pay_type;

      return {
        ...tm,
        first_name: tm.first_name,
        last_name: tm.last_name,
        pay_type: payType,
        payroll_status: getPayrollStatus(tm),
        active_status: getTeamMemberActiveStatus(tm),
        start_date_string: formatDate(tm.start_date, undefined, true),
        type: getType(tm),
        departmentName: tm.department?.name,
        locationName: tm.location?.name,
        onboarded: tm.onboarding_status?.all_tasks_complete !== false,
        pay_rate_group: tm.union_rate?.pay_rate_group,
        union_rate: tm.union_rate?._id,
        ...unwindCustomFieldValuesAndPrependPrefix(tm),
        benefits_eligibility_groups: tm.benefits_eligibility_groups?.map((group) => ({
          label: lookupBenefitsEligibilityGroup(group)?.integrations.clasp.clasp_subclass.name ?? "",
          value: group,
        })),
      };
    });

    return cleaned;
  };
};
