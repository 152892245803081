import React, { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";

import { CostType, MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import Banner from "../shared/Banner";
import {
  useActiveCompanyId,
  useLedgerAccountOptions,
  useLookupCostType,
  useSetCostTypes,
} from "dashboard/hooks/atom-hooks";
import AppContext from "dashboard/contexts/app-context";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { Option } from "ui/form/Input";
import { CostTypeScopes } from "./CostTypeScopes";
import { CostTypeScope } from "backend/models/cost-type";
import { useAuditLogHistory } from "../audit-logs/useAuditLogHistory";

type CostTypeForm = {
  label: string;
  code: string;
  ledger_account_id: Option<string>;
};

type Props = {
  costTypeId?: string;
  onFinish: (costType: CostType) => void;
  onHide: () => void;
};

export const CostTypeModal: React.FC<Props> = ({ costTypeId, onFinish, onHide }) => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const setCostTypes = useSetCostTypes();
  const lookupCostType = useLookupCostType();
  const ledgerAccountOptions = useLedgerAccountOptions();
  const { can, cannot } = useMiterAbilities();
  const { integrations } = useContext(AppContext);
  const form = useForm<CostTypeForm>();
  const { renderAuditLogHistoryButton, renderAuditLogHistoryModal } = useAuditLogHistory({
    itemId: costTypeId || "",
    itemType: "cost_type",
    refreshCounter: 0,
    show: false,
  });

  const selectedCostType = lookupCostType(costTypeId);

  // State
  const [loading, setLoading] = useState(false);
  const [scopes, setScopes] = useState<CostTypeScope[]>(
    selectedCostType?.scopes ?? ["timesheets", "expense_management"]
  );

  const sourceSystem = useMemo(() => {
    let system: MiterIntegrationForCompany | undefined;
    if (selectedCostType?.integrations) {
      const key = Object.keys(selectedCostType.integrations)[0];
      if (key) system = integrations.find((i) => i.key === key);
    }
    return system;
  }, [selectedCostType, integrations]);

  const cleanParams = (data: CostTypeForm) => {
    return {
      ...data,
      ledger_account_id: data.ledger_account_id?.value ?? null, // unset by passing null
      company_id: activeCompanyId!,
      scopes,
    };
  };

  const createCostType = async (data: CostTypeForm) => {
    if (!activeCompanyId || selectedCostType || cannot("lists:cost_types:manage")) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.cost_types.create(params);
      if (res.error) throw new Error(res.error);

      Notifier.success("Cost type created successfully");
      setCostTypes((prev) => prev.concat(res));
      onFinish(res);
      onHide();
    } catch (e: $TSFixMe) {
      console.error("Error creating cost type:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateCostType = async (data: CostTypeForm) => {
    if (!activeCompanyId || !selectedCostType || cannot("lists:cost_types:manage")) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.cost_types.update(selectedCostType._id, params);
      if (res.error) throw new Error(res.error);

      setCostTypes((prev) => prev.concat(res));
      onFinish(res);
      onHide();
      Notifier.success("Cost type updated successfully");
    } catch (e: $TSFixMe) {
      console.error("Error updating cost type:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  return (
    <ActionModal
      headerText={`${selectedCostType ? "Update" : "Create"} cost type`}
      showSubmit={can("lists:cost_types:manage")}
      showCancel={true}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={form.handleSubmit(selectedCostType ? updateCostType : createCostType)}
      loading={loading}
    >
      {sourceSystem && (
        <Banner
          style={{ borderBottom: "1px solid #d6d6d6", marginRight: -20, marginLeft: -20 }}
          content=""
          type="modal"
        >
          <span className="flex">
            {`Note: this cost type was imported from ${sourceSystem.label}. Certain fields won't be
        editable.`}
          </span>
        </Banner>
      )}
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="label"
          label="Label*"
          form={form}
          val={vals.required}
          editing={true}
          defaultValue={selectedCostType?.label}
          className="modal"
          disabled={!!sourceSystem || cannot("lists:cost_types:manage")}
        />
        <Formblock
          type="text"
          name="code"
          label="Code*"
          form={form}
          val={vals.required}
          editing={true}
          defaultValue={selectedCostType?.code}
          className="modal"
          disabled={!!sourceSystem || cannot("lists:cost_types:manage")}
        />
        <Formblock
          label={"GL account"}
          labelInfo={
            "If selected, Miter will use this GL account for lines with this cost type, unless overridden at a more specific level (e.g., allowance-level account)."
          }
          type="select"
          name="ledger_account_id"
          options={ledgerAccountOptions}
          className="modal"
          defaultValue={selectedCostType?.ledger_account_id}
          requiredSelect={false}
          control={form.control}
          editing={true}
          disabled={cannot("lists:cost_types:manage")}
          isClearable
        />
        <CostTypeScopes currentScopes={scopes} handleFieldsChange={setScopes} />
        {renderAuditLogHistoryButton()}
        {renderAuditLogHistoryModal()}
      </div>
    </ActionModal>
  );
};
