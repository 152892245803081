import InfoButton from "dashboard/components/information/information";
import React from "react";
import { TimesheetSettings } from "./TimesheetSettings";

interface MobileTimeInputSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const MobileTimeInputSettings: React.FC<MobileTimeInputSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  return (
    <div className="billing-card-wrapper">
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Mobile manual time input</div>
        <InfoButton text="This setting determines how team members can manually input their time from the mobile app." />
      </div>

      <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
        <div className="vertical-spacer-small"></div>
        <div className="flex">
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => handleChange({ mobile_time_input: "clock_out" })}
            checked={timesheetSettings.mobile_time_input === "clock_out"}
          />
          <label>
            <span className="bold">Clock in/out</span>: Team members enter the clock in time, clock out time,
            and unpaid break time.
          </label>
        </div>
        <div className="flex" style={{ marginTop: 7 }}>
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => handleChange({ mobile_time_input: "duration" })}
            checked={timesheetSettings.mobile_time_input === "duration"}
          />
          <label>
            <span className="bold">Duration</span>: Team members enter the number of hours (duration) they
            worked.
          </label>
        </div>
      </div>
    </div>
  );
};

export default MobileTimeInputSettings;
